import React, { useEffect, useState } from "react";
import {
  Search,
  ArrowDropUp,
  ArrowDropDown,
  UnfoldMore,
  Star,
  SyncAlt,
} from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
import bitcoin from "../../Assets/Image/bitcoin.png";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import LoaderCom from "../../config/LoaderCom";

const Market = (props: any) => {
  const { id } = useParams();

  const [emptyData, setemptyData] = useState([]);

  const [elpMarket, setElpMarket] = useState([]);
  const [searchedVal, setSearchedVal] = useState('');

  const [tradeData, setTradeData] = useState([{}]);
  async function MARKET() {
    // setemptyData([]);
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=trade_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      setElpMarket(d?.market);
      setemptyData(d)
    }
  }


  const dropShow = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.toggle("drop_show");
  };
  const [TradeemptyData, setTradeemptyData] = useState([]);
  async function fetchData() {
    var d = await _fetch(`${api_url}trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&order_status=completed`, 'GET', {}, {});
    if (d?.status === 'success') {
      setTradeData(d.data.data);
      // console.log('buy data',d.data.data)
      setTradeemptyData(d)
    }
  }
  useEffect(() => {
    MARKET()
    fetchData()


  }, [])

  const tradeValue = "100%"

  const socket = {

    Profit: {
      width: "100%",
      backgroundImage: `linear-gradient( to right, var(--buy) ${tradeValue}, var(--buy) ${tradeValue} ,  Transparent 0%, Transparent 0% )`,
    },


    Loss: {
      width: "100%",
      backgroundImage: `linear-gradient( to right, var(--sell) ${tradeValue}, var(--sell) ${tradeValue} ,  Transparent 0%, Transparent 0% )`,
    },

  };

  return (
    <>
      <div className="main-market">

        <div className="data-sec">

          <div className="" style={{ display: props.CointTab }}>

            <div className="main-search">
              <div className="search-bar">
                <div className="icon">
                  <Search />
                </div>
                <div className="input1">
                  <input type="search" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="main-table">
              <table>
                <thead>
                  <tr>
                    <th className="pr">
                      pair <UnfoldMore />
                    </th>
                    <th className="pc">
                      Price <UnfoldMore />
                    </th>
                    <th className="ch">
                      Balance
                      {/* <UnfoldMore /> <SyncAlt /> */}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="border-0">
                    <td colSpan={6} className="__loginData">
                      <div className='btn-groups'>
                        <Link to="/login" className='btn btn-primary sign_up__button'> Login </Link> or <Link to="/Sign-up" className='btn btn-primary sign_up__button'> Sign Up </Link> to trade
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div className="d-mobail-none main-table2" style={{ display: props.myorderTab }}>
            <div className="inner">
              <Tabs>
                <TabList>
                  <Tab>Market Trades</Tab>
                </TabList>

                <TabPanel>
                  <div className="tab1">
                    <table className=" w-100">
                      <thead>
                        <tr>
                          <th className="pr">Price(USDT)</th>
                          <th className="pc">Amount </th>
                          <th className="ch">Time </th>
                        </tr>
                      </thead>

                      <tbody>

                        {/* <LoaderCom condition={TradeemptyData} text={'Getting Trading History Orders...'} />

                        {tradeData?.map((item: any, index: any, tradeData: any) => {
                          if (item.p > 0) {
                            return (
                              <tr data-index={index} key={index} >
                                <td style={{ color: (item.market_trade_type != "buy") ? '#e84650' : '#10a748' }}>{parseFloat(item.coin_price).toFixed(3)}</td>
                                <td className='pc'>{parseFloat(item.coin).toFixed(3)}</td>
                                <td className='ch'>{new Date(item.createdAt).toLocaleTimeString("en-US")}</td>
                              </tr>
                            )
                          }
                        }
                        )} */}

                        <tr className="border-0">
                          <td colSpan={6} className="__loginData">
                            <div className='btn-groups'>
                              <Link to="/login" className='btn btn-primary sign_up__button'> Login </Link> or <Link to="/Sign-up" className='btn btn-primary sign_up__button'> Sign Up </Link> to trade
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Market;
