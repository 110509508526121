import site_logo from "../Assets/Icon/logo.png";
export const logo = site_logo;
export const title = 'KissanX ';
export const website = 'KissanX.com';
export const shortTitle = 'KSN';
export const titleDescription = 'KissanX';
// export const api_url = 'http://192.168.29.162:8001/demo/api/v1/';
// export const titleDescription = 'STA EXCHANGE';
// export const api_url = 'http://192.168.1.36:8001/demo/api/v1/';
export const api_url = 'https://api.kissanx.com/api/v1/';
export const markit_url = 'https://market.kissanx.com/api/v1/';
export const kycImage_path = 'https://api.kissanx.com/images/kyc/';
export const referral_link = 'https://kissanx.com/sign-up/';
export const receipt_img = 'https://api.kissanx.com/receipt/';

export const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                        resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                        reject(error);
                }
        })
}