import React from 'react'
import { title } from '../../config/config'
import BannerImg from "../../Assets/Image/bannerLaunchBase.png";
import USD from "../../Assets/Image/USD.png";
import { Link } from 'react-router-dom';

const LaunchBase = () => {
  return (
    <>
        <div className='main-bonus'>

            <div className='head-sec-bonus'>
                <div className='coustom_container'>

                    <div className='banner-head'>
                        <div className='sl'>
                            <h1>{title} LaunchBase</h1>
                        </div>
                        <div className='sr'>
                            <img src={BannerImg} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='cornerwrapper'>
                <div className='coustom_container'>

                    <div className='cards-head'>
                        <div className='cards-txt'>
                            <h2>Concluded</h2>
                            </div>

                            <Link to="/">
                            <div className='cards-base'>  
                                <div className='card-inner'>  
                                <div className='cl'>
                                    <img src={USD} alt="usd" />
                                    <h3>USDTEARN1</h3>
                                    <span className='status_label'>Ended</span>
                                </div>
                                <div className='cr'>

                                    <div className='top-info'>
                                        <h2>USDTEARN1</h2>
                                        <p className='ellp'> EARN is a suite of fixed-term & fixed-income products launched by {title}. Users can subscribe to the EARN-suite tokens on Launchbase and receive their principal and yields on the maturity date. As the first product in the EARN suite, USDTEARN1 is a 90-day fixed-income product with an APY of 8% and a total supply of 5,000,000. Each user can subscribe to up to 10,000 USDTEARN1.</p>
                                    </div>
                                    <div className="bottom-info">
                                        <p> Initial Circulating Supply  <span className='str'>5,000,000 USDTEARN1</span></p>
                                        <p> Sale Price  <span className='str'>1 USDTEARN1 = 1 USDT</span></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </Link>
                    </div>

                    <div className='cards-head'>

                        <Link to="/">
                            <div className='cards-base'>  
                                <div className='card-inner'>  
                                <div className='cl'>
                                    <img src={USD} alt="usd" />
                                    <h3>USDTEARN1</h3>
                                    <span className='status_label'>Ended</span>
                                </div>
                                <div className='cr'>

                                    <div className='top-info'>
                                        <h2>USDTEARN1</h2>
                                        <p className='ellp'> EARN is a suite of fixed-term & fixed-income products launched by {title}. Users can subscribe to the EARN-suite tokens on Launchbase and receive their principal and yields on the maturity date. As the first product in the EARN suite, USDTEARN1 is a 90-day fixed-income product with an APY of 8% and a total supply of 5,000,000. Each user can subscribe to up to 10,000 USDTEARN1.</p>
                                    </div>
                                    <div className="bottom-info">
                                        <p> Initial Circulating Supply  <span className='str'>5,000,000 USDTEARN1</span></p>
                                        <p> Sale Price  <span className='str'>1 USDTEARN1 = 1 USDT</span></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Link>    
                    </div>

                    <div className='cards-head'>
                        <Link to="/">
                            <div className='cards-base'>  
                                <div className='card-inner'>  
                                <div className='cl'>
                                    <img src={USD} alt="usd" />
                                    <h3>USDTEARN1</h3>
                                    <span className='status_label'>Ended</span>
                                </div>
                                <div className='cr'>

                                    <div className='top-info'>
                                        <h2>USDTEARN1</h2>
                                        <p className='ellp'> EARN is a suite of fixed-term & fixed-income products launched by {title}. Users can subscribe to the EARN-suite tokens on Launchbase and receive their principal and yields on the maturity date. As the first product in the EARN suite, USDTEARN1 is a 90-day fixed-income product with an APY of 8% and a total supply of 5,000,000. Each user can subscribe to up to 10,000 USDTEARN1.</p>
                                    </div>
                                    <div className="bottom-info">
                                        <p> Initial Circulating Supply  <span className='str'>5,000,000 USDTEARN1</span></p>
                                        <p> Sale Price  <span className='str'>1 USDTEARN1 = 1 USDT</span></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </Link>    
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LaunchBase