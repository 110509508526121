import React, { useEffect, useState } from 'react'
import { KeyboardArrowRight, PlayCircle, PlaylistAddCheckCircle } from "@mui/icons-material";
import { useParams } from 'react-router-dom';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';

const LiveChart = (props:any) => {
    const { id } = useParams();
	const [coinData, setCoinData] = useState({current_price:'',price_change_percentage_24h:0,high_24h:'',low_24h:''});

    // const [livePrice, setLivePrice] = useState([]);
    async function CoinInfo() {
		var d = await _fetch(`${api_url}markets/getSingleTradeMarket?symbol=${id?.toLowerCase()}`, 'GET', {}, {});
		if (d?.status === 'success') {
			
			setCoinData(d.market[0]);
			// console.log('dsdad',d.market[0])
		}
	}



    useEffect(() => {
        // const nowStream = `${props.symbol.toLowerCase()}@ticker`;
        CoinInfo()
    }, [props.LoadApi])



    const [RightSIdebar, setRightSIdebar] = useState(false);
    const onOpenRightSIdebar = () => setRightSIdebar(true);
    const onCloseRightSIdebar = () => setRightSIdebar(false);

    const togglerBarRemove = () => {
        const aRemove: HTMLElement | any = document.body;
        aRemove.classList.remove("show_header__mobail");
    };
    return (
        <div className='main-livechart'>
            <div className='inner'>
                <div className='busd'>
                    <h2>{id?.toUpperCase()}/USDT</h2>
                </div>
                <div className='n1'>
                    <span className='grn'>{coinData.current_price} </span>
                    <span className='sz'>${coinData.current_price}</span>
                </div>
                <div className='n2'>
                    <span>24h Change</span>
                    <span className='grn sz'>{`${coinData.price_change_percentage_24h?.toFixed(2)??0}%`}</span>
                </div>
                <div className='n3'>
                    <span>24h High</span>
                    <span className='grn sz' style={{ textAlign: "center" }}>{coinData.high_24h}</span>
                </div>
                <div className='n4'>
                    <span>24h Low</span>
                    <span className='rd sz'>{coinData.low_24h}</span>
                </div>
                {/* <div className='n5'>
                    <span>Volume</span>
                    <span className='sz'>{currentVolumn.toFixed(1)}</span>
                </div> */}
            </div>

            {/* <div className='inner2'>
            <div className='tut'>
                <p><PlayCircle /> Spot Tutorial</p>
            </div>
            <div className='spot'>
                <p><PlaylistAddCheckCircle /> Spot Guidance</p>
            </div> */}
            {/* </div> */}

        </div>
    )
}

export default LiveChart;