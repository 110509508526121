import React, { useRef } from "react";
import { CheckCircle, CopyAll } from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "react-bootstrap";
import { shortTitle, title } from "../../config/config";

const Accounts = () => {
  // copy text function

  const textRef = useRef<HTMLDivElement>(null);

  const handleCopy = () => {
    if (textRef.current) {
      const range = document.createRange();
      range.selectNode(textRef.current);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
      alert("text copied succesfully");
    }
  };

  type demoDataNew = {
    status: string;
    message: string;
    result: string;
  };

  const demoData: demoDataNew = {
    status: "1",
    message: "OK",
    result: "154476596514118000947297331",
  };

  return (
    <>
      <div className="main-sec-account">
        <div className="coustom_container">
          <div className="inner-sec">
            <h1>Accounts</h1>
            <div className="info-sec">
              <span>
                <CheckCircle />
              </span>
              <span>
                Endpoints with are under the API Pro subscription. To upgrade
                your API plan, browse through the {title} APIs page.
              </span>
            </div>

            <div className="response-sec">
              <h2>Get {shortTitle} Balance for a Single Address</h2>
              <p>Returns the {shortTitle} balance of a given address.</p>

              <div className="inner" ref={textRef}>
                <span>
                  https://api.{title}.com/api <br />
                  ?module=account <br />
                  &action=balance
                  <br />
                  &address=0x70F657164e5b75689b64B7fd1fA275F334f28e18
                  <br />
                  &apikey=YourApiKeyToken
                </span>
                <div>
                  <span>
                    <CopyAll onClick={handleCopy} titleAccess="copy text" />
                  </span>
                </div>
              </div>
            </div>
            <div className="request-sec">
              <h3>Try this endpoint in your Browser</h3>
              <div className="request-table">
                <div className="inner-table">
                  <Tabs>
                    <TabList>
                      <Tab>Request</Tab>
                      <Tab>Response</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="head-sec">
                        <h5>Query Parameters</h5>
                        <div className="inner-sec-table">
                          <Table>
                            <thead>
                              <tr>
                                <th>Parameter</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>address</td>
                                <td>
                                  the string representing the address to check
                                  for balance
                                </td>
                              </tr>
                              <tr>
                                <td>tag</td>
                                <td>
                                  the string pre-defined block parameter, either
                                  earliest, pending or latest
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="head-sec-resp">
                        <div className="data-resp">
                          <span>No Data Available</span>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>

            <div className="multi-cell">
              <div className="inner-cell">
                <h2>
                  Get {shortTitle} Balance for Multiple Addresses in a Single
                  Call
                </h2>
                <p>
                  Returns the balance of the accounts from a list of addresses.
                </p>

                <div className="inner" ref={textRef}>
                  <span>
                    https://api.{title}.com/api <br />
                    ?module=account <br />
                    &action=balance
                    <br />
                    &address=0x70F657164e5b75689b64B7fd1fA275F334f28e18
                    <br />
                    &apikey=YourApiKeyToken
                  </span>
                  <div>
                    <span>
                      <CopyAll onClick={handleCopy} titleAccess="copy text" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
