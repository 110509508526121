import React from 'react'
import InfoSec from './InfoSec'
import Banner from './Section/Banner'
import Communityicons from './Section/Communityicons'
import Download from './Section/Download'
import Section2 from './Section/Section2'


const Index = () => {
  return (
    <>
      <Banner />
      <Section2 />
      <Communityicons />
      <InfoSec />
      <Download />
    </>
  )
}

export default Index