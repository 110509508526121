import React, { useEffect } from "react";
import { shortTitle, title } from "../../config/config";
import ShareIcon from "@mui/icons-material/Share";
import Launchpad__img from "../../Assets/Image/launchpad-img.png";
import Launchpad__img_1 from "../../Assets/Image/launchpad-img_1.png";
import Launchpad__img_2 from "../../Assets/Image/launchpad-img_2.png";
import { TaskAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Launchpad = () => {

  //  this page css Affiliates page(240 line ) +++
  return (
    <>
      <div className="Launchpad__sec only-margin">
        <div className="card__banner ">
          <div className="coustom_container">
            <div className="Launchpad__contain">
              <div className="card__banner__left">
                <h1>
                  {title} Launchpad
                </h1>
                <h3>Invest in the best before they skyrocket</h3>
                <p>
                  The Kissanx Launchpad is your gateway to the most promising and innovative blockchain projects in the market. At Kissanx, we meticulously handpick the brightest projects to offer you a unique opportunity to purchase tokens at their early stages with KSN.
                </p>
                <button type="button" className="btn btn-success">
                  <ShareIcon />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="more__option_lan">
          <div className="Launchpad__contain coustom_container">
            <div className="Launchpad__option_header">
              <h1> Past Projects {shortTitle}</h1>
            </div>

            <div className="option___data">
              <Link to="/">
                <div className="row">
                  <div className="col-3">
                    <img src={Launchpad__img} alt="img/icon" />
                  </div>

                  <div className="col-8">
                    <div className="launchpad_text_sec">
                      <h3>Kissannft </h3>
                      <p className="pro__diss">HOW KSN NFTS WORK</p>
                      <p>KSN Token NFTs function as distinct digital assets that symbolise every facet of farming, from equipment to seeds. Since each NFT is kept on a blockchain, ownership and authenticity can be easily and transparently confirmed. These digital tokens are available for collectors to obtain, and on the platform's marketplace, NFTs can be purchased, sold, or traded.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="option___data">
              <Link to="/">
                <div className="row">
                  <div className="col-3">
                    <img src={Launchpad__img_1} alt="img/icon" />
                  </div>

                  <div className="col-8">
                    <div className="launchpad_text_sec">
                      <h3>Ksn Metaverse </h3>
                      <p className="pro__diss">HYPERLOCAL UNIVERSE OF VIRTUAL SPACES</p>
                      <p>3D interactive farm spaces where users can buy, sell & rent farms and the equipments. Communicate, explore, and collaborate with each other in a virtual environment using KSN Tokens.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="option___data">
              <Link to="/">
                <div className="row">
                  <div className="col-3">
                    <img src={Launchpad__img_2} alt="img/icon" />
                  </div>

                  <div className="col-8">
                    <div className="launchpad_text_sec">
                      <h3>Kissantoken </h3>
                      <p className="pro__diss">TRULY DECENTRALIZED</p>
                      <p>A leading organization in IT enable services and Agriculture Export Business. Having a professional team for future technologies like AI, Blockchain experts, and the most important financial industry in the world having turnover of more than 1 trillion dollars and 30000 farmers & customers.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Launchpad;
