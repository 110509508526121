import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Fees = () => {
    return (
        <div>
            <div className="only-margin fees_sec coustom_container">
                <div className="inner-sec">
                    <div className="hearding">
                        <h3>Fee Schedule</h3>
                    </div>
                    <div className="main-tabs-sec">
                        <Tabs>
                            <TabList>
                                <Tab> Spot Trading </Tab>
                                <Tab> Borrow Interest & Cross Margin Limit </Tab>
                                <Tab> USDT-M Perpetual Futures </Tab>

                            </TabList>

                            <TabPanel>
                                <div className="inner-info">

                                    <table className="customers">
                                        <tr>
                                            <th>Tier</th>
                                            <th>30 DAY TRADING VOLUME</th>
                                            <th>TRX BALANCE</th>
                                            <th>MAKER / TAKER FEE</th>
                                            <th>TRX MAKER / TAKER FEE</th>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                            <td>$100K</td>
                                            <td>N/A</td>
                                            <td>0.2000%/0.2000%</td>
                                            <td>0.1450%/0.1550%</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>$100K - $200K</td>
                                            <td>N/A</td>
                                            <td>0.1150%/0.1250%</td>
                                            <td>0.0805%/0.0875%</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>$200K - $500K</td>
                                            <td>N/A</td>
                                            <td>0.1050%/0.1200%</td>
                                            <td>0.0735%/0.0840%</td>
                                        </tr>

                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="inner-info">

                                    <table className="customers">
                                        <tr>
                                            <th>Tier</th>
                                            <th>30 DAY TRADING VOLUME</th>
                                            <th>TRX BALANCE</th>
                                            <th>MAKER / TAKER FEE</th>
                                            <th>TRX MAKER / TAKER FEE</th>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                            <td>$100K</td>
                                            <td>N/A</td>
                                            <td>0.2000%/0.2000%</td>
                                            <td>0.1450%/0.1550%</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>$100K - $200K</td>
                                            <td>N/A</td>
                                            <td>0.1150%/0.1250%</td>
                                            <td>0.0805%/0.0875%</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>$200K - $500K</td>
                                            <td>N/A</td>
                                            <td>0.1050%/0.1200%</td>
                                            <td>0.0735%/0.0840%</td>
                                        </tr>

                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="inner-info">

                                    <table className="customers">
                                        <tr>
                                            <th>Tier</th>
                                            <th>30 DAY TRADING VOLUME</th>
                                            <th>TRX BALANCE</th>
                                            <th>MAKER / TAKER FEE</th>
                                            <th>TRX MAKER / TAKER FEE</th>
                                        </tr>
                                        <tr>
                                            <td>0</td>
                                            <td>$100K</td>
                                            <td>N/A</td>
                                            <td>0.2000%/0.2000%</td>
                                            <td>0.1450%/0.1550%</td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>$100K - $200K</td>
                                            <td>N/A</td>
                                            <td>0.1150%/0.1250%</td>
                                            <td>0.0805%/0.0875%</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>$200K - $500K</td>
                                            <td>N/A</td>
                                            <td>0.1050%/0.1200%</td>
                                            <td>0.0735%/0.0840%</td>
                                        </tr>

                                    </table>
                                </div>
                            </TabPanel>
                          
                        </Tabs>
                    </div>
                </div>

           
            </div>
        </div>
    )
}

export default Fees
