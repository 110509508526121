import React from 'react'
import { shortTitle } from '../../config/config';
import protection_bg from '../../Assets/Image/protection_bg.svg';
import Card from 'react-bootstrap/Card';
import { ArrowRightAlt } from '@mui/icons-material';
import btc_img from '../../Assets/Image/btc_img.png';
import pro_card1 from '../../Assets/Image/pro_card1.png';
import pro_footer from '../../Assets/Image/pro_footer.png';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';


const ProtectionFund = () => {
    return (
        <div className='protection_page only-margin'>
            <div className='coustom_container'>
                <div className="pro_banner">
                    <div className="row row_gap">
                        <div className="col-md-6 align_left">
                            <h5>Your security, our priority</h5>
                            <h6>{shortTitle} Protection Fund</h6>
                            <p>We regularly monitor the {shortTitle} Protection Fund, as well as crypto's cybersecurity landscape, to make sure that our fund remains adequate for our customers.</p>
                        </div>

                        <div className="col-md-6 d-flex_align-items-center justify-content-center">
                            <img src={protection_bg} alt='' />
                        </div>
                    </div>

                </div>

            </div>


            <div className='coustom_container bg_color'>
                <div className="top_cards pt-5 ">
                    <h5 className='commen_heading'>The fund is currently valued at US $300 million.</h5>

                    <div className='row row_gap pt-4'>
                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <div className='card_topflex'>
                                    <span className="border_left_top"></span>
                                    <span className="border_left_bottom"></span>
                                    <span className="border_right_bottom"></span>
                                    <span className="border_right_top"></span>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>BTC</h6>
                                        <Card.Title>{shortTitle} 6500 BTC</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Link to="#">
                                            Wallet <ArrowRightAlt />
                                        </Link>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <div className='card_topflex'>
                                    <span className="border_left_top"></span>
                                    <span className="border_left_bottom"></span>
                                    <span className="border_right_bottom"></span>
                                    <span className="border_right_top"></span>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>USDT</h6>
                                        <Card.Title>{shortTitle} 160 million USDT</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Link to="#">
                                            Wallet <ArrowRightAlt />
                                        </Link>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-sm-4 col-lg-4'>
                            <Card>
                                <div className='card_topflex'>
                                    <span className="border_left_top"></span>
                                    <span className="border_left_bottom"></span>
                                    <span className="border_right_bottom"></span>
                                    <span className="border_right_top"></span>
                                    <Card.Img variant="top" src={btc_img} />
                                    <div className='icon_text'>
                                        <h6>USDC</h6>
                                        <Card.Title>{shortTitle} 40 million USDC</Card.Title>
                                    </div>

                                </div>
                                <Card.Body>
                                    <Card.Text>
                                        <Link to="#">
                                            Wallet <ArrowRightAlt />
                                        </Link>
                                    </Card.Text>

                                    <div className='dropdown'>
                                        <Link to="#">Link 1</Link>
                                        <Link to="#">Link 2</Link>
                                    </div>

                                </Card.Body>
                            </Card>
                        </div>


                    </div>
                </div>


                <div className="assets_cards pt-5">
                    <h5>Learn how to protect your assets.</h5>
                    <h6>Read our Academy articles on how to protect your account on Web3.</h6>

                    <div className='row row_gap pt-4 pb-5'>
                        <div className='col-12 col-md-4 col-lg-4 asset-col-sec'>
                            <Card>
                                <Card.Img variant="top" src={pro_card1} />
                                <Card.Body>
                                    <Card.Text>$300M {shortTitle} Protection Fund to Protect User's Assets</Card.Text>
                                    <Card.Title>December 22, 2022 ｜10 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-md-4 col-lg-4 asset-col-sec'>
                            <Card>
                                <Card.Img variant="top" src={pro_card1} />
                                <Card.Body>
                                    <Card.Text>Solana's $6M Hack: How to Protect Your Money</Card.Text>
                                    <Card.Title>December 12, 2022 ｜ 5 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                        <div className='col-12 col-md-4 col-lg-4 trending-card'>
                            <Card>
                                <Card.Img variant="top" src={pro_card1} />
                                <Card.Body>
                                    <Card.Text>Rookie Mistakes When Starting Out in Crypto</Card.Text>
                                    <Card.Title>November 18, 2022 ｜ 10 min</Card.Title>

                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </div>


                <div className="pro_footer">
                    <div className="text_flex">
                        <h5>Your Security, Our Priority</h5>
                        <h6>At Kissanx, we understand that security is paramount in today’s digital landscape. That’s why we have made it our mission to ensure that your data and transactions are protected with the highest level of security.</h6>
                        <div>  <button className='new_custom_button new_custom_button_add'>Get started</button></div>
                    </div>
                    <img src={pro_footer} alt='' />

                </div>
            </div>

        </div>
    )
}

export default ProtectionFund