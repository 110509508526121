import React from 'react'
import Button from 'react-bootstrap/Button';
import { ArrowForwardIos } from '@mui/icons-material';
import { CopyAll } from '@mui/icons-material';
import ksntoken from '../../Assets/Image/ksntoken.png';
import img1 from '../../Assets/Image/sta_token_img1.png';
import img2 from '../../Assets/Image/sta_token_img1.png';
import img3 from '../../Assets/Image/sta_token_img1.png';
import img4 from '../../Assets/Image/sta_token_img1.png';
import img5 from '../../Assets/Image/sta_token_img1.png';
import img6 from '../../Assets/Image/sta_token_img1.png';
import investment_access from '../../Assets/Image/investment_access.svg';
import Card from 'react-bootstrap/Card';
import { logo, shortTitle } from '../../config/config';
import { Link } from "react-router-dom";

const StaToken = () => {
  return (

    <div className="statoken_page only-margin">
      <div className='coustom_container statoken_baner'>
        <div className="row">
          <div className="col-md-6">
            <h5>{shortTitle} Token: Your {shortTitle} to the Crypto Revolution</h5>

            <h6>Connect your crypto, DeFi, and NFTs like never before. Unlock Web3 today with the {shortTitle} Token from {shortTitle}.</h6>
            <div>
              <Button className='main-button' variant="success">Our vision <ArrowForwardIos style={{ fontSize: '15px' }} /></Button>
            </div>



          </div>

          <div className="col-md-6">
            <div className="right">
              {/* <img src={ksntoken} alt='' /> */}
            </div>
          </div>
        </div>

      </div>


      <div className="coustom_container token_profit">
        <div className="row">
          <div className="col-4_left">
            <div className="card-to">
              <div className="card-header">
                <h4>Est. APY</h4>
                <h1>6.00%</h1>
              </div>
              <div className="logo_img">
                <img src={logo} alt="logo" />
              </div>
              <div className="data_token">
                <h4>- - / - -</h4>
                <h5>Total {shortTitle} Subscribed / Total {shortTitle}</h5>
              </div>
              <div className="card-footer">
                <div className="footer_card_1">
                  <Button variant="success">subscribe</Button>
                  <Button variant="success">redeem</Button>

                </div>
                <div className='login_btn'>
                  <Button variant="success btn_style">Login</Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8_right">
            <div className="card_to_right">
              <div className="card-header">
                <h3>Current Profits</h3>
              </div>
              <div className="card-body">
                <div className="card-sec-body-1">
                  <div className="card-sec-body-1_data">
                    <h3>{shortTitle} Earn Rewards</h3>
                  </div>
                  <p>{shortTitle} Earn is an asset management platform on {shortTitle} for investing and growing your {shortTitle} holdings. You can earn {shortTitle} every day by subscribing to a variety of products that support early redemption.</p>
                </div>
                <div className="token___card_sec__ad12">
                  <div className="ad__12_data">
                    <h5>Total {shortTitle} Subscribed</h5>
                    <p>- -</p>
                  </div>
                  <div className="ad__12_data">
                    <h5>Cumulative Profit</h5>
                    <p>- -</p>
                  </div>
                </div>
                <h4 className="token_card_sec_title"> Other Events</h4>
                <div className="token___card_sec__ad12">
                  <div className="ad__12_data">
                    <h5>Current Profits</h5>
                    <p>- -</p>
                  </div>
                  <div className="ad__12_data">
                    <h5>24h Profits</h5>
                    <p>- -</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div className="coustom_container sta_power">
        <h6 className='heading'>Feel the power of {shortTitle}</h6>

        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img1} alt='' />
              <div className="">
                <h6>Futures Margin</h6>
                <p>Use {shortTitle} as a margin to unlock a 15% discount on trading fees.</p>
                <Link to="#">
                  Stay Tuned
                </Link>
              </div>


            </div>

          </div>

          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img2} alt='' />
              <div className="">
                <h6>Trading Fees</h6>
                <p>Pay with {shortTitle} to get a 20% discount on all trading fees.</p>
                <Link to="#">
                  check details
                </Link>
              </div>


            </div>

          </div>


          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img3} alt='' />
              <div className="">
                <h6>KOL Cooperation</h6>
                <p>Use {shortTitle} as collateral for more collaboration opportunities.</p>
                <Link to="#">
                  Stay Tuned
                </Link>
              </div>


            </div>

          </div>


          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img4} alt='' />
              <div className="">
                <h6>Impressive Rewards </h6>
                <p>Holding {shortTitle} gives you access to {shortTitle} rewards, private circle tickets, and more.</p>
                <Link to="#">
                  Stay Tuned
                </Link>
              </div>


            </div>

          </div>


          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img5} alt='' />
              <div className="">
                <h6>Earn Passive Income</h6>
                <p>Stake {shortTitle} to earn and enjoy value-added income.</p>
                <Link to="#">
                  Stay Tuned
                </Link>
              </div>


            </div>

          </div>


          <div className="col-md-6">
            <div className="card">
              <span className="border_left_top"></span>
              <span className="border_left_bottom"></span>
              <span className="border_right_bottom"></span>
              <span className="border_right_top"></span>

              <img src={img6} alt='' />
              <div className="">
                <h6>Earn Passive Income</h6>
                <p>Stake {shortTitle} to earn and enjoy value-added income.</p>
                <Link to="#">
                  Stay Tuned
                </Link>
              </div>


            </div>

          </div>


        </div>
      </div>
    </div >


  )
}

export default StaToken