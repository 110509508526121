import React, { useEffect, useState } from 'react'
import "react-tabs/style/react-tabs.css";
import { Link, useParams } from "react-router-dom";
// import img_icons from "../../Assets/Images/t.svg";
import _fetch from '../../config/api';
import { api_url, logo, receipt_img, title } from '../../config/config';
import ReactPaginate from 'react-paginate';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Nofound from '../../config/Nofound';


const ViewAdsPostData = (props: any) => {
    var storedNames = JSON.parse(localStorage.getItem("user") || "");
    const { order_type, id } = useParams()
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");



    // handle onChange event of the dropdown/////////////////////////////////////////////////
    //  const handleChange = (e) => {
    //     setSelectedOption(e);
    //     // setSearchedType('sell_currency');
    //     setsellCoinType(e.value)
    // }
    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allData, setallData] = useState([])
    async function getAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }

        if (order_type === 'buy') {
            var typeData = `buyer_order_id=${id}`
        } else {
            var typeData = `seller_order_id=${id}`

        }

        let d = await _fetch(`${api_url}trade/p2p-selfTransactonsDetails?${typeData}&page=${BuyItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallData(d?.data.data);
            setbuyRecords(d.total);
        }
    }


    useEffect(() => {
        document.title = props.title;

        getAllData()
    }, []);
    const [PassbtnDisable, setPassbtnDisable] = useState('');

    const ApproveData = async (id: any) => {
        // setPassbtnDisable(true)

        var data = {
            "id": id,
            "orderType": "sell"
        }
        var d = await _fetch(`${api_url}trade/p2p-approve-order`, 'POST', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            //  setOpenQR(false);
            setPassbtnDisable('')

            getAllData()

        } else {
            setPassbtnDisable('')

            toasted.error(d.message);

        }
    }


    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <>
            <div className=''>
                <div className='my-ads-post'>
                    <div className="new_market__p2p">
                        <div className="card " style={{ border: '0' }}>

                            <div className="table__p2p__history p-2">
                                <table className="table">
                                    <thead>
                                        <tr className="">
                                            <th>Advertiser</th>
                                            <th>Price</th>
                                            <th>Coin Request</th>
                                            <th>Amount (INR)</th>

                                            {/* <th>Available
                                                <span className="th__divider__span"></span>
                                                Limits
                                            </th> */}
                                            <th>Payment Method	</th>
                                            <th>Type	</th>
                                            <th>Order ID</th>
                                            <th> Receipt</th>
                                            <th>Tranaction ID</th>
                                            <th>Status</th>
                                            {order_type !== 'buy' && <th>Action</th>}

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <Nofound/>
                                        
                                        {allData?.map((val: any) => {
                                            return (
                                                <>
                                                    {order_type === 'buy' && <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                {/* <div className="Img__user__p2p">A</div> */}
                                                                <div className="user__info__p2p">
                                                                    <p>{val.buy_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                        <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Receivable</p>
                                                                    <p>{val.received_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                </div>
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Pending</p>
                                                                    <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> ---- </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> BUY </div>
                                                        </td>
                                                        <td><div className='mad-btn'>{val.seller_order_id}</div></td>

                                                        <td><div className='mad-btn'>{val.order_status}</div></td>

                                                        {/* <td>
                                                                        <div className="btn_hit_p2p">
                                                                            <Link className="trade_btn active" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                                        </div>
                                                                    </td> */}
                                                    </tr>}

                                                    {order_type !== 'buy' && <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                {/* <div className="Img__user__p2p">A</div> */}
                                                                <div className="user__info__p2p">
                                                                    <p>{val.sell_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                        <td>{val.amount?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                        <td>{(val.amount * val.price)?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>

                                                        {/* <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Available</p>
                                                                    <p>{val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                </div>
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Limits</p>
                                                                    <p>{val.minimum_limit} ~ {val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                </div>

                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            {val.payment_method?.map((method: any) => {
                                                                return (
                                                                    <div className="mad-btn"> {method.type} </div>

                                                                );
                                                            })}
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> Sell </div>
                                                        </td>
                                                        <td>
                                                            <div className="mad-btn"> {val.buyer_order_id} </div>
                                                        </td>
                                                        <td>
                                                            <div className='mad-btn'>
                                                                <img src={receipt_img + val.image} alt="Tranaction Image" />
                                                            </div>
                                                        </td>
                                                        {/* <td><div className='mad-btn'>{val.receipt}</div></td> */}
                                                        <td><div className='mad-btn'>{val.trx_id}</div></td>
                                                        <td><div className='mad-btn'>{val.order_status}</div></td>


                                                        <td>
                                                            <div className="btn_hit_p2p">
                                                                {val.order_status !== 'completed' && <button className="btn table-buy-btn" onClick={() => { ApproveData(val._id); setPassbtnDisable(val._id) }}>
                                                                    {PassbtnDisable === val._id &&
                                                                        <div className="color-ring">
                                                                            <ClipLoader color="#36d7b7" />

                                                                            Please Wait...
                                                                        </div>
                                                                    }
                                                                    {PassbtnDisable === '' || PassbtnDisable !== val._id ? 'Approve' : ''}   </button>}
                                                                {val.order_status === 'completed' && <button className="btn table-buy-btn" style={{ background: 'green', color: '#fff' }} > Approved  </button>}
                                                            </div>
                                                        </td>
                                                    </tr>}
                                                </>
                                            );
                                        })}

                                    </tbody>
                                </table>

                                <div className="update_trending_coin_tabs">
                                    <div className="tabs_content active">
                                        <div className="content_inner_data"></div>
                                        {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={BuyhandlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={buyRecords / 10}
                                            previousLabel="<"

                                        // renderOnZeroPageCount={null}
                                        />}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div>


            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "Tranaction_image",
                }}>
                <div className='New__sad p-2'>
                    {allData?.map((val: any) => {
                        return (
                            <>
                                <img src={val.receipt} alt='Tranaction_image' height="200px" />
                            </>
                        )
                    })}

                </div>
            </Modal>




        </>
    )
}

export default ViewAdsPostData