import React from 'react'
import proofreserves_banner from '../../Assets/Image/proofreserves_banner.png';
import taskgift from '../../Assets/Image/taskgift.png';
import userguide_card from '../../Assets/Image/userguide_card.jpg';
import rewards_draw from '../../Assets/Image/rewards_draw.svg';
import draw_gift from '../../Assets/Image/draw_gift.svg';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { shortTitle } from '../../config/config';


const Rewards = () => {
    return (

        <div className="main_rewardspage only-margin">

            <div className=" baner_bg">
                <div className="coustom_container">
                    <div className="rewards_banner">
                        <div className="row row_gap">
                            <div className="col-md-6 align_left">
                                <h5>Complete tasks to earn up to 5,00 USDT</h5>
                                <h6>Connect your crypto, DeFi, and NFTs like never before. Unlock Web3 today with the {shortTitle} Token from {shortTitle}.</h6>

                                <div className="btn_flex">
                                    <button className='new_custom_button new_custom_button_add'>Log In</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="affiliates-plan-step">
                <div className="coustom_container block-center-x">
                    <h2 className='heading'>How to get commissions</h2>
                    <div className="flex-row mt45">
                        <ul className="flex-row items-center block-center-x">
                            <li className="flex-row items-center relative step-item step1">
                                <div className="absolute text-content">
                                    <h2>Sign Up</h2>
                                    <p>Sign Up To Begin Your Journey With KSN</p>
                                </div>
                            </li>
                            <li className="item-line"></li>
                            <li className="flex-row items-center relative step-item step2">
                                <div className="absolute text-content">
                                    <h2>Deposit</h2>
                                    <p>Earn Rewards Worth Up To 5,00 USDT</p>
                                </div>
                            </li>
                            <li className="item-line"></li>
                            <li className="flex-row items-center relative step-item step3">
                                <div className="absolute text-content">
                                    <h2>Trade</h2>
                                    <p>Earn Rewards Worth Up To 5 USDT</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



            <div className="coustom_container user_guide pt-5">
                <h5>User Guide</h5>

                <div className="row">
                    <div className="col-4 ">
                        <Card className='card-1'>
                            <img src={userguide_card} alt="" />
                            <div className="inner_info">
                                <h1>How To Trade In The Futures Market？</h1>
                            </div>
                        </Card>
                    </div>

                    <div className="col-4">
                        <Card className='card-1'>
                            <img src={userguide_card} alt="" />
                            <div className="inner_info">
                                <h1>How To Trade In The Futures Market？</h1>
                            </div>
                        </Card>
                    </div>

                    <div className="col-4 ">
                        <Card className='card-1'>
                            <img src={userguide_card} alt="" />
                            <div className="inner_info">
                                <h1>How To Trade In The Futures Market？</h1>
                            </div>
                        </Card>


                    </div>
                </div>

            </div>


        </div >
    )
}

export default Rewards