import React from 'react'
import { title } from '../../config/config'
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import WindowIcon from '@mui/icons-material/Window';
import QrCodeIcon from '@mui/icons-material/QrCode';
import qrcode from "../../Assets/img/qr.png";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const Download = () => {
    return (
        <div>
            <div className="download_sec mt-4">
                <div className="coustom_container">
                    {/* <h1 className='heading'>Access  {title}  Anytime</h1> */}
                    <div className="row for_driction">
                        <div className="col-lg-6 new_add-class"></div>
                        <div className="col-lg-6">
                            <div className="info_sec">

                                <div className="inner">
                                    <h1>Trade Anywhere with the {title} App</h1>
                                    <h2>Trade, deposit, and withdraw crypto on the move with our mobile app. </h2>
                                    <div className="links">
                                        <div className="qr_sec">
                                            <div className="inner">
                                                <img src={qrcode} alt="" />
                                                <div className="info">
                                                    <p>Scan to download App</p>
                                                    <h3>iOS and Android</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='go-sec'>
                                            <a href="#" className='store-sec'>
                                                <AppleIcon /> App Store
                                            </a>
                                            <a href="#">
                                                <ShopIcon /> Google Play
                                            </a>
                                            <a href="#" className='store-sec'>
                                                <WindowIcon /> Windows
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Download
