import React, { useState } from "react";
import { SidebarData, SidebarDataNew } from "../ApiSidebarData/SidebarData";
import { Link, useLocation } from "react-router-dom";
import LeftDrawer from "./LeftDrawer";


const ApiSidebar = () => {

  let location = useLocation();

  function handleClose(): void {
    console.log("Drawer closed");
  }

  return (
    <>
      <div className="sidebar-main">
        <div className="first-sec">
          <h6>Getting Started</h6>

          {SidebarData.map((val, key) => {
            return (
              <ul key={key}>
                <Link
                  to={val.link}
                  id={location.pathname === val.link ? "active" : ""}
                >
                  <li id={location.pathname === val.link ? "active" : ""}>
                    <div id="icon">{val.icon}</div>
                    <div id="title">{val.titleNew}</div>
                  </li>
                </Link>
              </ul>
            );
          })}
        </div>
        <div className="second-sec">
          <h6>Api Endpoints</h6>

          {SidebarDataNew.map((val, key) => {
            return (
              <ul key={key}>
                <Link
                  to={val.link}
                  id={window.location.pathname === val.link ? "active" : ""}
                >
                  <li
                    id={window.location.pathname === val.link ? "active" : ""}
                  >
                    <div id="icon">{val.icon}</div>
                    <div id="title">{val.titleNew}</div>
                  </li>
                </Link>
              </ul>
            );
          })}
        </div>
      </div>

      <div className="mob-sidebar">
        {
          <LeftDrawer isOpen={true} onClose={handleClose}>
            <div className="sidebar-main-mob">
              <div className="first-sec">
                <h6>Getting Started</h6>

                {SidebarData.map((val, key) => {
                  return (
                    <ul key={key}>
                      <Link
                        to={val.link}
                        id={location.pathname === val.link ? "active" : ""}
                      >
                        <li id={location.pathname === val.link ? "active" : ""}>
                          <div id="icon">{val.icon}</div>
                          <div id="title">{val.titleNew}</div>
                        </li>
                      </Link>
                    </ul>
                  );
                })}
              </div>
              <div className="second-sec">
                <h6>Api Endpoints</h6>

                {SidebarDataNew.map((val, key) => {
                  return (
                    <ul key={key}>
                      <Link
                        to={val.link}
                        id={
                          window.location.pathname === val.link ? "active" : ""
                        }
                      >
                        <li
                          id={
                            window.location.pathname === val.link
                              ? "active"
                              : ""
                          }
                        >
                          <div id="icon">{val.icon}</div>
                          <div id="title">{val.titleNew}</div>
                        </li>
                      </Link>
                    </ul>
                  );
                })}
              </div>
            </div>
          </LeftDrawer>
        }
      </div>
    </>
  );

}

export default ApiSidebar