import React, { useEffect, useState } from 'react'
import {
    Person,
    AppRegistration,
    Sms,
    DoNotDisturb,
} from "@mui/icons-material";
import { Button } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
const Authentication = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const [activeToggle, setactiveToggle] = useState(false);
    const [activeToggle2, setactiveToggle2] = useState(false);
    const [activeToggle3, setactiveToggle3] = useState(false);
    const [changeSecurity, setchangeSecurity] = useState('none');

    const onOpenModalTab2 = () => {
        setOpenModal(true);
    };
    const onOpenModalTab3 = () => {
        setOpenModal2(true);
    };
    const onOpenModalTab4 = () => {
        setOpenModal3(true);
    };

    const onCloseModal = () => {
        setOpenModal(false);
        setOpenModal2(false)
        setOpenModal3(false)
    };
    const [sec2faImg, setsec2faImg] = useState('');
    const [sec2faCode, setsec2faCode] = useState('');
    const [timer, settimer] = useState('00:00');
    const [otp2fa, setotp2fa] = useState();

    async function security2fa() {
        toasted.success('Please Wait');

        var d = await _fetch(`${api_url}auth/generateQrCode`, 'GET', {}, {});
        if (d?.status === 'success') {
            setsec2faImg(d.data.qr_url);
            setsec2faCode(d.data.base32);
            onOpenModalTab2();
            setactiveToggle(true);
            setactiveToggle2(false);
            setactiveToggle3(false);
            if (timer === "00:00") {
                var seconds: any = '120';
                var intervalId = window.setInterval(function () {
                    seconds--;
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;
                    settimer(leftData);
                    if (seconds === 0) {
                        security2fa();
                        stop();
                    }
                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            toasted.error(d.message);

        }
    }


    const submit2Fa = async (e: any) => {
        e.preventDefault();
        const data = {
            "base32": sec2faCode,
            "otp": otp2fa

        }
        var d = await _fetch(`${api_url}auth/activate2FA`, 'POST', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            setOpenModal(false);
            //  setOpenQR(false);
            getUserInfo()
        } else {
            toasted.error(d.message);

        }
    }
    const [phoneOtp, setphoneOtp] = useState('00:00');

    async function getphone2faOtp(remove: any) {
        toasted.success('Please Wait');

        var d = await _fetch(`${api_url}auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
        if (d?.status === 'success') {
            if (remove !== true) {
                onOpenModalTab3();
                setactiveToggle2(true);
                setactiveToggle3(false)
                setactiveToggle(false)
            } else {
                onOpenModalTab4();
                setactiveToggle2(false);
                setactiveToggle3(true)
                setactiveToggle(false)
            }
            if (phoneOtp === "00:00") {
                var seconds: any = '120';
                var intervalId = window.setInterval(function () {
                    seconds--;
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;
                    setphoneOtp(leftData);
                    if (seconds === 0) {
                        stop();
                    }
                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
            toasted.success(d.message);
        }
    }

    const [mobileotp, setmobileotp] = useState()

    const submitPhone2Fa = async (e: any) => {
        e.preventDefault();
        const data = {
            "otp": mobileotp
        }
        var d = await _fetch(`${api_url}auth/activate2Auth`, 'POST', data, {});
        if (d?.status === 'success') {
            toasted.success(d.message);
            setOpenModal2(false);
            setOpenModal3(false);
            setOpenModal(false)
            getUserInfo();
        } else {
            toasted.error(d.message);
        }
    }

    const [removeOtp, setremoveOtp] = useState();
    const submitRemove2Fa = async (e: any) => {
        e.preventDefault();
        const data = {
            "otp": removeOtp
        }
        var d = await _fetch(`${api_url}auth/deactivateAuth`, 'POST', data, {});
        if (d?.status === 'success') {
            setOpenModal3(false);
            if (changeSecurity === '2fa') {
                security2fa()
            } else if (changeSecurity === 'other') {
                getphone2faOtp(false)
            }
            getUserInfo()
            toasted.success(d.message);
        } else {
            toasted.error(d.message);

        }
    }

    const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
    async function getUserInfo() {
        var d = await _fetch(`${api_url}user/userInfo`, 'GET', {}, {});
        if (d?.status === 'success') {
            setUserInfo(d?.data?.data);

            if (d?.data?.data?.security_type === 'none') {
                setactiveToggle3(true);
                setactiveToggle2(false);
                setactiveToggle(false);

            } else if (d?.data?.data?.security_type === '2fa') {
                setactiveToggle3(false);
                setactiveToggle2(false);
                setactiveToggle(true);
            } else if (d?.data?.data?.security_type === 'other') {
                setactiveToggle3(false);
                setactiveToggle2(true);
                setactiveToggle(false);
            }

        }
    }

    useEffect(() => {
        getUserInfo();
    }, [])
    return (
        <>
            <div className="tab-body">
                <div className="email-sec">
                    <div className="sec1">
                        <div className="icon-sec">
                            <AppRegistration className="icon-mui" />
                        </div>
                        <div className="txt-sec">
                            <h5>Authenticator App Recommended Highly Secure</h5>
                        </div>
                    </div>
                    <div className="btn-sec">
                        <Button variant="success" onClick={() => { setchangeSecurity('2fa'); userInfo.security_type !== '2fa' ? userInfo.security_type !== 'other' ? security2fa() : getphone2faOtp(true) : onOpenModalTab4() }}>
                            Enable
                        </Button>
                    </div>
                </div>

                <div className="email-sec">
                    <div className="sec1">
                        <div className="icon-sec">
                            <Sms className="icon-mui" />
                        </div>
                        <div className="txt-sec">
                            <h5> Mobile SMS Moderately Secure 2FA Enabled On
                                <span>  {" " + userInfo?.email}</span>
                            </h5>
                        </div>
                    </div>
                    <div className="btn-sec">
                        <Button variant="success" onClick={() => { setchangeSecurity('other'); userInfo.security_type !== 'other' ? userInfo.security_type !== '2fa' ? getphone2faOtp(false) : onOpenModalTab4() : getphone2faOtp(false) }}>Enable</Button>

                    </div>
                </div>

                <div className="email-sec">
                    <div className="sec1">
                        <div className="icon-sec">
                            <DoNotDisturb className="icon-mui" />
                        </div>
                        <div className="txt-sec">
                            <h5> None</h5>
                        </div>
                    </div>
                    <div className="btn-sec">
                        <Button variant="success" onClick={() => { setchangeSecurity('none'); userInfo.security_type === 'other' ? getphone2faOtp(true) : onOpenModalTab4(); setactiveToggle3(true); setactiveToggle2(false); setactiveToggle(false) }}>Enable</Button>
                    </div>
                </div>
            </div>



            {/* Tab 2 Modal  */}
            <Modal
                open={openModal}
                onClose={onCloseModal}
                classNames={{
                    modal: "New_modal2",
                }}
                center
            >
                <div className="main-sec-modal2">
                    <h4>2fa Authentication</h4>

                    <div className='d-flex justify-content-center align-items-center mb-4'>
                        <img src={sec2faImg} alt='Authentication-img' className='m-auto' />

                    </div>

                    <div className="form">
                        <form>
                            <div className="input-1">
                                <label>2fa Authentication</label>
                                {/* <span></span> */}
                                <input type="number" onChange={(e: any) => { setotp2fa(e.target.value) }} value={otp2fa} className='form-control' placeholder='Enter OTP' />
                            </div>
                            <button type="button" onClick={submit2Fa} className="btn submit-button">
                                Submit
                            </button>
                        </form>
                        <div className="otp-sec text-center mt-2">
                            <span>Valid {timer}</span>
                            <br />
                            <span>
                                {/* Resend in <OtpCounter /> seconds */}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* // 2fa modal closed 
          mobile modal start
          */}
            <Modal
                open={openModal2}
                onClose={onCloseModal}
                classNames={{
                    modal: "New_modal2",
                }}
                center
            >
                <div className="main-sec-modal2">
                    <h5>Activate Email/Mobile OTP</h5>
                    <div className="form mt-3">
                        <form>
                            <div className="input-1">
                                <label>Activate Authentication OTP</label>
                                <span></span>
                                <input type="number" onChange={(e: any) => { setmobileotp(e.target.value) }} placeholder='Enter OTP Sent To Your Mobile' className='form-control' />
                            </div>
                            <button type="button" className="btn submit-button" onClick={submitPhone2Fa}>
                                Submit
                            </button>
                        </form>
                        <div className="otp-sec text-center mt-3">
                            <span>OTP Expire in {phoneOtp}</span><br />
                            <span>Didn't Receive OTP Yet?</span>
                            <br />
                            <span>
                                {/* Resend in <OtpCounter /> seconds */}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* // mobile modal closed 
         
         none modal start
         */}
            <Modal
                open={openModal3}
                onClose={onCloseModal}
                classNames={{
                    modal: "New_modal2",
                }}
                center
            >
                <div className="main-sec-modal2">
                    <h5>Remove Authentication</h5>
                    <div className="form">
                        <form>
                            <div className="input-1">
                                <label>Remove Authentication</label>
                                {/* <span> </span> */}
                                <input type="number" onChange={(e: any) => { setremoveOtp(e.target.value) }} placeholder='Enter OTP' className='form-control' />
                            </div>
                            <button type="button" onClick={submitRemove2Fa} className="btn submit-button">
                                Submit
                            </button>
                        </form>
                        <div className="otp-sec text-center mt-3">
                            <span>Didn't Receive OTP Yet?</span>
                            <br />
                            <span>
                                {/* Resend in <OtpCounter /> seconds */}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Authentication