import React from 'react';
import { Button, Form } from 'react-bootstrap';
import coreum from '../../Assets/Image/pro_card1.png';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import morning_cup from '../../Assets/Image/morning_cup.png';
import { shortTitle, title } from '../../config/config';
const STABlog = () => {
  return (
    <>

      <div className='poloniex only-margin'>
        <div className='coustom_container pt-5'>
          <div className='heading-sec'>
            <div className='title'>{title} Blog

            </div>
            <div className='searchbar'>
              <Form.Control type="text" placeholder="Search" />
            </div>

          </div>
        </div>
      </div>
      <div>
        <div className='coustom_container corem-desc'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 left-sec'>
              <a href="/sta-inner-blog">
                <img src={coreum} />
              </a>
            </div>

            <div className='col-lg-6 col-md-6 right-sec'>
              <a href="/sta-inner-blog" className='grade'>
                <h4>What is {title}?</h4>
                <p>{title} is a Layer 1 Enterprise Grade Blockchain, which means it is geared toward business use cases. It provides the infrastructure for use cases like digital assets, issuing stablecoins, traditional asset tokenization, CBDCs, smart tokens and NFTs.</p>
                <div className='date-sec'>
                  May 24, 2023
                  <span><AccessTimeIcon className='clock-time' />5 min</span>
                </div>
              </a>
            </div>
          </div>
          <div className='link-series'>
            <div>
              <a href="/">
                <div className='link-items'>Ethereum</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>NFT</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Trading</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Blockchain</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Core</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Polygon</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>{title}</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>{title}</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Curve</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Capella</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>DEX</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Staking</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>{title}</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Market News</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Polopedia</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Fan Tokens</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>Futures</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>News</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>AMM</div>
              </a>
            </div>
            <div>
              <a href="/">
                <div className='link-items'>AMA</div>
              </a>
            </div>
          </div>
          <div className='row morning-cup'>
            <div className='col-12 col-sm-6  col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        <div>{shortTitle} Newsletter</div>

                      </div>

                      <div className='btn-1'>

                        <div>The Morning Cup</div>

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3>☕️The Morning Cup: Bitcoin’s got NFTs </h3>
                        <p>Well, well, well... how the tables turn! In the short time since the Ordinals Protocol dropped in January 2023, an update that allows for the inscription of data into Satoshis</p>

                      </div>
                    </div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>

            <div className='col-12 col-sm-6  col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        <div>{shortTitle} Newsletter</div>

                      </div>

                      <div className='btn-1'>

                        <div>The Morning Cup</div>

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3>  ☕️BRC-20 has exploded in popularity. But not everybody's onboard.  </h3>
                        <p> Although not necessarily new, the token standard on the Bitcoin network has recently seen a meteoric rise in popularity. But this rise has been met with a fair bit of controversy.  </p>

                      </div>
                    </div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>
            <div className='col-12 col-sm-6  col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        {shortTitle} Newsletter

                      </div>

                      <div className='btn-1'>

                        The Morning Cup

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3> ☕️ $PEPE explodes…. then sputters a bit </h3>
                        <p> In a twist that has left the altcoin world astounded, Pepecoin (PEPE) holders are likely reaping the rewards of their investment, following an almost unbelievable surge of 5,000,000% in the past few weeks. PEPE, however, has experienced a downturn of nearly 45% after peaking at $0.00000431 last Friday. With this peak, the coin's market capitalization hit an impressive $1.8 billion, achieved in just over three weeks since its mid-April issuance. </p>

                      </div>
                    </div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        <div>{shortTitle} Newsletter</div>

                      </div>

                      <div className='btn-1'>

                        <div>The Morning Cup</div>

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3> How will Web3 change digital identity? </h3>
                        <p> Web3 is an Internet built on blockchains that is decentralized and peer-to-peer. What this looks like for users on a day-to-day level is using an internet for the same things they already use it for, but under a system that puts users first, </p>

                      </div></div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>

            <div className='col-12 col-sm-6  col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        <div>{shortTitle} Newsletter</div>

                      </div>

                      <div className='btn-1'>

                        <div>The Morning Cup</div>

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3> Letter to our users upon the launch of our Liquid Staking product </h3>
                        <p> The team here at Poloniex is thrilled to announce the launch of our new Liquid Staking product! This has been a labor of love for us and we are excited to finally share it with all of you.</p>

                      </div></div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 cup-sec'>
              <a href="/sta-inner-blog">
                <div className='card'>
                  <img src={morning_cup}></img>
                  <div className='card-section'>
                    <div className='poloniex-btn'>
                      <div className='btn-1'>

                        <div>{shortTitle} Newsletter</div>

                      </div>

                      <div className='btn-1'>

                        <div>The Morning Cup</div>

                      </div>

                    </div>
                    <div className='corem-section'>
                      <div className='corem-layer'>
                        <h3> ☕️{title} launches Liquid Staking </h3>
                        <p> Liquid staking is on Poloniex, and its memecoin summer 🐸🐶 </p>

                      </div></div>

                    <div className='date-section'>
                      May 24, 2023
                      <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>

                  </div>
                </div>
              </a>
            </div>

          </div>



          <div className='load-more'><Button className='load-btn'>Load More</Button></div>
        </div>
      </div >
    </>
  )
}

export default STABlog;