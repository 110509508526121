import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import { logo, shortTitle, title, website } from "../config/config";
import InfoSec from "./InfoSec";
import { Accordion } from "react-bootstrap";

const Footer = () => {
  const showContent = (e: any) => {
    const abSD: HTMLElement | any = document.getElementById("sddsf");
    abSD.classList.toggle("nav__item_dropw");
    var sibling = e.currentTarget.nextElementSibling;
    var siblingHeight = sibling.scrollHeight;

    let main = e.currentTarget.classList.toggle("show");

    if (main) {
      sibling.setAttribute("style", `height:${siblingHeight}px`);
      console.log(siblingHeight);
    } else {
      sibling.removeAttribute("style");
    }
  };

  window.scrollTo(0, document.body.scrollTop);

  return (
    <>
      <footer className="Footer__public___12 s-primary-bg">
        <div className="coustom_container footer-section-container">
          {/* footer navigation */}
          <nav className="footer_nav">
            <li className=" footer_navlist_A">
              <div className="web__f__deatial-12">
                <div className="inner">
                  <h1>{title.toUpperCase()}</h1>
                  <p>Your future is bright with {title} </p>
                </div>
              </div>
            </li>
            <ul className="footer_navlist">
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Company
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/about"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/protection-fund"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Protection Fund
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/proof-reserves"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Proof of Reserves
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/ksn-token"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      {shortTitle} Token
                    </Link>
                  </li>
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/buy-crypto"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Buy Crypto
                    </Link>
                  </li> */}
                  <li className="footer_sub_navitem">
                    <Link
                      to="/careers"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Careers
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Service
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/ksn-launchpad"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      {shortTitle} Launchpad
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/promotions"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Promotions
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/referral-program"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Referral Program
                    </Link>
                  </li>
                  {/* <li className="footer_sub_navitem">
                    <Link
                      to="/affiliate-program"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Affiliate program
                    </Link>
                  </li> */}
                  <li className="footer_sub_navitem">
                    <Link
                      to="/vip-services"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      VIP Services
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/institutional-services"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Institutional Services
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/getting-api-key"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      API Trading
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Legal & Disclosures
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/regulatory-license"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Regulatory License
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis" >
                      AML/KYC Policies
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Legal Statement
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/privacy-policy"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Risk Disclosure
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Support
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/feedback"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Feedback
                    </Link>
                  </li>

                  <li className="footer_sub_navitem">
                    <Link
                      to="/fee-schedule"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Fee Schedule
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/verification-channel"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Official Verification Channel{" "}
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/buy-bitcoin"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Buy Bitcoin
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="footer_navitem">
                <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                  Copy Trading
                </h3>
                <ul className="footer_sub_navlist">
                  <li className="footer_sub_navitem">
                    <Link
                      to="/copy-trading"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Copy Trading
                    </Link>
                  </li>
                  <li className="footer_sub_navitem">
                    <Link
                      to="/startagy-trading"
                      className="footer_nav_link s-normal-color txt-ellipsis"
                    >
                      Strategy Trading
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </nav>

          {/* mobile footer */}

          <div className="mobile__footer">
            <div className="web__f__deatial-15">
              <div className="f-web-logo-12">
                <Link to="/" className="footer_logo_A line-height-0">
                  <img src={logo} alt="" width="100%" />
                </Link>

              </div>
            </div>
            <div className="inner">
              <div className="right_header-sidebar">

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Company <KeyboardArrowDownIcon /></Accordion.Header>
                    <Accordion.Body>
                      <Link to="/about" className="nav-link">
                        About Us
                      </Link>
                      <Link to="/protection-fund" className="nav-link">
                        Protection Fund
                      </Link>
                      <Link to="/proof-reserves" className="nav-link">
                        Proof of Reserves
                      </Link>

                      <Link to="/ksn-token" className="nav-link">
                        {shortTitle} Token
                      </Link>
                      {/* <Link to="/buy-crypto" className="nav-link">
                          Buy Crypto
                        </Link> */}
                      <Link to="/careers" className="nav-link">
                        Careers
                      </Link>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Service <KeyboardArrowDownIcon /></Accordion.Header>
                    <Accordion.Body>
                      <Link to="/ksn-launchpad" className="nav-link">
                        {shortTitle} Launchpad
                      </Link>
                      <Link to="/promotions" className="nav-link">
                        Promotions
                      </Link>
                      <Link to="/referral-program" className="nav-link">
                        Referral Program
                      </Link>
                      {/* <Link to="/affiliate-program" className="nav-link">
                          Affiliate program
                        </Link> */}
                      <Link to="/vip-services" className="nav-link">
                        VIP Services
                      </Link>
                      <Link to="/institutional-services" className="nav-link">
                        Institutional Services
                      </Link>
                      <Link to="/api-trading" className="nav-link">
                        API Trading
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Legal & Disclosures <KeyboardArrowDownIcon /></Accordion.Header>
                    <Accordion.Body>
                      <Link to="/regulatory-license" className="nav-link">
                        Regulatory License
                      </Link>
                      <Link to="/privacy-policy" className="nav-link">
                        AML/KYC Policies
                      </Link>
                      <Link to="/privacy-policy" className="nav-link">
                        Privacy Policy{" "}
                      </Link>
                      <Link to="/privacy-policy" className="nav-link">
                        Terms of Use
                      </Link>
                      <Link to="/privacy-policy" className="nav-link">
                        Legal Statement
                      </Link>
                      <Link
                        to="/privacy-policy"
                        className="nav-link"
                      >
                        Risk Disclosure
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Support <KeyboardArrowDownIcon /></Accordion.Header>
                    <Accordion.Body>
                      <Link to="/feedback" className="nav-link">
                        Feedback
                      </Link>
                      <Link to="/fee-schedule" className="nav-link">
                        Fee Schedule{" "}
                      </Link>
                      <Link to="/verification-channel" className="nav-link">
                        Official Verification Channel
                      </Link>
                      <Link to="/buy-bitcoin" className="nav-link">
                        Buy Bitcoin
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Copy Trading <KeyboardArrowDownIcon /></Accordion.Header>
                    <Accordion.Body>
                      <Link to="/copy-trading" className="nav-link">Copy Trading</Link>
                      <Link to="/startagy-trading" className="nav-link">Strategy Trading   </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          {/* footer bottom */}
          <div className="footer_bottom">
            <div className="footer_bottom_left">
              <p className="sec_info s-normal-color opacity-0-7">
                CopyRight © {new Date().getFullYear()}{" "}
                <Link className="sec_info s-normal-color " to="/">
                  {title}
                </Link>{" "}
                All Rights Reserved.
              </p>
            </div>
            <div className="footer_navitem add__class">
              <div className="more-footer-links">
                <div className="two-two">
                  <Link to="/" className="media-link-footer">
                    {" "}
                    <FacebookIcon />{" "}
                  </Link>
                  <Link to="/" className="media-link-footer">
                    <InstagramIcon />{" "}
                  </Link>
                </div>
                <div className="two-two">
                  <Link to="/" className="media-link-footer">
                    {" "}
                    <TwitterIcon />{" "}
                  </Link>
                  <Link to="/" className="media-link-footer">
                    {" "}
                    <GoogleIcon />{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
