import { FilePresent } from '@mui/icons-material'
import React from 'react'

const Nofound = () => {
    return (
        <tr className="__found">
            <td colSpan={12}>
                <div className="__no_found">
                    <FilePresent />
                    <p>We regret to inform you that the requested data is not available!</p>
                </div>
            </td>
        </tr>
    )
}

export default Nofound