import React, { useEffect, useState } from "react";
import { ArrowBackIosNew, KeyboardArrowRight, FilePresent } from "@mui/icons-material";
import Select from 'react-select';
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import toasted from "../../config/toast";
import { ClipLoader } from 'react-spinners'




const Transfer = () => {

  const [selectedOption, setSelectedOption] = useState({ value: `main_account`, text: <span className="new__a"> Main Account</span>, });
  const [sendTo, setSendTo] = useState({ value: `trade_account`, text: <span className="new__a"> Trade Account</span>, });
  const [coinSelected, setCoinSelected] = useState();
  const [coinOption, setCoinOption] = useState([]);
  const [amount, setAmount] = useState(0);
  const [btnDisable, setbtnDisable] = useState(false);



  async function getMarketdata() {
    let data = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=${selectedOption.value}`, 'GET');
    if (data?.status === 'success') {
      const selectedValue = data?.market?.[0];

      if (coinSelected === undefined) {
        setCoinSelected({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()}</span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" />,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
        });

        handleCoinChange({
          value: selectedValue?.symbol,
          text: <span className="new__a"> {selectedValue?.symbol.toUpperCase()}</span>,
          icon: <img src={selectedValue?.image} className="option-img-a__A" />,
          total_coin: selectedValue?.total_coin,
          coinID: selectedValue?.coin_id,
        });
      }

      data?.market?.map((value) => {
        if (value.total_coin > 0) {
          var newValue = {
            value: value?.symbol,
            text: <span className="new__a"> {value?.symbol.toUpperCase()}</span>,
            icon: <img src={value?.image} className="option-img-a__A" />,
            total_coin: value?.total_coin,
            coinID: value?.coin_id,
          };

          setCoinOption(elpMarket => [...elpMarket, newValue]);
        }
      })

    }
  }

  useEffect(() => {
    getMarketdata();
  }, [selectedOption]);

  function reset() {
    setCoinSelected('');
    setCoinSelected('');
    setAmount();
    setSendTo();
    setSelectedOption();
  }

  async function SubmitTransferFund() {
    setbtnDisable(true)

    let transferData = {
      chain_id: coinSelected.coinID,
      symbol: coinSelected.value,
      amount: amount,
      transfer_to: sendTo.value,
      transfer_from: selectedOption.value
    }

    let data = await _fetch(`${api_url}incomes/transfer`, 'POST', transferData);
    if (data?.status === 'success') {
      toasted.success(data?.message);
      setbtnDisable(false)
    }
    else {
      toasted.error(data?.message);
      setbtnDisable(false)


    }
  }



  const data = [
    {
      value: `main_account`,
      text: <span className="new__a"> Main Account</span>,
      // icon: <img src={BTC} className="option-img-a__A" />
    },
    {
      value: `funding_account`,
      text: <span className="new__a"> Funding Account</span>,
      // icon: <img src={USD} className="option-img-a__A" />
    },
    {
      value: `trade_account`,
      text: <span className="new__a"> Trade Account</span>,
      // icon: <img src={TRX} className="option-img-a__A" />
    },
    // {
    //     value: `eth`,
    //     text: <span className="new__a"> ETH</span>,
    //     // icon: <img src={ETH} className="option-img-a__A" />
    // },

  ];

  const handleChange = (e) => {
    setSelectedOption(e);
  }

  const handleCoinChange = (e) => {
    setCoinSelected(e);
  }

  function Toggle() {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
      setIsChecked(!isChecked);
    };

    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="toggle-slider"></span>
      </label>
    );
  }

  // function switch input fields



  return (
    <>
      <div className="main-transfer">
        <div className="inner-crum">
          <div className="__links">
            <h3>Transfer</h3>
          </div>
        </div>


        <div className="form-sec">
          <div className="inner-sec">

            <div className="middle-sec">
              <div className="form-main">
                <form>
                  <div className="spot-account">
                    <span>Spot Account</span>
                    <div className="select-coin2">
                      <Select
                        placeholder="Select option"
                        value={selectedOption}
                        className="select-option-s"
                        options={data}
                        onChange={handleChange}
                        getOptionLabel={(e) => (
                          <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                            {e.icon}
                            <span style={{ marginLeft: '5px' }}>{e.text}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className="switch-val">
                    <SwapVert className="icon" />
                  </div> */}
                  <div className="future-account">
                    <span>Future Account</span>
                    <div className="select-network2">
                      {/* <select placeholder="Select Network">
                          <option>TRX</option>
                          <option>BTC</option>
                        </select> */}
                      <Select
                        placeholder="Select option"
                        value={sendTo}
                        className="select-option-s"
                        options={data}
                        onChange={(e) => { setSendTo(e) }}
                        getOptionLabel={(e) => (
                          <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                            {e.icon}
                            <span style={{ marginLeft: '5px' }}>{e.text}</span>
                          </div>
                        )}
                      />
                    </div>

                    {/* <div className="tooltip-form">
                      <p>Please activate your Futures Account before transferring the balance. <Button variant="success">Enable</Button></p>

                    </div> */}
                  </div>
                  <div className="coin">
                    <span>Select Coin</span>
                    <div className="select-network2">
                      {/* <select placeholder="Select Network">
                          <option>TRX</option>
                          <option>BTC</option>
                        </select> */}
                      <Select
                        placeholder="Select option"
                        value={coinSelected}
                        className="select-option-s"
                        options={coinOption}
                        onChange={handleCoinChange}
                        getOptionLabel={(e) => (
                          <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                            {e.icon}
                            <span style={{ marginLeft: '5px' }}>{e.text}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>


                  <div className="withdrawl-amount">
                    <div className="tooltip-sec">
                      <p>Your Balance is: {(Number(coinSelected?.total_coin) > 0 ? Number(coinSelected?.total_coin) : 0)}</p>
                      <span>Amount</span>
                      <span className="amount-right-sec">
                        <Tooltip placement="top" title="The amount of the chosen currency that can be withdrawn or transferred out without borrowing, excluding the assets in use. The transferrable amount may be lower than the available balance as transfers-out from an account with borrowed assets will increase the risk of liquidation" arrow><p style={{ color: "#a4a4a4" }}>Transferrable</p></Tooltip>
                      </span>
                      <span><p>{coinSelected?.value?.toUpperCase()}</p></span>
                    </div>
                    <div className="withdraw-sec">
                      <div className="input-sec">
                        <input type="number" placeholder="Enter transfer amount.." onChange={(e) => { setAmount(e.target.value) }} value={amount} />
                      </div>
                      <div className="link1"><span><Link to="">{coinSelected?.value?.toUpperCase()}</Link></span></div>
                      <div><span>|</span></div>
                      <div className="link2 "><span><Button onClick={() => { setAmount(coinSelected?.total_coin) }} className="max btn-success">MAX</Button></span></div>
                    </div>
                  </div>

                  {/* <div className="auto-bor">
                    <span><Toggle /></span>
                    <span><h5>Auto Borrow</h5></span>
                  </div> */}
                  <Button className="confirm-btn" variant="success" onClick={SubmitTransferFund} disabled={btnDisable}>

                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Confirm Transfer ' : ''}
                  </Button>
                </form>

              </div>
            </div>
            <div className="right-sec">
              <div className="main-right">
                <h5>Related Questions</h5>
                <p>Have you had problems with your Deposit? Please click to view Deposit related questions.</p>
                <Button variant="link">Related Questions <KeyboardArrowRight /></Button>
              </div>
              {/* <div className="main-right2">
                <p>$ 10,000.00 daily withdrawal limit remaining.</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Transfer;
