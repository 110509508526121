import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Form as form, Table } from "react-bootstrap";
import { ContentCopy, NaturePeople } from "@mui/icons-material";
import {
  Password,
  MobileFriendly,
  AlternateEmail,
  Badge,
  Person,
  PermIdentity
} from "@mui/icons-material";

import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import toasted from "../../config/toast";
import { toast } from "react-toastify";
import PaymentDetail from "./PaymentDetail";
import { Link, useLocation } from "react-router-dom";
import Authentication from "./Authentication";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ClipLoader } from 'react-spinners'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// function that creates dark and light mode with toggle switcher

const SwitchToggle = () => {
  const [mode, setmode] = React.useState<"light" | "dark">("dark");

  const handleToggle = () => {
    setmode(mode === "light" ? "dark" : "light");
    // You can add more code here to change the theme of your application
    // console.log(mode);
  };

  return (
    <div>
      {/* {mode === "dark" ? "light" : mode === "light" ? "dark" : undefined} */}
      <label className="switch">
        <input
          type="checkbox"
          checked={
            mode === "light" ? true : mode === "dark" ? false : undefined
          }
          onChange={handleToggle}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

// Tabs section

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tabpanel-inner">
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {

  const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '' });
  const [updateUserData, setUpdateUserData] = useState('');
  const [timer, settimer] = useState('00:00');
  const [otp, setOTP] = useState();
  const [callFuntion, setcallFuntion] = useState(0);


  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userKycInfo, setUserKycInfo] = useState({ first_name: '', last_name: '' });
  const [btnDisable, setbtnDisable] = useState(false);
  const [PassbtnDisable, setPassbtnDisable] = useState(false);




  const location = useLocation()

  const { state } = location

  useEffect(() => {
    (async () => {
      const userData = await _fetch(`${api_url}user/userInfo/`, 'GET');
      setUserInfo(userData?.data?.data);
      const kycData = await _fetch(`${api_url}kyc/userKycInfo/`, "GET", "", {});
      setUserKycInfo(kycData?.data?.data);
    })();
    if (state?.active === true) {
      setValue(3)
    }
  }, [callFuntion]);


  const handleCopy = (data: any) => {
    if (data) {
      navigator.clipboard.writeText(data);
      toasted.success("Username copied successfully");
    }
  };

  async function createOTP(val: any) {
    if (val === 'email') {
      var data: any = `&sendTo=${updateUserData}&check=email`;
    }
    else {
      var data: any = `&sendTo=${updateUserData}&check=phone`;
    }

    var d = await _fetch(`${api_url}auth/generateAuthOTP?email_type=Update Profile${data}`, 'GET');
    if (d?.status === 'success') {
      toasted.success(d?.message);

      if (timer === "00.00") {
        var seconds: any = '180';
        var intervalId = window.setInterval(() => {
          seconds--;

          let minutes: any = Math.floor(seconds / 60);
          let exterseconds: any = seconds % 60;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          exterseconds = exterseconds < 10 ? "0" + exterseconds : exterseconds;
          var leftData = minutes + ':' + exterseconds;

          settimer(leftData);

          if (seconds == 0) {
            stop();
          }

        }, 1000);
      }
      let stop = () => clearInterval(intervalId);
    }
    else {
      localStorage.clear();
      toast.error(d?.message);
    }
  }


  // Submit button of updating the user profile

  const updateUserProfile = async (profileType: any) => {
    setbtnDisable(true)
    var data = {
      "type": profileType,
      "update": updateUserData,
      "otp": otp
    }

    var d = await _fetch(`${api_url}user/updateProfile`, 'POST', data);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      onCloseModal();
      setcallFuntion(callFuntion === 0 ? 1 : 0);
      setbtnDisable(false)
    }
    else {
      toasted.error(d?.message);
      setbtnDisable(false)
    }
  }



  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Submit function of password

  const SubmitPasswordData = async (e: any) => {
    setPassbtnDisable(true)
    e.preventDefault();
    const loginData = {
      "current_password": oldPassword,
      "new_password": newPassword,
      "confirm_new_password": confirmPassword
    }

    var d = await _fetch(`${api_url}user/changePassword/`, 'POST', loginData);
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setOpen(false)
      setPassbtnDisable(false)
    }
    else {
      toasted.error(d?.message);
      setPassbtnDisable(false)

    }
  }


  async function getphone2faOtp() {
    var d = await _fetch(`${api_url}auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
    }
  }



  // states for modal

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  // change email modal

  const [openEmail, setOpenEmail] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const onOpenEmailModal = () => {
    setOpenEmail(true);
  }

  const onOPenMobileModal = () => {
    setOpenMobile(true);
  }

  // states for tab2 modal
  const [openModal, setOpenModal] = useState(false);

  const onOpenModalTab2 = () => {
    setOpenModal(true);
  };

  // states for bank account modal
  const [openModalBank, setOpenModalBank] = useState(false);

  const onOpenModalBank = () => {
    setOpenModalBank(true);
  };
  // states for UPI modal
  const [openModalUpi, setOpenModalUpi] = useState(false);

  const onOpenModalUpi = () => {
    setOpenModalUpi(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
    setOpenModalBank(false);
    setOpenModalUpi(false);
    setOpenEmail(false);
    setOpenMobile(false);
  };
  // counter for modal2 for OTP
  const OtpCounter: React.FC = () => {
    const [count, setCount] = useState(60);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      if (count === 0) {
        clearInterval(intervalId);
      }

      return () => {
        clearInterval(intervalId);
      };
    }, [count]);

    return (
      <div style={{ display: "inline-flex" }}>
        <h1 style={{ fontSize: "18px" }}>{count}</h1>
      </div>
    );
  };

  //

  return (
    <div className="main-sec1">

      <div className="inner-sec1">
        <div style={{ width: "100%" }}>
          <div className="header-fix">
            <Tabs
              value={value}
              onChange={handleChange}>
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Security" {...a11yProps(1)} />
              <Tab label="API Keys" {...a11yProps(2)} />
              <Tab label="Payment" {...a11yProps(3)} />
            </Tabs>
          </div>

          <TabPanel value={value} index={0}>

            <div className="__profile-sec">
              <div className="head">
                <h2> General Settings</h2>
              </div>

              <div className="__profile-data">
                <div className="list-items">
                  <div className="list-items-inner">
                    <div className="list-icons">
                      <Person className="icon_items" />
                    </div>

                    <div className="list-title">
                      <div className="title">
                        <p>Username</p>
                      </div>
                    </div>
                    <div className="data"> {userInfo?.username} <ContentCopy style={{ cursor: "pointer" }} onClick={() => handleCopy(userInfo?.username)} /></div>
                  </div>

                  <div className="list-items-inner">
                    <div className="list-icons">
                      <Badge className="icon_items" />
                    </div>
                    <div className="list-title">
                      <div className="title">
                        <p>Name</p>
                      </div>

                    </div>
                    <div className="data"> {userKycInfo?.first_name}  {userKycInfo?.last_name}</div>
                  </div>

                  <div className="list-items-inner">
                    <div className="list-icons">
                      <AlternateEmail className="icon_items" />
                    </div>
                    <div className="list-title">
                      <div className="title">
                        <p>Email</p>
                      </div>
                    </div>
                    <div className="data">{userInfo?.email}
                      {userInfo?.email === "" &&
                        <Button style={{ marginLeft: '4px' }} variant="success" onClick={onOpenEmailModal}>
                          Modify
                        </Button>
                      }
                    </div>
                  </div>

                  <div className="list-items-inner">
                    <div className="list-icons">
                      <MobileFriendly className="icon_items" />
                    </div>
                    <div className="list-title">
                      <div className="title">
                        <p>Mobile</p>
                      </div>

                    </div>
                    <div className="data">{userInfo?.phone}
                      {userInfo?.phone === "" && <Button variant="success" className="change-pwd" onClick={onOPenMobileModal}>Edit</Button>}
                    </div>
                  </div>

                  <div className="list-items-inner">
                    <div className="list-icons">
                      <Password className="icon_items" />
                    </div>
                    <div className="list-title">
                      <div className="title">
                        <p>Change Password</p>
                      </div>

                    </div>
                    <div className="data">
                      <Link to='/dashboard/change-password' className="btn-success change-pwd"> Edit</Link>
                    </div>
                  </div>

                  <div className="list-items-inner">
                    <div className="list-icons">
                      <NaturePeople className="icon_items" />
                    </div>
                    <div className="list-title">
                      <div className="title">
                        <p>Activity logs</p>
                      </div>

                    </div>
                    <div className="data">
                      <Link to='/dashboard/activitylogs' className="btn-success change-pwd"> Activity</Link>
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </TabPanel>


          <TabPanel value={value} index={1}>
            <div className="__profile-sec">
              <div className="head">
                <h2> Two-Factor Authentication (2FA)</h2>
              </div>

              <div className="__profile-data">
                <Authentication />
              </div>
            </div>
          </TabPanel>


          <TabPanel value={value} index={2}>

            <div className="__profile-sec">
              <div className="head">
                <h2> API Access:Disabled</h2>
                <SwitchToggle />
              </div>

              <div className="__profile-data">
              <div className="tab-body">
                <div className="sec1">
                  <div className="txt-sec">
                    <ol>
                      <li>Each account can create up to 30 API Keys.</li>
                      <li>
                        Do not disclose your API key to anyone to avoid asset
                        losses. It is recommended to bind IPs for API keys to
                        increase your account security.
                      </li>
                      <li>
                        Be aware that your API key may be disclosed if you
                        authorize it to a third-party platform.
                      </li>
                      <li>
                        Please see the API Documentation for more information on
                        how to use your API keys.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="table-data-sec">
                <div className="table-data">
                  <Table style={{ marginBottom: "0px" }}>
                    <thead>
                      <tr>
                        <th>API Key</th>
                        <th>Created On</th>
                        <th>Last Accessed</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                  </Table>
                  <div className="demo-data">
                    <span>
                      Enable API access on your account to generate keys.
                    </span>
                    <br />
                    <Button variant="link">+ Enable API access</Button>
                  </div>
                </div>
              </div>
              </div>

            </div>

          </TabPanel>


          <TabPanel value={value} index={3}>
            <PaymentDetail />
          </TabPanel>
        </div>
        {/* </div> */}
      </div>

      <>
        {/* tab 1 Modal */}
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h2>Change Password</h2>
            <div className="form">
              <form method="post">
                <div className="input-1">
                  <label>Current Password</label>
                  <input type="password" placeholder="Current Password" onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} />
                </div>
                <div className="input-1">
                  <label>New Password</label>
                  <input type="password" placeholder="New Password" onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword} />
                </div>
                <div className="input-1">
                  <label>Confirm New Password</label>
                  <input type="password" placeholder="Confirm New Password" onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={SubmitPasswordData} disabled={PassbtnDisable}>

                    {PassbtnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {PassbtnDisable === false ? 'Change Password ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* Change Email Modal */}

        <Modal
          open={openEmail}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h5>Edit Your Email</h5>
            <div className="form">
              <form>
                <div className="input-1">
                  <label>Email addresss</label>
                  <div className="inner-input">
                    <input type="email" placeholder="Enter email" onChange={(e) => { setUpdateUserData(e.target.value) }} value={updateUserData} />
                    <button type="button" className="form-btn otpfield" onClick={() => { createOTP('email') }}>
                      Send Code
                    </button></div>
                </div>
                <div className="input-1">
                  <label>OTP</label>
                  <input type="text" placeholder="OTP" onChange={(e: any) => { setOTP(e.target.value) }} value={otp} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={() => { updateUserProfile('email') }} disabled={btnDisable}>
                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Submit ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* change mobile number Modal */}


        <Modal
          open={openMobile}
          onClose={onCloseModal}
          classNames={{
            modal: "New_modal",
          }}
          center
        >
          <div className="main-sec-modal">
            <h5>Edit Your Mobile Number</h5>
            <div className="form">
              <form>
                <div className="input-1">
                  <label>Mobile Number</label>
                  <div className="inner-input">
                    <PhoneInput
                      inputStyle={{ paddingLeft: "50px" }}
                      inputClass={"inputt-ph"}
                      containerStyle={{}}
                      searchClass="search-class"
                      disableSearchIcon={false}
                      enableTerritories
                      countryCodeEditable={true}
                      placeholder='Phone Number'
                      buttonStyle={{ width: '47px' }}
                      dropdownStyle={{ height: '180px' }}
                      country={"in"}
                      enableSearch={true}
                      onChange={(e) => { setUpdateUserData(e) }}
                    // onChange={(e) => { setUpdateUserData(e.target.value) }} value={updateUserData}
                    />
                    <button type="button" className="form-btn otpfield" onClick={() => { createOTP('phone') }}>
                      Send Code
                    </button></div>
                </div>
                <div className="input-1">
                  <label>OTP</label>
                  <input type="text" placeholder="OTP" onChange={(e: any) => { setOTP(e.target.value) }} value={otp} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="button" className="form-btn" onClick={() => { updateUserProfile('phone') }} disabled={btnDisable}>
                    {btnDisable === true &&
                      <div className="color-ring">
                        <ClipLoader color="#36d7b7" />

                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Submit ' : ''}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>



      </>
    </div>
  );
}
