import React, { useEffect, useState } from "react";
import { FiberManualRecord, HourglassBottom, VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import DashboardDataTabs from "./DashboardDataTabs";
import _fetch from "../../config/api";
import { api_url, referral_link, title } from "../../config/config";
import { Link } from "react-router-dom";
import __img_status from '../../Assets/img/verification-box.webp';
import toasted from "../../config/toast";


const Dashboard = () => {

  const [userInfo, setUserInfo] = useState({ email: '', phone: "", username: '', security_type: '', kyc_status: false });
  const [getSpotBalance, setGetSpotBalance] = useState({ main_account: 0, funding: 0, trading: 0 });

  async function getSpotTotalBalance() {
    let spotBalanceData = await _fetch(`${api_url}incomes/portfolio`, 'GET', {}, {});
    setGetSpotBalance({ main_account: spotBalanceData.data[1].total_amount, funding: spotBalanceData.data[2].total_amount, trading: spotBalanceData.data[0].total_amount });
  }

  

  const [showAssets, setShowAssets] = useState(false);
  const [totalReferal, setTotalReferal] = useState(0);


  const handleCopy = (data: any) => {
    if (data) {
      navigator.clipboard.writeText(data);
      toasted.success("Referral link copied successfully");
    }
  };

  useEffect(() => {
    (async () => {
      const userData = await _fetch(`${api_url}user/userInfo`, 'GET');
      setUserInfo(userData?.data?.data);

    })();
    add();
    getSpotTotalBalance();

  }, []);





  async function add() {
    var d = await _fetch(`${api_url}/user/totalReferral?fields=username,created_at`, 'GET', {}, {});
    if (d?.status === 'success') {
      setTotalReferal(d?.totalReferral);
    }
  }


  return (
    <>
      <div className="main-dashboard">
        <div className="main-balance ">
          <div className="row main-row">
            <div className="col-sm-12">
              <div className="__balanceCard">
                <h4>Hello #{userInfo?.username}</h4>
                <div className="__links">
                  <div className="__left-link">
                    <h6>Total Balance</h6>
                    <div className="__balance">
                      {showAssets ?
                        <h3>{(getSpotBalance?.main_account + getSpotBalance?.trading + getSpotBalance?.funding)?.toFixed(4)} USDT</h3>
                        :
                        <h3> <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord /></h3>
                      }

                      {showAssets ?
                        <VisibilityTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                        :
                        <VisibilityOffTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                      }
                    </div>
                  </div>

                  <div className="__right-link">
                    <ul>
                      <li>
                        <Link className="btn --deposit" to={'/dashboard/accounts'}>accounts overview </Link>
                      </li>

                    </ul>

                  </div>
                </div>
              </div>
            </div>

            {userInfo?.kyc_status == false && <div className="col-12">
              <div className="__kycInfo">
                <div className="__img-info">
                  <HourglassBottom />
                </div>

                <div className="__infomarion">
                  <h4>Complete Account Verification</h4>
                  <p>Resume your onboarding journey from where you left off so you can start using the {title} platform.</p>
                  <Link to="/dashboard/kyc" className="btn"> complete account verification</Link>
                </div>

              </div>
            </div>}

            <div className="col-12">
              <DashboardDataTabs />
            </div>

            <div className="col-12">
              <div className="__boost">
                <h4>Boost your <span>crypto portfolio</span> </h4>
                <p>Earn rewards with transparency and ease. <br /> No hidden fees. On {title}, you can now enjoy a steady stream of income.</p>

                <div className="link_add">
                  <input type="text" className="link_input" value={referral_link + userInfo?.username} readOnly />

                  <button type="button" className="btn copy_btn" onClick={() => handleCopy(referral_link + userInfo?.username)}  > Copy</button>
                </div>

              </div>
            </div>


          </div>

        </div>
      </div>
    </>
  );
};

export default Dashboard;
