import React from 'react'
import Button from 'react-bootstrap/Button';
import top_img from '../../Assets/Image/top_img.png';
import ivcard from '../../Assets/Image/ivcard.png';
import services_advan from '../../Assets/Image/services_advan.svg';
import Card from 'react-bootstrap/Card';
import { shortTitle } from '../../config/config';
const IvServices = () => {
    return (
        <div className="iv_services_page only-margin">
            <div className=" ivservices_bg">
                <div className="coustom_container">
                    <div className="row">
                        <div className="col">
                            <h1>Institutional Services</h1>
                            <h6>{shortTitle} offers bespoke services to institutions and professional traders</h6>
                            <div className='btn_padding'> <Button className='main-button' variant="success">Contact Us</Button></div>
                        </div>
                        <div className="col">
                            <div className="img_baner">
                                <img src={top_img} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="coustom_container services_scope pt-5">
                <h5 className='heading'>Scope of Services</h5>

                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                        <Card>

                            <Card.Img variant="top" src={ivcard} />

                            <Card.Body>
                                <Card.Title>Institution Account KYC</Card.Title>
                                <Card.Text>{shortTitle} offers institution verification for companies</Card.Text>

                            </Card.Body>

                        </Card>
                    </div>

                </div>

            </div>



            <div className="coustom_container sta_client">
                <div className="text_btn">
                    <h5>Become an institutional client of {shortTitle}</h5>

                    <div> <Button className='main-button' variant="success">Contact Us</Button></div>
                </div>
            </div>

        </div>
    )
}

export default IvServices