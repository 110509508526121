import React from "react";
import { Button } from "react-bootstrap";
import btc from "../../Assets/Image/btc_img.png";
import { CurrencyBitcoin } from "@mui/icons-material";
import { title } from "../../config/config";

const BuyBitcoin = () => {
  return (
    <>
      <div className="bitcoin-main">
        <div className="banner-sec">
          <div className="coustom_container">
            <div className="banner-inner">
              <div className="left-sec">
                <h1>How To Buy Bitcoin Guide At</h1>
                <h3>Update: 2023-01-29 19:30:00 On {title} Market</h3>
              </div>
              {/* <div className="right-sec">
                <img src={btc} alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="body-sec2">
          <div className="body-inner">
            <div className="coustom_container body-inner2">
              <div className="left-sec2">
                <p>
                  Want To Buy Crypto But Not Sure How? No Worries! {title}
                  Offers Many Options. You Can Easily Buy Bitcoin (BTC) With The
                  Lowest Fees And Highest Security Anywhere {title} Is
                  Available. Learn How To Buy Bitcoin (BTC) With The {title} App Now.
                </p>
                <div className="list-sec ">
                  <ol>
                    <li className="bg-clr">
                      <span>

                        Create A Free Account On The {title} Website Or The
                        App.
                      </span>
                      <br />
                      <span>
                        Your {title} Account Acts As A Gateway Into Buying
                        Crypto. But Before You Can Buy Bitcoin (BTC), You’ll
                        Need To Open An Account And Verify Your Identification.
                      </span>
                      <ul className="list2">
                        <li>Register Via The {title} App</li>
                        <li>Register Via The Website Using Your Email App</li>
                        <li>
                          Register Via The Website Using Your Mobail Number App
                        </li>
                      </ul>
                    </li>
                    <li className="bg-clr">
                      <h2>
                        Choose How You Want To Buy The Bitcoin (BTC) Asset.
                      </h2>
                      <br />
                      <span>
                        Click On The “Buy Crypto” Link On The Top Left Of The
                        {title} Website Navigation, Which Will Show The
                        Available Options In Your Country. <br />
                      </span>
                      <span>
                        For Better Coin Compatibility, You Consider Buying A
                        Stablecoin Like USDT Or BUSD First, And Then Use That
                        Coin To Buy Bitcoin.
                      </span>
                      <ul className="list2">
                        <li>
                          <strong>A. Credit Card And Debit Card</strong>
                          If You Are A New User, This Is The Easiest Option To
                          Purchase Bitcoin (BTC). {title} Supports Both
                          Visa And MasterCard.
                        </li>
                        <li>
                          <strong>B. Bank Deposit</strong>
                          Transfer Fiat Currency From Your Bank Account To {title}, And Then Use The Amount To Buy Bitcoin
                          (BTC).
                        </li>
                        <li>
                          <strong>C. P2P Trading</strong>
                          Buy Bitcoin (BTC) Directly From Other Users With {title}’s Peer-To-Peer Service.
                        </li>
                        <li>
                          <strong>D. Third Party Payment</strong>
                          There Are Multiple Options For Third-Party Payment
                          Channels. Please, Visit The {title} FAQ To Check
                          Which Ones Are Available In Your Region.
                        </li>
                      </ul>
                    </li>
                    <li className="bg-clr">
                      <h2>
                        {" "}
                        Create A Free Account On The {title} Website Or The
                        App.
                      </h2>
                      <br />
                      <span>
                        You Have 1 Minute To Confirm Your Order At The Current
                        Price. After 1 Minute, Your Order Will Be Recalculated
                        Based On The Current Market Price. You Can Click Refresh
                        To See The New Order Amount.
                      </span>
                    </li>
                    <li className="bg-clr">
                      <h2>
                        Store Or Use Your Bitcoin (Bitcoin) In {title}.
                      </h2>
                      <br />
                      <span>
                        Now That You Bought Your Crypto, You Can Store It In
                        Your Personal Crypto Wallet Or Simply Hold It In Your
                        {title} Account. You Can Also Trade For Other
                        Crypto Or Stake It On {title} Earn For Passive
                        Income. If You Would Like To Trade Your Bitcoin (BTC) To
                        A Decentralized Exchange You May Want To Check Trust
                        Wallet Which Supports Millions Of Assets And
                        Blockchains.
                      </span>
                      <br />
                      <span>
                        Want To Keep Tabs On Coin Prices? Visit Our Coin Price
                        Directory To Add To Bookmark.
                      </span>
                    </li>
                  </ol>
                </div>
                <div className="left-sec2 ">
                  <h2>{title}: Where The World Trades Bitcoin (BTC)</h2>
                  <div className="cards-sec">
                    <div className="cards-items">
                      <CurrencyBitcoin className="icon-mui" />
                      <h4>Fast</h4>
                      <p>
                        {title}’s World-Class Matching Engine Supports Up
                        To 1,400,000 Orders Per Second. This Ensures That Your
                        Crypto Trading Experience Is Quick And Reliable.
                      </p>
                    </div>
                    <div className="cards-items">
                      <CurrencyBitcoin className="icon-mui" />
                      <h4>Simple</h4>
                      <p>
                        Trading Crypto On {title} Is Easy And Intuitive.
                        You Only Need A Few Steps To Buy Bitcoin (BTC).
                      </p>
                    </div>
                    <div className="cards-items">
                      <CurrencyBitcoin className="icon-mui" />
                      <h4>Secure & Trusted</h4>
                      <p>
                        Millions Of Global Crypto Users Trust Us. Our Multi-Tier
                        & Multi-Cluster System Architecture And SAFU (Secure
                        Assets Fund For Users) Insurance Fund Protect Your
                        Account.
                      </p>
                    </div>
                    <div className="cards-items">
                      <CurrencyBitcoin className="icon-mui" />
                      <h4>Liquid</h4>
                      <p>
                        {title} Is The World’s Most Liquid Cryptocurrency
                        Exchange, With The Biggest Volumes Across Multiple
                        Crypto Pairs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="left-sec3">
                  <h2>Start Buying Bitcoin</h2>
                  <p>
                    If You’re Ready To Take The Next Step And Want To Buy Some
                    Bitcoin, Take The Next Step With {title}!
                  </p>
                  <Button variant="success">Start Now</Button>
                  <p>
                    Cryptocurrency Prices Are Subject To High Market Risk And
                    Price Volatility. You Should Only Invest In Products That
                    You Are Familiar With And Where You Understand The
                    Associated Risks. The Content Expressed On This Page Is Not
                    Intended To Be And Shall Not Be Construed As An Endorsement
                    By {title} About The Reliability Or Accuracy Of Such
                    Content. You Should Carefully Consider Your Investment
                    Experience, Financial Situation, Investment Objectives And
                    Risk Tolerance And Consult An Independent Financial Adviser
                    Prior To Making Any Investment. This Material Should Not Be
                    Construed As Financial Advice. Past Performance Is Not A
                    Reliable Indicator Of Future Performance. The Value Of Your
                    Investment Can Go Down As Well As Up, And You May Not Get
                    Back The Amount You Invested. You Are Solely Responsible For
                    Your Investment Decisions. {title} Is Not Responsible
                    For Any Losses You May Incur. For More Information, Please
                    Refer To Our Terms Of Use And Risk Warning.
                  </p>

                  <p>
                    Please Also Note That Data Relating To The Above-Mentioned
                    Cryptocurrency Presented Here (Such As Its Current Live
                    Price) Are Based On Third Party Sources. They Are Presented
                    To You On An “As Is” Basis And For Informational Purposes
                    Only, Without Representation Or Warranty Of Any Kind. Links
                    Provided To Third-Party Sites Are Also Not Under {title}’s Control. {title} Is Not Responsible For The
                    Reliability And Accuracy Of Such Third-Party Sites And Their
                    Contents.
                  </p>
                </div>
              </div>
              <div className="right-sec2">
                <div className="right-inner">
                  <div className="img-sec">
                    <img src={btc} alt="" width="30px" height="30px" />
                    <h2>Bitcoin</h2>
                  </div>
                  <div className="data-sec">
                    <span>
                      <h3>$ 23,576.32 USD</h3>
                    </span>
                    <span>+2.64%</span>
                    <span>(24H)</span>
                  </div>
                  <div className="bg-sec">
                    <p>
                      The Live Price Of Bitcoin Is $ 23,576.32 Per (BTC / USD)
                      Today With A Current Market Cap Of $ 454.46B USD. 24-Hour
                      Trading Volume Is $ 21.59B USD. Bitcoin To USD Price Is
                      Updated In Real-Time. Bitcoin Is +2.64% In The Last 24
                      Hours. It Has A Circulating Supply Of 19.28M.
                    </p>
                  </div>
                  <Button className="icon-2" variant="success">
                    Buy Bitcoin
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBitcoin;
