import React from 'react'
import { Button } from 'react-bootstrap'
import { shortTitle } from '../../config/config'

const ApiTradingNew = () => {
  return (
    <>
    <div className="main-api-sec">
            <div className='coustom_container'>
        <div className="api-inner-sec">
            <div className="left-sec-api">
                <h2>{shortTitle} Public API (V2)</h2>
                <div>
                    <span>Download OpenAPI specification: </span>
                    <span><Button variant='link'>Download</Button></span>
                </div>
                <p>Welcome to {shortTitle} Public API.</p>

                <div className="basic-info">
                    <h3>Basic Information</h3>
                    <ul>
                        <li>The base address is https://api.latoken.com/v2</li>
                        <li>All methods are returning JSON object</li>
                        <li>If you want to generate an API key for your account, please go to https://exchange.latoken.com/account/apikeys</li>
                        <li>You can refer to this guide for further information: https://latoken.zendesk.com/hc/en-us/articles/360012042260-How-to-generate-an-API-key-</li>
                    </ul>
                </div>

                <div className='socket-sec'>
                    <h3>WebSocket, P2P</h3>
                    <ul>
                        <li>Latoken WebSocket API documentation is located at https://api.latoken.com/doc/ws/</li>
                        <li>Latoken P2P API documentation is located at https://api.latoken.com/doc/p2p/</li>
                    </ul>
                </div>
            </div>
            <div className="right-sec-api">
                <div className='input-sec'>
                    <div className='input-title'>
                        <div className='method'>
                        <span className='name'>Get</span>
                        <span>/ v2 / auth / account</span>
                        </div>
                    <select name="api" id="api">
                        <option value="api1" disabled>Select Api</option>
                        <option value="api1">https://api.latoken.com/v2/auth/account</option>
                    </select>
                    </div>

                    <div className='data-sec'>
                        <span>No data Available</span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ApiTradingNew    