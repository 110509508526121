
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import btc_img from '../../Assets/Image/btc_img.png';
import { Link } from "react-router-dom";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import Markets from '../FooterPages/Markets';

const Sec2tabpart = () => {
  return (
    <>
      <div className="main-tab-sec d-none">
        <div className="coustom_container pt-5 tab">

          <Tabs
            defaultActiveKey="recommended"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="recommended" title="Recommended">
              <div className='row tab_bar'>
                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>

                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="trending" title="Trending">
              <div className='row tab_bar'>
                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>

                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>



            <Tab eventKey="gainers" title="24H Gainers">
              <div className='row tab_bar'>
                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>

                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="topfutures" title="Top Futures">
              <div className='row tab_bar'>
                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>

                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="newlistings" title="New Listings">
              <div className='row tab_bar'>
                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>

                <div className='col-md-6   mt-3 '>
                  <div className='tab_body'>
                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>



                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>


                    <div className='tabs_link'>
                      <Link to="#">
                        <div className='tab_flex'>

                          <div className='tab_imgtext'>
                            <img src={btc_img} alt=''></img>
                            <h6>BTC/USDT</h6>
                          </div>


                          <div>
                            <h4>$24,550.52 <small>/  <span> -0.77%</span></small></h4>
                          </div>

                          <div className='sparkline-area'>
                            <Sparklines data={[5, 10, 5, 20]}>
                              <SparklinesLine color="green" />
                            </Sparklines>
                          </div>


                        </div>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>

          {/* <div className='arrow_icon'> - </div> */}
        </div>
      </div>

      <div className='markets-table'>
        <Markets />
      </div>
    </>

  )
}

export default Sec2tabpart
