import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const LiveBalance = (props: any) => {
    const { id } = useParams();
    const [sellData, setSellData] = useState([]);
    const [buyData, setBuyData] = useState([]);

    useEffect(() => {
        const nowStream = `${props.symbol.toLowerCase()}usdt@depth20`;
        const streams = ['@ticker', '@depth20', '@trade'];
        const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            try {
                if ((json)) {
                    if (json.stream === nowStream) {
                        if (json) {
                            var d = json.data;
                            if (d.asks) {
                                // console.log
                                setSellData(d.asks.reverse());
                            }

                            if (d.bids) {
                                setBuyData(d.bids);

                            }
                        }
                    }
                }
            } catch (err) {
                // console.log(err);
            }
        };

    }, [])

    const [buytab, setbuyTab] = useState(0);
    const [fixedValue, setfixedValue] = useState(3);


    return (
        <div className='main-order-book'>

            <div className='__order-header'>
                <ul className='list'>
                    <li className={buytab == 0 ? 'active' : undefined} onClick={() => setbuyTab(0)}>
                        <span> </span>
                        <span> </span>
                    </li>

                    <li className={buytab == 1 ? 'active' : undefined} onClick={() => setbuyTab(1)}>
                        <span> </span>
                        <span> </span>
                    </li>

                    <li className={buytab == 2 ? 'active' : undefined} onClick={() => setbuyTab(2)}>
                        <span> </span>
                        <span> </span>
                    </li>
                </ul>


                <div className='__dropdwon'>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {fixedValue == 3 && 0.001}
                            {fixedValue == 4 && 0.0001}
                            {fixedValue == 5 && 0.00001}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <div className='__item' onClick={() => { setfixedValue(3) }}> 0.001</div>
                            <div className='__item' onClick={() => { setfixedValue(4) }}> 0.0001</div>
                            <div className='__item' onClick={() => { setfixedValue(5) }}> 0.00001</div>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

            </div>

            {buytab == 0 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>
                        <div className='scroll-y'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {sellData.map((item, index) => (
                                        <tr data-index={index} style={{
                                            width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                            backgroundImage: `linear-gradient( to right, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                        }}>
                                            <td className='pl'>{parseFloat(item[0]).toFixed(fixedValue)}</td>
                                            <td className='al'>{parseFloat(item[1]).toFixed(fixedValue)}</td>
                                            <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(fixedValue)}</td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>

                        <div className='scroll-y1'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='numbers'>{Number(buyData?.[0]?.[0])?.toFixed(fixedValue)} </th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.[1])?.toFixed(fixedValue)}</th>
                                        <th className='total'>More</th>
                                    </tr>


                                </thead>
                                <tbody>


                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} style={{
                                                    width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                                    backgroundImage: `linear-gradient( to right, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                                }}>
                                                    <td className="status-dot-cell-green pl"> {parseFloat(item[0]).toFixed(fixedValue)} </td>
                                                    <td className='al'>{parseFloat(item[1]).toFixed(fixedValue)}</td>
                                                    <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(fixedValue)}</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {buytab == 1 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>

                        <div className='scroll-y1 scroll-fully-public'>
                            <table>
                                <thead>
                                    {/* <tr>
                                        <th className='numbers'>{Number(buyData?.[0]?.[0])?.toFixed(fixedValue)} </th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.[1])?.toFixed(fixedValue)}</th>
                                        <th className='total'>More</th>
                                    </tr> */}
                                    <tr>

                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} style={{
                                                    width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                                    backgroundImage: `linear-gradient( to right, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--buy) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                                }}>
                                                    <td className="status-dot-cell-green pl"> {parseFloat(item[0]).toFixed(fixedValue)} </td>
                                                    <td className='al'>{parseFloat(item[1]).toFixed(fixedValue)}</td>
                                                    <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(fixedValue)}</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {buytab == 2 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>
                        <div className='scroll-y scroll-fully-public'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {sellData.map((item, index) => (
                                        <tr data-index={index} style={{
                                            width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`,
                                            backgroundImage: `linear-gradient( to right, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`}, var(--sell) ${(Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%`} ,  Transparent 0%, Transparent 0% )`,
                                        }}>
                                            <td className='pl'>{parseFloat(item[0]).toFixed(fixedValue)}</td>
                                            <td className='al'>{parseFloat(item[1]).toFixed(fixedValue)}</td>
                                            <td className='tl'>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(fixedValue)}</td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default LiveBalance