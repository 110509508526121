// import React from 'react';
import React, { useEffect, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Background from '../../Assets/Image/kycBG.svg'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import _fetch from "../../config/api";
import { api_url, kycImage_path } from "../../config/config";

import dateFormat from "dateformat";

function KycDetails() {

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    const [open1, setOpen1] = useState(false);
    const onOpenModal1 = () => setOpen1(true);
    const onCloseModal1 = () => setOpen1(false);
    const [userKycInfo, setUserKycInfo] = useState({
        city: "", comment: "", country: "", created_at: "", dob: "", document_back_image: "", document_front_image: "", document_number: "", document_type: "", first_name: "", kyc_status: "", last_name: "", pan_image: "", pan_number: "", pin_code: "", selfie_image: "", state: ""
    });


    useEffect(() => {
        (async () => {
            const kycData = await _fetch(`${api_url}kyc/userKycInfo/`, "GET", "", {});
            setUserKycInfo(kycData?.data?.data);
        })();
    }, [])
    return (
        <>
            <section className='kyc-details-main'>
            <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12'>
                            <div className='kyc-detail'>
                                <div className='kyc-heading_d'>
                                    <h4> KYC Detail</h4>
                                </div>
                                <div className='kyc-inner-main'>
                                    <li>
                                        <span className='title'>Name</span>
                                        <span className='detail'>{userKycInfo.first_name + ' ' + userKycInfo.last_name}</span>
                                    </li>

                                    <li onClick={onOpenModal} className="list-1">
                                        <span className='title'>Pan Detail</span>
                                        <div className='detail detail-1'>{userKycInfo.pan_number} <span><AddPhotoAlternateOutlinedIcon className="icon-open" /></span></div>
                                    </li>
                                    <Modal open={open} onClose={onCloseModal} center>
                                        <div className="modal-content">
                                            <div className="image">
                                                <img className="front-image" src={kycImage_path + userKycInfo.pan_image} />

                                            </div>
                                        </div>
                                    </Modal>

                                    <li onClick={onOpenModal1} className="list-1">
                                        <span className='title'>{userKycInfo.document_type} Detail</span>
                                        <div className='detail detail-1'>{userKycInfo.document_number} <span><AddPhotoAlternateOutlinedIcon className="icon-open" /></span></div>

                                    </li>
                                    <Modal open={open1} onClose={onCloseModal1} center>
                                        <div className="modal-content">
                                            <div className="image">
                                                <img className="front-image" src={kycImage_path + userKycInfo.document_front_image} />
                                                <img className="back-images" src={kycImage_path + userKycInfo.document_back_image} />
                                            </div>
                                        </div>
                                    </Modal>



                                    <li>
                                        <span className='title'>DOB </span>
                                        <span className='detail'>
                                            {dateFormat(userKycInfo.dob, "UTC:dd-mm-yyyy ")}
                                        </span>
                                    </li>

                                    {/* <li>
                                        <span className='title'>Address </span>
                                        <span className='detail'>House No- 104 , Abohar </span>
                                    </li> */}
                                    <li>
                                        <span className='title'>Pin Code </span>
                                        <span className='detail'>{userKycInfo.pin_code} </span>
                                    </li>

                                    <li>
                                        <span className='title'>State </span>
                                        <span className='detail'>{userKycInfo.state}</span>
                                    </li>

                                    <li>
                                        <span className='title'>Country</span>
                                        <span className='detail'>{userKycInfo.country}</span>
                                    </li>
                                    <p>
                                        Status : <span style={{ color: userKycInfo.kyc_status === 'Approved' ? '#95d395' : 'blue' }}> {userKycInfo.kyc_status} </span>

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
            </section>

        </>
    )
}

export default KycDetails
