import React, { useEffect, useState } from "react";
import { FileDownload, Menu, Person, Close, Leaderboard, RocketLaunch, RssFeed, EmojiEvents, Newspaper, ArrowDropDown, WbSunnyOutlined, NightsStayOutlined, LensBlurOutlined, DashboardCustomize } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api_url, logo, shortTitle, title } from "../config/config";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import _fetch from "../config/api";
import __img_qr from '../Assets/img/qr.png';
import { Accordion } from "react-bootstrap";
import toasted from "../config/toast";




const Header = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const checkAuth = localStorage.getItem('auth')




  const [theme, setTheme] = useState('dark');  /* Theme change using state  */
  const [RightSIdebar, setRightSIdebar] = useState(false);
  const onOpenRightSIdebar = () => setRightSIdebar(true);
  const onCloseRightSIdebar = () => setRightSIdebar(false);
  const [showAssets, setShowAssets] = useState(false);



  const togglerBar = () => {
    setRightSIdebar(!RightSIdebar)
  };



  useEffect(() => {

    if (checkAuth === "true") {
      (async () => {
        const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", "", {});
        if (data1?.data?.data) {

        } else {
          localStorage.clear();
          navigate("/login");
        }
      })();
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }, [location.pathname]);




  // const [getSpotBalance, setGetSpotBalance] = useState({ main_account: 0, funding: 0, trading: 0 });

  // async function getSpotTotalBalance() {
  //   let spotBalanceData = await _fetch(`${api_url}incomes/portfolio`, 'GET', {}, {});
  //   setGetSpotBalance({ main_account: spotBalanceData.data[1].total_amount, funding: spotBalanceData.data[2].total_amount, trading: spotBalanceData.data[0].total_amount });
  // }




  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      localStorage.setItem("mode", 'dark');
      localStorage.setItem("mode_chart", 'dark');

    } else {
      setTheme('light');
      localStorage.setItem("mode", 'light');
      localStorage.setItem("mode_chart", 'light');

    }
  };

  // modeTheme 
  let modeTheme = localStorage.getItem("mode");
  var themeModeSave = localStorage.getItem("mode");


  useEffect(() => {
    const body: HTMLElement | any = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);

    setTheme(`${themeModeSave}`)
    if (themeModeSave) {

    } else {
      localStorage.setItem("mode", 'dark');
      localStorage.setItem("mode_chart", 'dark');
    }
  }, [theme]);


  const logout = async () => {
    var subdata = await _fetch(`${api_url}auth/logout`, 'GET', {}, {});
    if (subdata.status === 'success') {
      toasted.success(subdata.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login')
    } else {
      toasted.error(subdata.message);
    }
  }


  interface NavItem {
    path: string;
    label: string;
    subNav?: SubNavItem[];
  }

  interface SubNavItem {
    path: string;
    label: string;
    pragraph: string;
    icon: React.ReactNode;
  }

  const navItems: NavItem[] = [
    { path: '/', label: 'Home' },
    { path: '/markets', label: 'Markets' },
    { path: '/referral-program', label: 'Referrals' },
    {
      path: "",
      label: 'Explore',
      subNav: [
        {
          path: '/ksn-launchpad',
          label: 'LeaderBoard',
          pragraph: 'Buy Crypto via visa or mastercard',
          icon: <Leaderboard />,
        },
        {
          path: '/launchbase',
          label: 'Launchbase',
          pragraph: 'Buy Crypto via visa or mastercard',
          icon: <RocketLaunch />,
        },
        {
          path: '/ksn-blog',
          label: `${shortTitle} Blog`,
          pragraph: 'Buy Crypto via visa or mastercard',
          icon: <RssFeed />,
        },
        {
          path: '/bonus',
          label: 'Reward Center',
          pragraph: 'Buy Crypto via visa or mastercard',
          icon: <EmojiEvents />,
        },
        {
          path: '/promotions',
          label: 'Promotions',
          pragraph: 'Buy Crypto via visa or mastercard',
          icon: <Newspaper />,
        }
      ],
    },
  ];



  return (
    <>

      <header className="header">
        <div className="HeaderLayout">

          <div className="__logo">
            <Link to="/" className="web-logo">
              <img src={logo} alt={title + ` logo`} />
            </Link>
          </div>

          <div className="__center-header">
            <ul className="nav">
              {navItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <Link to={item.path} className="nav-link">
                    {item.label} {item.subNav && <ArrowDropDown className="drop__arrow" />}
                  </Link>

                  {item.subNav && (
                    <div className="drophover">
                      <ul className="drop-nav">
                        {item.subNav.map((subItem, subIndex) => (
                          <Link to={subItem.path} className="drop-link" key={subIndex}>
                            <div className="__icon">
                              {subItem.icon}
                            </div>
                            <div className="__c">
                              <h5>{subItem.label}</h5>
                              <p>{subItem.pragraph}</p>

                            </div>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="__end-header">

            {checkAuth ?
              <div className="__links">
                <Link to="/dashboard" className="btn --q-btn-login"> Overview</Link>
              </div>
              :
              <div className="__links">
                <Link to="/login" className="btn --q-btn-login"> Login</Link>
                <Link to="/sign-up" className="btn --q-btn"> Sign Up </Link>
              </div>
            }



            <div className="__extra">
              {/* <div className="__download">
                <h4><FileDownload /></h4>

                <div className="dropdwon--">
                  <div className="qr-sec">
                    <img src={__img_qr} alt="" />
                    <p>Scan to download</p>
                  </div>

                  <div className="left">
                    <a href="#">Download App</a>
                  </div>
                </div>
              </div> */}

              <div className="__overview">
                <h4> <Link to="/dashboard" ><DashboardCustomize /> </Link></h4>
              </div>

              <div className="__theme">
                <h4 onClick={() => toggleTheme()}>  {modeTheme === 'light' ? (<WbSunnyOutlined className="sys-icon" />) : modeTheme === 'dark' ? (<NightsStayOutlined className="sys-icon" />) : modeTheme === 'dim' ? (<LensBlurOutlined className="sys-icon" />) : <NightsStayOutlined className="sys-icon" />}</h4>
              </div>

              {checkAuth ?
                <div className="__profile">
                  <h4><Link to="/dashboard/profile"> <Person />  </Link></h4>
                </div>
                : ""}

              <div className="__toggle">
                <h4 onClick={() => { togglerBar() }}>  {RightSIdebar ? <Close /> : <Menu />} </h4>

              </div>
            </div>
          </div>

        </div>
      </header>



      <Modal
        open={RightSIdebar}
        onClose={() => { onCloseRightSIdebar() }}

        classNames={{
          // modalContainer: "public-header-container",
          overlay: "public-header-overly",
          modal: "Header__sidebar__right",
          modalAnimationIn: "ani-start-header",
          modalAnimationOut: "ani-end-header",
        }}
        animationDuration={800} >


        <div className="__smallHeader">
          <div className="subheader-1">
            <p> welcome to {title}</p>
          </div>

          <div className="__ulList">
            <Accordion defaultActiveKey="0">
              {navItems.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>

                  {item.subNav ?
                    <Accordion.Header className={" fullLink"}>
                      <button className="nav-link w-auto border-0">
                        {item.label} {item.subNav && <ArrowDropDown className="drop__arrow" />}
                      </button>
                    </Accordion.Header>
                    :

                    <Accordion.Header className={" onlyLink"}>
                      <Link to={item.path} className="nav-link" onClick={() => togglerBar()}>
                        {item.label} {item.subNav && <ArrowDropDown className="drop__arrow" />}
                      </Link>
                    </Accordion.Header>

                  }



                  {item.subNav && (
                    <Accordion.Body>
                      <ul className="drop-nav">
                        {item.subNav.map((subItem, subIndex) => (
                          <Link to={subItem.path} className="drop-link" onClick={() => togglerBar()} key={subIndex}>
                            <div className="__icon">
                              {subItem.icon}
                            </div>
                            <div className="__c">
                              <h5>{subItem.label}</h5>
                              <p>{subItem.pragraph}</p>

                            </div>
                          </Link>
                        ))}
                      </ul>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              ))}
            </Accordion>
          </div>

          {checkAuth ?
            <div className="__loginstatus mt-5">
              <Link to="#" onClick={logout} className="btn bg-danger border-danger w-50 m-auto"> Logout </Link>
            </div>
            :
            <>
              <div className="__loginstatus mt-5" onClick={() => togglerBar()}>
                <Link to="/login" className="btn"> Log in </Link>
                <Link to="/sign-up" className="btn"> sign up </Link>
              </div>
            </>
          }

        </div>
      </Modal>
    </>
  );
};

export default Header;
