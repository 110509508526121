import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Form } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import morning_cup from '../../Assets/Image/morning_cup.png';
import { shortTitle, title } from '../../config/config';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import RedditIcon from '@mui/icons-material/Reddit';
import ForumIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import chart from '../../Assets/Image/chart.png';
import bitcoin from '../../Assets/Image/bitcoin.webp';
import coindesk from '../../Assets/Image/coindesk.webp';
import cointelegraph from '../../Assets/Image/cointelegraph.webp';
import week_highlight from '../../Assets/Image/week_highlight.png';
const MorningCup = () => {
    return (<>
        <div className='home-data only-margin'>
            <div className='coustom_container pt-5'>
                <div className='heading-sec'>
                    <div className='title'><a href="/sta-blog"><span><ArrowBackIosIcon /> </span>Home</a>

                    </div>
                    <div className='searchbar'>
                        <Form.Control type="text" placeholder="Search" />
                    </div>

                </div>
            </div>
        </div>

        <div className='coustom_container bitcoin-container'>
            <div className='row'>
                <div className='col-lg-8 col-md-8 col-sm-12 bitcoin-sec'>
                    <h1>☕️The Morning Cup: Bitcoin’s got NFTs</h1>
                    <div className='date-sec'>
                        May 24, 2023
                        <span><AccessTimeIcon className='clock-time' />5 min</span>
                    </div>
                    <div className='img-section'>
                        <a><img src={morning_cup}></img></a>
                    </div>
                    <div className='here'>
                        <p>😎gm. here's what's up:</p>
                        <ul>
                            <li>BTC ends week above $27K</li>
                            <li>Bitcoin’s got NFTs 🖼️ </li>
                            <li>AI has come to finance 🤖</li>
                            <li>HAPPY PIZZA DAYYYY 🍕🍕🍕</li>
                        </ul>
                    </div>
                    <hr />
                    <div className='market'>
                        <h3>📈📉 Market Watch!</h3>
                        <div className='market-img'>
                            <img src={chart}></img>
                        </div>
                        <div className='btc-ends'>
                            <p><strong>BTC ends the week on top of $27K as Bitcoin NFT phenomenon takes firm hold</strong></p>
                            <p>At the time of writing, the price of 1BTC stands at $27,382.78. Renewed developer interest in the Bitcoin network sparked by the recent ability to issue NFTs on the blockchain may be one thing keeping the price above $27K. Traders may also be awaiting the United States Bureau of Economic Analysis to release its report on GDP for the first quarter. And as the US debt limit fight goes on and the June 1 deadline for the US to pay its debts looms, sentiment is split and altogether cautious, whether that be cautious optimism or just plain cautiousness.</p>
                            <p>The top gainers for the last 24 hours on {title} are <a><u>RFD/USDT</u></a>, <a><u>BILLY/USDT</u></a>, <a><u>BEN/USDT</u></a>, <a><u>SIMPSON/USDT</u></a>, <a><u>ROCK/USDT</u></a> and <a><u>WSTREETBABY/USDT</u></a>.</p>
                            <hr />
                            <h3>
                                <strong>🗞️ So, what happened?</strong>
                            </h3>
                            <h2>💥The big story</h2>
                            <div><img src={bitcoin} /></div>
                        </div>
                        <div className='btc-ends'>
                            <p><strong>Bitcoin becomes 2nd most popular network for NFTs</strong></p>
                            <p>Well, well, well... how the tables turn! Okay maybe no tables have turned, but this is truly a remarkable and, for some, unexpected turn of events. Because of a mix of novelty and hype-inducement from leaders in the space, the Bitcoin network has become one of the top go-to places for issuing NFTs, after Ethereum of course.</p>
                            <p>In the short time since the Ordinals Protocol dropped in January 2023, an update that allows for the inscription of data into Satoshis (smallest unit of Bitcoin), the sales of Bitcoin NFTs has reached a staggering $167 million, outsizing and unseating the Solana network. One project that has been fueling this hype train (and kind of driving it) is Yuga Labs, the famed team behind the Bored Ape Yacht Club NFT collection. Yuga's auction of its Bitcoin-based <a><u>Twelvefold</u></a> collection fetched $16.5 million. All the hubbub around the Ordinals Protocol has also reinvigorated interest in development on the network.</p>
                            <p>We reported on this <a><u>last week</u></a> and it bears repeating that this phenomenon has not been without its controversy, with many decrying Ordinals as taking away from the Bitcoin thesis. And the data inscriptions on Bitcoin have proven quite weighty, causing network congestion and driving fees to multi-year highs. However, there is always another side to the story as, with higher fees, Bitcoin miners have enjoyed financial benefit. And it can't be understated how welcome this would be by miners after Ethereum's shift to proof of stake and the crackdown on mining operations in various parts of the world.</p>
                            <p>What do you think of the Ordinals protocol and the new NFT phenomenon on Bitcoin? Could this fuel the next Bitcoin bull run? Is this a passing fad? Do the seeming costs outweigh the benefits?</p>
                            <a><u>Coindesk</u></a>
                            <div className='coindesk-img'> <img src={coindesk}></img></div>
                            <p><strong>AI has come to finance</strong></p>
                            <p>We here at the virtual Morning Cup editorial desk have been up to our necks in news about artificial intelligence. For real. It’s actually kind of hard to breathe. It's an odd thing after all, when you're in a space that goes 88MPH, that there is an adjacent industry going well over 100. That's how it's felt with new AI applications popping up day after day. From doing someone's math homework to writing the premise for your next screenplay to scraping websites and making quick work of analyzing giant sets of data, the capabilities of AI don't seem to be slowing down any time soon.</p>
                            <p>We here at {title} know just how much analysis goes into assessing market trends, predicting where the candlestick charts might go next, if a crypto might break above its resistance levels, etc etc etc... Sometimes, making these trading decisions can feel like a lot, so it only makes sense that some might look to artificial intelligence to lend a metal hand. We're starting to see company's like Binance and Crypto.com use AI-powered chatbots to perform customer service duties, and there are even applications that scrape social media to assess market sentiment and digest financial reports for an end user. With all this possibility, the future of AI in finance could mean more access by people across the globe to advanced market analyses so that they can make better trading and investment decisions.</p>
                            <p>However, many believe that AI tools simply are not mature enough to make the sort of complex financial decisions, especially not without oversight and sufficient consumer protection. However, the potential advantages that AI would bring to finance are absolutely there. This, according Ahmed Ismail, founder and CEO of liquidity aggregator Fluid. With AI, there is the potential to skirt the possibility of human error and bias. Of course, a robot is only as good as its maker, and human biases can still make their way into AI programs.</p>
                            <p>With all this robot talk, something that is important to touch upon is the effect on humans. And by that, we mean job displacement. With any new technology, be that system automation, the advent of email and cars, and so on and so forth, there is always the question of who is put out of a job. And it's an important question, especially as AI has proven to be a tidal wave of a paradigm shift. With various tech leaders signing an open letter to pause development on AI, the recent writers’ strike in the US calling for studios to stem AI involvement in the writing process, and just the general possibility of AI performing tasks that humans currently do, there is much to consider here, and this question will only get bigger.</p>
                            <a><u>Cointelegraph</u></a>
                            <div className='coindesk-img'> <img src={cointelegraph}></img></div>
                            <p><strong>Happy Bitcoin Pizza Day everyone!</strong></p>
                            <p>It is (was depending on your timezone) May 22, which means it is Bitcoin Pizza Day! Today commemorates the day when a man by the name of Laszlo Hanyecz bought two pizzas with 10,000BTC. And no, those pizzas were not made of gold. Rather, these now legendary pizzas fetched 10,000 BTC because they were made, bought and consumed in 2010, when BTC was worth not even a penny. And oh, how times have changed. Today 1 BTC is worth just over $27K at the time of writing, which would make those pizzas $270million. For that price tag, those pizzas better give you powers... </p>
                            <p>All jokes aside, this day not only commemorates overpriced pizzas, it commemorates (albeit debatably) the very first time a digital currency was used to purchase something in the real world. And it serves as a sort of crypto creation myth. A preamble to the story of the “future of money” all of us in crypto are working to write.</p>
                            <p>So happy pizza day everyone. Celebrate by buying some Bitcoin, or, ya know, buying a pizza. Just check the price tag before you order.</p>
                            <a><u>MarketWatch</u></a>
                            <hr />

                        </div>
                        <div className='listings'>
                            <h3>🤑 New Listings on {title}</h3>
                            <p  >Here’s what we’ve listed recently:</p>
                            <h3>New Coins</h3>
                            <ul>
                                <li><p><a><u><strong>DMT</strong></u></a></p></li>
                                <li><p><a><u><strong>OPEPE</strong></u></a></p></li>
                                <li> <p><a><u><strong>RFD</strong></u></a></p></li>
                                <li> <p><a><u><strong> BNBDADDY</strong></u></a></p></li>
                                <li> <p><a><u><strong>LARRY</strong></u></a></p></li>
                            </ul>
                            <h3>Futures</h3>
                            <ul>
                                <li><p><a><u><strong>ORDI</strong></u></a></p></li>
                                <li><p><a><u><strong>1000OPEPE</strong></u></a></p></li>
                                <li> <p><a><u><strong>SUI</strong></u></a></p></li>
                                <li> <p><a><u><strong> ID</strong></u></a></p></li>
                                <li> <p><a><u><strong>MASK</strong></u></a></p></li>
                            </ul>
                            <hr />
                            <h3>What’s happening here at {title}?</h3>
                            <p>This is what {title} week looked like</p>
                            <div><img src={week_highlight}></img></div>
                            <hr />
                            <h3>Campaigns</h3>
                            <p>Our recap of ongoing campaigns that YOU can get involved in!

                            </p>
                            <p>Drum roll please 🥁🥁🥁</p>
                            <p>Introducing BTC Flexible Savings! We’re offering subscribers to this principal-protected investment product an APY of 0.2% on their BTC holdings!! Check out the <a><u>details here</u></a>.</p>
                            <p>Holy airdrops, Batman! We’ve got some campaigns for you to celebrate some of our recent listings ✈️</p>
                            <ul className='airdrop'>
                                <li><a><u>$24,000 Airdrop to Celebrate the Listing of Pepe AI (PEPEAI)</u>

                                </a></li>
                                <li><a> <u>$25,000 Airdrop to Celebrate the Listing of Limoverse (LIMO)</u>

                                </a></li>
                            </ul>
                            <hr />
                            <p>And that about covers it for this week! Curious about our campaigns or upcoming events? Give us a shout in our Telegram channel-<a><u> https://t.me/{title}English</u></a> </p>
                            <p>hanks for spending some time with us as we went over what’s going on. And wherever you are, from the team here at {title}, good morning, good day, and good evening!</p>
                        </div>
                    </div>

                </div>


                <div className='col-lg-4 col-md-4 col-sm-12 follow-sec'>
                    <div >Follow {title} On</div>
                    <div className='social-icons'>
                        <ul>
                            <li>
                                <a>  <TwitterIcon /></a>
                            </li>
                            <li>
                                <a>  <FacebookIcon /></a>
                            </li>
                            <li>
                                <a>     <TelegramIcon /></a>
                            </li>
                            <li>
                                <a> <RedditIcon /></a>
                            </li>
                            <li>
                                <a> <ForumIcon /></a>
                            </li>
                            <li>
                                <a> <InstagramIcon /></a>
                            </li>

                        </ul>
                    </div>
                    <div >
                        <p className='follow'>Related Posts</p>

                        <div className='col-12  right-sec'>
                            <a href='/'>
                                <div className='card'>
                                    <img src={morning_cup}></img>
                                    <div className='card-section'>

                                        <div className='corem-section'>
                                            <div className='corem-layer'>
                                                <h3>  ☕️The Tale of AI (so far)
                                                </h3>
                                                <p>  AI is certainly in the air. From startups to tech titans, everyone seems to be looking for ways to implement artificial intelligence into their products.  All of this, however, isn't without its controversy.  </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12  right-sec'>
                            <a href='/'>
                                <div className='card'>
                                    <img src={morning_cup}></img>
                                    <div className='card-section'>

                                        <div className='corem-section'>
                                            <div className='corem-layer'>
                                                <h3>  ☕️BRC-20 has exploded in popularity. But not everybody's onboard.  </h3>
                                                <p> Although not necessarily new, the token standard on the Bitcoin network has recently seen a meteoric rise in popularity. But this rise has been met with a fair bit of controversy.  </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12  right-sec'>
                            <a href='/'>
                                <div className='card'>
                                    <img src={morning_cup}></img>
                                    <div className='card-section'>

                                        <div className='corem-section'>
                                            <div className='corem-layer'>
                                                <h3> ☕️ $PEPE explodes…. then sputters a bit </h3>
                                                <p>  In a twist that has left the altcoin world astounded, Pepecoin (PEPE) holders are likely reaping the rewards of their investment, following an almost unbelievable surge of 5,000,000% in the past few weeks. PEPE, however, has experienced a downturn of nearly 45% after peaking at $0.00000431 last Friday. With this peak, the coin's market capitalization hit an impressive $1.8 billion, achieved in just over three weeks since its mid-April issuance.  </p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default MorningCup