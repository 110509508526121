import React from "react";
import "./Assets/scss/style.scss"; 
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, } from "react-router-dom";
import Index from "./Public/Index";
import Header from "./Public/Header";
import Footer from "./Public/Footer";
import Dashboard from "./Public/Templates/Dashboard";
import Activity from "./Public/Templates/Activity";
import Profile from "./Public/Templates/Profile";
import Fees from "./Public/FooterPages/Fees";
import Referral from "./Public/Section/Referral";
import ComplianceLicense from "./Public/FooterPages/ComplianceLicense";
// import Referrel from "./Public/Templates/Referrel";
import About from "./Public/FooterPages/About";
import Feedback from "./Public/FooterPages/Feedback";
import ProtectionFund from "./Public/FooterPages/ProtectionFund";
import ProofReserves from "./Public/FooterPages/ProofReserves";
import StaToken from "./Public/FooterPages/StaToken";
import Careers from "./Public/FooterPages/Careers";
import Rewards from "./Public/FooterPages/Rewards";
import IvServices from "./Public/FooterPages/IvServices";
import ApiTrading from "./Public/FooterPages/ApiTrading";
import Earn from "./Public/FooterPages/Earn";
import Markets from "./Public/FooterPages/Markets";
import FeeSchedule from "./Public/FooterPages/FeeSchedule";
import Verification from "./Public/FooterPages/Verification";
import BuyBitcoin from "./Public/FooterPages/BuyBitcoin";
import CommingSoon from "./Public/FooterPages/CommingSoon";
import CopyTrading from "./Public/FooterPages/CopyTrading";
import Policies from "./Public/FooterPages/Policies";
import Launchpad from "./Public/FooterPages/Launchpad";
import AffiliateProgram from "./Public/FooterPages/AffiliateProgram";
import StartagyTrading from "./Public/FooterPages/StartagyTrading";
import VipIntroduce from "./Public/FooterPages/VipIntroduce";
import Deposit from "./Public/Templates/Deposit";
import Login from "./Public/Templates/Login";
import SignUp from "./Public/Templates/SignUp";
import ForgotPass from "./Public/Templates/ForgotPass";
import Withdraw from "./Public/Templates/Withdraw.jsx";
import VerificationOtp from "./Public/Templates/VerificationOtp";
import Transfer from "./Public/Templates/Transfer";

import ApiTradingNew from "./Public/FooterPages/ApiTradingNew";
import GettingApiKey from "./Public/ApiPages/GettingApiKey";
import EndpointUrl from "./Public/ApiPages/EndpointUrl";
import Accounts from "./Public/ApiPages/Accounts";
import Contracts from "./Public/ApiPages/Contracts";
import ApiSidebar from "./Public/ApiSidebar/ApiSidebar";
import Transaction from "./Public/ApiPages/Transaction";
// import AuthGuard from "./config/AuthGuard";
// import PVTHeader from "./Private/HeaderFooter/PVTHeader";
import Kyc from "./Private/Templates/Kyc";
import MarketReport from "./Public/Templates/MarketReport";
import DepositHistory from "./Public/Templates/DepositHistory";
import PrivateRoutes from "./config/PrivateRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WithdrawHistory from "./Public/Templates/WithdrawHistory";
import ChangePassword from "./Public/Templates/ChangePassword";
import AccountDetails from "./Public/Templates/AccountDetails";
import ActivityLogs from "./Public/Templates/ActivityLogs";
import KycDetails from "./Private/Templates/KycDetails";
import P2p from "./Public/p2pPages/P2p";
import STABlog from "./Public/FooterPages/STABlog";
import MyAdsPost from "./Public/p2pPages/MyAdsPost";
import AddPost from "./Public/p2pPages/AddPost";
import TransactionCompleted from "./Public/p2pPages/TransactionCompleted";
import { title } from "./config/config";
import MorningCup from "./Public/FooterPages/MorningCup";
import Bonus from "./Public/Templates/Bonus";
import ViewAdsPostData from "./Public/p2pPages/ViewAdsPostData";
import MainTrading from "./Private/TradingComp/MainTrading";
import ManualTrading from "./Private/TradingCompManual/ManualTrading";
import PublicMainTrading from "./Public/TradingComp/MainTrading";
import PublicManualTrading from "./Public/TradingCompManual/ManualTrading";
import LaunchBase from "./Public/Templates/LaunchBase";
import Sidebardash from "./Public/Templates/Sidebardash";
import AccountsInfo from "./Public/Dashboard/AccountsInfo";


function App() {

  window.scrollTo(0, document.body.scrollTop);

 


  function LoginLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  function PublicLayout() {
    return (
      <>
        <div className="login__container">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </>
    );
  }

  function ParivateLayout() {
    return (
      <>
        <Header />
        <div className="__conta">
          <Sidebardash />
          <Outlet />
        </div>
        <Footer />
      </>
    );
  }

  function MarketLayout() {
    return (
      <>
        <div className="__trading">
          <Header />
        </div>
        <Outlet />
      </>
    );
  }

  function PublicMarketLayout() {
    return (
      <>
        <div className="__trading">
          <Header />
        </div>
        <Outlet />
      </>
    );
  }

  function ApiLayout() {
    return (
      <>
        <Header />
        <ApiSidebar />
        <Outlet />
      </>
    );
  }

  function P2pLayout() {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }


  return (
    <>
      <Router>
        <Routes>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-up/:sponser" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/VerificationID/:token" element={<VerificationOtp />} />

          </Route>

          <Route element={<PublicLayout />}>
            <Route path="/" element={<Index />} />
            <Route path="*" element={<Index />} />
            <Route path="/fees" element={<Fees />} />
            <Route path="/about" element={<About />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/ksn-blog" element={<STABlog />} />
            <Route path="/sta-inner-blog" element={<MorningCup />} />
            <Route path="/protection-fund" element={<ProtectionFund />} />
            <Route path="/proof-reserves" element={<ProofReserves />} />
            <Route path="/fee-schedule" element={<FeeSchedule />} />
            <Route path="/verification-channel" element={<Verification />} />
            <Route path="/buy-bitcoin" element={<BuyBitcoin />} />
            <Route path="/ksn-token" element={<StaToken />} />
            {/* <Route path="/buy-crypto" element={<BuyCrypto />} /> */}
            <Route path="/careers" element={<Careers />} />
            <Route path="/promotions" element={<Rewards />} />
            <Route path="/institutional-services" element={<IvServices />} />
            <Route path="/api-trading" element={<ApiTrading />} />
            <Route path="/api-trading-new" element={<ApiTradingNew />} />
            <Route path="/regulatory-license" element={<ComplianceLicense />} />
            <Route path="/referral-program" element={<Referral />} />
            <Route path="/copy-trading" element={<CopyTrading />} />
            <Route path="/startagy-trading" element={<StartagyTrading />} />
            <Route path="/vip-services" element={<VipIntroduce />} />
            <Route path="/coming-soon" element={<CommingSoon />} />
            <Route path="/privacy-policy" element={<Policies />} />
            <Route path="/ksn-launchpad" element={<Launchpad />} />
            {/* <Route path="/affiliate-program" element={<AffiliateProgram />} /> */}
            <Route path="/earn" element={<Earn />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/launchbase" element={<LaunchBase />} />

          </Route>

          <Route element={<ParivateLayout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/accounts" element={<AccountsInfo />} />
              <Route path="/dashboard/activitylogs" element={<Activity />} />
              <Route path="/dashboard/profile" element={<Profile />} />
              <Route path="/dashboard/change-password" element={<ChangePassword />} />
              <Route path="/dashboard/deposit" element={<Deposit />} />
              <Route path="/dashboard/withdraw" element={<Withdraw />} />
              <Route path="/dashboard/transfer" element={<Transfer />} />
              <Route path="/dashboard/:type" element={<MarketReport />} />
              <Route path="/dashboard/account-record/:type" element={<AccountDetails />} />
              <Route path="/dashboard/deposit-history" element={<DepositHistory />} />
              <Route path="/dashboard/withdraw-history" element={<WithdrawHistory />} />
              <Route path="/dashboard/kyc" element={<Kyc />} />
              <Route path="/dashboard/kycdetails" element={<KycDetails />} />
              <Route path="/dashboard/kyc/Approved" element={<KycDetails />} />
            </Route>
          </Route>



          <Route element={<P2pLayout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard/p2p" element={<P2p />} />
              <Route path="/dashboard/addpost" element={<AddPost />} />
              <Route path="/dashboard/myadspost" element={<MyAdsPost />} />
              <Route path='Dashbaord/view-adds-data/:order_type/:id' element={<ViewAdsPostData title={`ADD POST | ` + title + ` EXCHANGE`} />} />
              <Route path='/dashboard/p2p/transaction/:id' element={<TransactionCompleted title={`P2P | ` + title} />} />

            </Route>
          </Route>

          <Route element={<MarketLayout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard/trading/:id" element={<MainTrading />} />
              <Route path="/dashboard/tradings/:id" element={<ManualTrading />} />
            </Route>
          </Route>

          {/* Public Market ( Trading page......) */}
          <Route element={<PublicMarketLayout />}>
            <Route path="/trade/:id" element={<PublicMainTrading />} />
            <Route path="/trades/:id" element={<PublicManualTrading />} />
          </Route>

          <Route element={<ApiLayout />}>
            <Route path="/getting-api-key" element={<GettingApiKey />} />
            <Route path="/endpoint-url" element={<EndpointUrl />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/contracts" element={<Contracts />} />
            <Route path="/transaction" element={<Transaction />} />
          </Route>
        </Routes>

      </Router>
      <ToastContainer />

    </>
  );
}

export default App;