import React from 'react'
import careerslogo from '../../Assets/Image/careerslogo.png';
import { Link } from 'react-router-dom';
import { SearchRounded } from '@mui/icons-material';
import { ArrowOutwardSharp } from '@mui/icons-material';
import Form from 'react-bootstrap/Form';
import { shortTitle, title } from '../../config/config';

const Careers = () => {
  return (
    <div className='main_careers only-margin'>
      <div className="careers_page">
        <div className="careers_banner">
          <div className="coustom_container">
            {/* <img src={careerslogo} alt='' /> */}
            <h6>Buy & Trade WithTrust Brands</h6>
            <p>At {title}, we're transforming the way people trade and interact with digital assets. Our mission is to create a secure, user-friendly, and innovative platform for our global community. We're a diverse team of professionals passionate about technology, finance, and the future of digital exchanges.</p>
          </div>
        </div>

        <div className="coustom_container text_area pt-5">

          <p>
            Established in 2023, {shortTitle} has become one of the world’s largest crypto copy trading and derivatives exchanges and is ranked in the top five globally by CoinGecko for derivatives trading by volume. Currently serving over 8 million users in more than 100 countries around the world, {shortTitle} accelerated its mission to promote decentralized finance with an 800-strong workforce.
            <br />
            <br />
            Adhering closely to its philosophy of ‘Better Trading, Better Life’, {shortTitle} is committed to providing comprehensive and secure trading solutions to users globally. {shortTitle} announced its partnership with Argentinian legendary footballer, Lionel Messi in Oct 2022. And earlier in 2021, {shortTitle} announced its sponsorship of world-renowned football team Juventus as its first-ever sleeve partner and PGL Major’s official esports crypto partner soon after. Partnerships with the leading esports organization, Team Spirit, was also announced in early 2022.
          </p>

          <div className="dropdowns">
            <div className="dropdown_loc">
              <div>
                <Form.Select className='form-control' aria-label="Default select example">
                  <option>Location</option>
                  <option value="1">Location</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">3</option>
                </Form.Select>
              </div>

            </div>

            <div className="dropdown_loc">
              <div>
                <Form.Select className='form-control' aria-label="Default select example">
                  <option>Location</option>
                  <option value="1">Location</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">3</option>
                </Form.Select>
              </div>

            </div>


            <div className="dropdown_loc">
              <div>
                <Form.Select className='form-control' aria-label="Default select example">
                  <option>Location</option>
                  <option value="1">Location</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">3</option>
                </Form.Select>
              </div>

            </div>

          </div>

          <div className="joblocation_body">
            <div className="job_detail">

              <Link to="#">
                <p> Backend Developer</p>
                <p> Posted Today </p>
              </Link>

              <Link to="#">
                <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                <p> Hong Kong  </p>
              </Link>

              <Link to="#">
                Full time
              </Link>
            </div>

            <div className="job_detail">

              <Link to="#">
                <p> Backend Developer</p>
                <p> Posted Today </p>
              </Link>

              <Link to="#">
                <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                <p> Hong Kong  </p>
              </Link>

              <Link to="#">
                Full time
              </Link>
            </div>

            <div className="job_detail">

              <Link to="#">
                <p> Backend Developer</p>
                <p> Posted Today </p>
              </Link>

              <Link to="#">
                <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                <p> Hong Kong  </p>
              </Link>

              <Link to="#">
                Full time
              </Link>
            </div>

            <div className="job_detail">

              <Link to="#">
                <p> Backend Developer</p>
                <p> Posted Today </p>
              </Link>

              <Link to="#">
                <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                <p> Hong Kong  </p>
              </Link>

              <Link to="#">
                Full time
              </Link>
            </div>
          </div>



        </div>
      </div>

    </div>
  )
}

export default Careers