import React, { useState, useEffect } from 'react';
import icon1 from '../../Assets/Icon/icon2.png';
import { Link } from 'react-router-dom';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import ReactPaginate from 'react-paginate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const MyAdsPost = (props) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");

    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allData, setallData] = useState([])
    async function getAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }



        let d = await _fetch(`${api_url}trade/p2p-selfTransactons?search=order_type&value=buy&page=${BuyItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallData(d?.data.data);
            setbuyRecords(d.total);
        }
    }


    const [sellRecords, setsellRecords] = useState(0);
    const [sellPageCount, setsellPageCount] = useState(0);
    const [sellItemOffset, setsellItemOffset] = useState(1);
    const sellhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setsellItemOffset(newOffset);
    };
    const [sellallData, setsellallData] = useState([])


    async function getsellAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }



        let d = await _fetch(`${api_url}trade/p2p-selfTransactons?search=order_type&value=sell&page=${sellItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setsellallData(d?.data.data);
            setsellRecords(d.total);
        }
    }


    useEffect(() => {
        // document.title = props.title;
        getsellAllData()
        getAllData()
    }, [sellItemOffset,BuyItemOffset])

    return (
        <>
            <div className='myads-main'>
                <div className='container-lg myads__main__ad'>
                    <Tabs>
                        <div className="tab-header-fix">
                            <TabList>
                                <Tab>Buy </Tab>
                                <Tab>Sell</Tab>
                            </TabList>
                        </div>

                        <TabPanel>
                            <div className='my-ads-post'>
                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            <th>Advertiser</th>
                                            <th>Price</th>
                                            <th>Amount(INR)</th>

                                            <th>Receivable 
                                                {/* <span>|</span> Limits */}
                                                </th>
                                            <th>Payment Method</th>
                                            <th>Status</th>
                                            <th>Type</th>

                                            {/* <th>Action <span>0 Fees</span></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData?.map((val) => {
                                            return (
                                                <>
                                                    {val.order_type === 'buy' &&
                                                        <tr>
                                                            <td>
                                                                <p>{val.buy_currency?.toUpperCase()}</p>

                                                            </td>
                                                            <td>{val.price?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                            <td>{(val.price * val.received_amount)?.toFixed(3)} <small>{val.sell_currency?.toUpperCase()}</small></td>

                                                            <td>
                                                                {/* <p>Receivable</p> */}
                                                                <p>{val.received_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                {/* <p>Pending</p>
                                                                <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p> */}
                                                            </td>
                                                            <td>
                                                                <div > {val?.payment_method?.[0]?.type} </div>
                                                            </td>
                                                            <td>{val.order_status}</td>

                                                            <td>
                                                                {val.order_type}
                                                            </td>
                                                            {/* <td>
                                                                <Link className="btn table-buy-btn" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                            </td> */}
                                                        </tr>
                                                    }


                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={BuyhandlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={buyRecords / 10}
                                    previousLabel="<"

                                // renderOnZeroPageCount={null}
                                />}
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className='my-ads-post'>
                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            <th>Advertiser</th>
                                            <th>Price</th>
                                            <th>Available <span>|</span> Limits</th>
                                            <th>Payment Method</th>
                                            <th>Status</th>
                                            <th>Type</th>

                                            <th>Action <span>0 Fees</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sellallData?.map((val) => {
                                            return (
                                                <>


                                                    {val.order_type !== 'buy' &&
                                                        <tr>
                                                            <td>
                                                                <p>{val.sell_currency?.toUpperCase()}</p>
                                                            </td>
                                                            <td>{val.price?.toFixed(3)} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                            <td>
                                                                <p>Available</p>
                                                                <p>{val.pending_amount?.toFixed(3)} {val.sell_currency?.toUpperCase()}</p>
                                                                <p>Limits</p>
                                                                <p>{val.minimum_limit} {val.sell_currency?.toUpperCase()}</p>
                                                            </td>
                                                            <td>
                                                                {val.payment_method_type?.map((method) => {
                                                                    return (
                                                                        <> {method}</>
                                                                    );
                                                                })}
                                                            </td>
                                                            <td>{val.order_status}</td>
                                                            <td>
                                                                {val.order_type}
                                                            </td>
                                                            <td>
                                                                <Link className="btn table-buy-btn" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                            </td>
                                                        </tr>
                                                    }
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {sellRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={sellhandlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={sellRecords / 10}
                                    previousLabel="<"

                                // renderOnZeroPageCount={null}
                                />}
                            </div>
                        </TabPanel>
                    </Tabs>

                </div>
            </div>
        </>
    )
}

export default MyAdsPost
