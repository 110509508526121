import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Form as form, Table } from "react-bootstrap";
import { FilePresent } from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ padding: "30px 0" }}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Activity() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="main-sec">
      <div className="coustom_container inner-sec">
        <div className="inner-sec1">
        <div style={{ width: "100%" }}>
          <div className="__tabsl">
            <Tabs
              value={value}
              onChange={handleChange}>
              <Tab label="Withdrawls" {...a11yProps(0)} />
              <Tab label="Deposit" {...a11yProps(1)} />
              <Tab label="Transfer" {...a11yProps(2)} />
              <Tab label="Other" {...a11yProps(3)} />
            </Tabs>
          </div>

          <TabPanel value={value} index={0}>
            <div className="tab1-sec">
              <form className="form-style">
                <div>
                  <label>Coin</label>
                  <br />
                  <select name="cars" id="cars">
                    <option value="volvo">All</option>
                    <option value="saab">AGLD</option>
                    <option value="mercedes">STPT</option>
                    <option value="audi">BTCTRON</option>
                  </select>
                </div>
                <div>
                  <label>Time</label> <br />
                  <input type="date" id="date" name="date" />
                </div>
                <div className="btn-sec">
                  <Button variant="success">Reset</Button>
                  <Button variant="success">Search</Button>
                </div>
              </form>

              <div className="tab-rt-sec">
                <Button variant="success">Export</Button>
              </div>
            </div>
            <div className="table-main">
              <div className="table-sec1">
                <Table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Coin</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Net Amount</th>
                      <th>Address/ Transaction ID</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-sec2">
                <Table>
                  <thead>
                    <tr>
                      <th>Memo</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <FilePresent sx={{ fontSize: "60px" }} /> <br />
              <span>No Data</span>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="tab1-sec">
              <form className="form-style">
                <div>
                  <label>Coin</label>
                  <br />
                  <select name="cars" id="cars">
                    <option value="volvo">All</option>
                    <option value="saab">AGLD</option>
                    <option value="mercedes">STPT</option>
                    <option value="audi">BTCTRON</option>
                  </select>
                </div>
                <div>
                  <label>Time</label> <br />
                  <input type="date" id="date" name="date" />
                </div>
                <div className="btn-sec">
                  <Button variant="success">Reset</Button>
                  <Button variant="success">Search</Button>
                </div>
              </form>

              <div className="tab-rt-sec">
                <Button variant="success">Export</Button>
              </div>
            </div>
            <div className="table-main">
              <div className="table-sec1">
                <Table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Coin</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Net Amount</th>
                      <th>Address/ Transaction ID</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-sec2">
                <Table>
                  <thead>
                    <tr>
                      {/* <th>Memo</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <FilePresent sx={{ fontSize: "60px" }} /> <br />
              <span>No Data</span>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="tab1-sec">
              <form className="form-style">
                <div>
                  <label>Coin</label>
                  <br />
                  <select name="cars" id="cars">
                    <option value="volvo">All</option>
                    <option value="saab">AGLD</option>
                    <option value="mercedes">STPT</option>
                    <option value="audi">BTCTRON</option>
                  </select>
                </div>
                <div>
                  <label>Time</label> <br />
                  <input type="date" id="date" name="date" />
                </div>
                <div className="btn-sec">
                  <Button variant="success">Reset</Button>
                  <Button variant="success">Search</Button>
                </div>
              </form>

              <div className="tab-rt-sec">
                <Button variant="success">Export</Button>
              </div>
            </div>
            <div className="table-main">
              <div className="table-sec1">
                <Table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Coin</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Net Amount</th>
                      <th>Address/ Transaction ID</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-sec2">
                <Table>
                  <thead>
                    <tr>
                      {/* <th>Memo</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <FilePresent sx={{ fontSize: "60px" }} /> <br />
              <span>No Data</span>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="tab1-sec">
              <form className="form-style">
                <div>
                  <label>Coin</label>
                  <br />
                  <select name="cars" id="cars">
                    <option value="volvo">All</option>
                    <option value="saab">AGLD</option>
                    <option value="mercedes">STPT</option>
                    <option value="audi">BTCTRON</option>
                  </select>
                </div>
                <div>
                  <label>Time</label> <br />
                  <input type="date" id="date" name="date" />
                </div>
                <div className="btn-sec">
                  <Button variant="success">Reset</Button>
                  <Button variant="success">Search</Button>
                </div>
              </form>

              <div className="tab-rt-sec">
                <Button variant="success">Export</Button>
              </div>
            </div>
            <div className="table-main">
              <div className="table-sec1">
                <Table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Coin</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Net Amount</th>
                      <th>Address/ Transaction ID</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-sec2">
                <Table>
                  <thead>
                    <tr>
                      {/* <th>Memo</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                </Table>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <FilePresent sx={{ fontSize: "60px" }} /> <br />
              <span>No Data</span>
            </div>
          </TabPanel>
        </div>
        </div>
      </div>
    </div>
  );
}
