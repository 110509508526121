import React, { useEffect, useState } from 'react'
import { api_url } from '../../config/config';
import _fetch from '../../config/api';
import { FiberManualRecord, KeyboardBackspace, VisibilityOffTwoTone, VisibilityTwoTone, WaterfallChart } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import __img_trading from '../../Assets/img/tradingBar.svg';

const AccountsInfo = () => {

    const [getSpotBalance, setGetSpotBalance] = useState({ main_account: 0, funding: 0, trading: 0 });

    async function getSpotTotalBalance() {
        let spotBalanceData = await _fetch(`${api_url}incomes/portfolio`, 'GET', {}, {});
        setGetSpotBalance({ main_account: spotBalanceData.data[1].total_amount, funding: spotBalanceData.data[2].total_amount, trading: spotBalanceData.data[0].total_amount });
    }

    useEffect(() => {
        getSpotTotalBalance();
    }, [])

    const [showAssets, setShowAssets] = useState(true);

    return (
        <>
            <div className="main-dashboard">
                <div className="main-balance ">
                    <div className='row'>
                        <div className='col-12'>
                            <Link to="/dashboard" className='backButton'> <KeyboardBackspace/> Back </Link>
                        </div>

                        <div className="col-sm-12">
                            <div className="__balanceCard">
                                <h4>Accounts Overview</h4>

                                <div className="__links">
                                    <div className="__left-link">
                                        <h6>Total Balance</h6>
                                        <div className="__balance">
                                            {showAssets ?
                                                    <h3>{(getSpotBalance?.main_account + getSpotBalance?.trading + getSpotBalance?.funding)?.toFixed(4)} USDT</h3>
                                                :
                                                    <h3> <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord />  <FiberManualRecord /></h3>
                                            }

                                            {showAssets ?
                                                <VisibilityTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                                                :
                                                <VisibilityOffTwoTone onClick={() => { setShowAssets(!showAssets) }} className="pointer" />
                                            }
                                        </div>
                                    </div>

                                    {/* <div className="__right-link">
                                        <ul>
                                            <li>
                                                <Link className="btn --deposit" to={'/dashboard/accounts'}>accounts overview </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <h5 className='__divider'>Your Accounts</h5>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='card __accountCard'>
                                <div className='__img'>
                                    <WaterfallChart/>
                                    <Link to="/dashboard/account-record/main_account" className='__link'>Report  </Link>
                                </div>

                                <div className='__txt'>
                                    <h5>USDT {getSpotBalance.main_account?.toFixed(4)} </h5>
                                    <h4>Main Account</h4>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div className='card __accountCard'>
                                <div className='__img'>
                                    <WaterfallChart/>
                                    <Link to="/dashboard/account-record/funding_account" className='__link'>Report  </Link>
                                </div>

                                <div className='__txt'>
                                    <h5>USDT {getSpotBalance.funding?.toFixed(4)} </h5>
                                    <h4>Funding Account</h4>
                                </div>

                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6'>
                            <div className='card __accountCard'>
                                <div className='__img'>
                                    <WaterfallChart/>
                                    <Link to="/dashboard/account-record/trade_account" className='__link'>Report  </Link>
                                </div>

                                <div className='__txt'>
                                    <h5>USDT {getSpotBalance.trading?.toFixed(4)} </h5>
                                    <h4>Trading Account</h4>
                                </div>

                            </div>
                        </div>








                    </div>
                </div>
            </div>

        </>
    )
}

export default AccountsInfo