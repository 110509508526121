import React from "react";
import { logo, shortTitle, website } from "../../config/config";

const Verification = () => {
  return (
    <>
      <div className="verification-main">
        <div className="banner-sec">
          <div className="coustom_container">
            <img src={logo} alt="" width="100px" height="100px" />
            <h1>Official Channel Verification</h1>
            <p>
              Please Confirm That You Are Visiting:
              <a href="/"> https://{website}</a>
            </p>
            <p>
              To Prevent Phishing Or Fraud, Please Verify That The
              Mail/Website/Social Media Account Is From An Official {shortTitle} Channel.
            </p>
            <div className="search-bar">
              <form>
                <input
                  type="search"
                  placeholder="Enter the email/website/social media account"
                />
                <button type="submit" className="btn">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
