import React, { useState } from "react";
import { Menu, Close } from "@mui/icons-material";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const LeftDrawer: React.FC<Props> = ({ isOpen, onClose, children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    onClose();
  };

  return (
    <>
      <div
        className={`overlay ${isDrawerOpen ? "open" : ""}`}
        onClick={handleDrawerClose}
      />
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={handleDrawerClose}>
          <Close />
        </button>
        {children}
      </div>
      <button
        className={`drawer-toggle-btn ${isDrawerOpen ? "open" : ""}`}
        onClick={handleDrawerOpen}
      >
        <Menu />
      </button>
    </>
  );
};

export default LeftDrawer;
