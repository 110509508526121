import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AddBusiness, AdminPanelSettings, CardGiftcard, DashboardCustomize, East, KeyboardArrowDown, People, SwapHorizontalCircle } from '@mui/icons-material'
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';


const Sidebardash = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [isClassAdded, setIsClassAdded] = useState(false);




    const logout = async () => {
        var subdata = await _fetch(`${api_url}auth/logout`, 'GET', {}, {});
        if (subdata.status === 'success') {
            toasted.success(subdata.message);
            localStorage.clear();
            window.location.reload();
            navigate('/login')
        } else {
            toasted.error(subdata.message);
        }
    }





    const subtoggle = () => {
        setIsClassAdded(!isClassAdded);
    };





    return (
        <>
            <div className="__subHeader">
                <h4> More Service</h4>
                <button type="button" onClick={subtoggle} className="btn"> <KeyboardArrowDown /> </button>
            </div>

            <div className={isClassAdded ? 'z-sidebar showHeader' : ' z-sidebar'} onClick={subtoggle}>
                <div className="z-list">

                    <Link to="/dashboard" className={location.pathname === '/dashboard' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <DashboardCustomize /> Overview</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>

                    <Link to="/dashboard/profile" className={location.pathname === '/dashboard/profile' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <AdminPanelSettings /> Profile</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>

                    <Link to="/dashboard/deposit" className={location.pathname === '/dashboard/deposit' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <AddBusiness /> Deposit</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>

                    <Link to="/dashboard/withdraw" className={location.pathname === '/dashboard/withdraw' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <CardGiftcard /> Withdraw</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>



                    <Link to="/dashboard/transfer" className={location.pathname === '/dashboard/transfer' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <SwapHorizontalCircle /> Transfer</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>

                    <Link to="/dashboard/p2p" className={location.pathname === '/dashboard/p2p' ? "z-link activeLink" : 'z-link'}>
                        <div className="inner">
                            <h3> <People /> p2p</h3>
                        </div>
                        <East className="arrow-rt" />
                    </Link>


                    <button type="button" onClick={() => { logout() }} className='btn btn-danger mt-2 w-100 LogoutOut'> Logout</button>
                </div>
            </div>
        </>

    )
}

export default Sidebardash