import React from "react";
import img1 from "../../Assets/Image/img1.png";
import img2 from "../../Assets/Image/img2.png";
import img3 from "../../Assets/Image/img3.png";
import {
  TipsAndUpdates,
  RateReview,
  CardGiftcard,
  WatchLater,
} from "@mui/icons-material";
import { shortTitle } from "../../config/config";

const Feedback = () => {
  return (
    <>
      <div className="feedback-main only-margin">
        <div className="banner-sec">
          <div className="coustom_container">
            <h1>We Love Hearing From You!</h1>
            <h5>Thank You For Your Feedback.</h5>
            <p>Your feedback is invaluable to us. At [Your Company], we are committed to providing the best possible experience for our customers, and your insights help us to continually improve our services.</p>
            <p>We appreciate the time you took to share your thoughts. If you have any further comments, questions, or suggestions, please do not hesitate to reach out. Your satisfaction is our top priority, and we look forward to serving you better in the future.</p>
          </div>
        </div>

        <div className="submit-sec">
          <div className="coustom_container">
            <h2>Submit Feedback</h2>
            <div className="cards-main">
              <div className="items">
                <h3>Safety & Security</h3>
                <p>
                  If You Find Any Security Flaws Or Bugs, Please Give Us Your
                  Feedback As Soon As Possible.
                </p>
              </div>
              <div className="items">
                <h3>Product Suggestions</h3>
                <p>
                  If You Discover Any Product-Related Bugs Or Want To Share
                  Advice On How To Optimize Our Products, Your Feedback Is
                  Welcome.
                </p>
              </div>
              <div className="items">
                <h3>Design & Layout</h3>
                <p>
                  Submit Advice Related To Visual Features Or Usability. Your
                  Feedback Is Always Welcome.
                </p>
              </div>
              <div className="items">
                <h3>Operational Feedback</h3>
                <p>
                  If There Are Any Misunderstandings In Content Related To
                  Events Or Announcements, Your Feedback Is Welcome.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="work-sec">
          <div className="coustom_container">
            <h2>How Does It Work?</h2>
            <div className="work-cards">
              <div className="items">
                <TipsAndUpdates className="icon-mui" />
                <h3>Submit Feedback</h3>
              </div>
              <div className="items">
                <RateReview className="icon-mui" />
                <h3>Team Review</h3>
              </div>

              <div className="items">
                <CardGiftcard className="icon-mui" />
                <h3>Redeem Your Gift</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="serve-sec">
          <h2>We Are Proud To Serve You</h2>
          <div className="coustom_container">
            <div className="feedback-items">
              <div className="items">
                <span>
                  User Feedback <RateReview className="icon-mui" />
                </span>
                <h3>
                  Hello, I Would Like To Suggest Adding A "Hide Full" Function
                  In Copy Trade, It Will Be More Convenient For Us To Choose The
                  Traders, Thanks!
                </h3>
                <div className="user-sec">
                  <span>
                    <WatchLater className="icon-mui" />
                    2022/04/28 18:29:27
                  </span>
                  <span>User: 979***9792</span>
                </div>
              </div>
              <div className="items">
                <span>
                  User Feedback <RateReview className="icon-mui" />
                </span>
                <h3>
                  Hello, I Would Like To Suggest Adding A "Hide Full" Function
                  In Copy Trade, It Will Be More Convenient For Us To Choose The
                  Traders, Thanks!
                </h3>
                <div className="user-sec">
                  <span>
                    <WatchLater className="icon-mui" />
                    2022/04/28 18:29:27
                  </span>
                  <span>User: 979***9792</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
