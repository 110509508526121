import React from 'react'
import { shortTitle, title } from '../../config/config';
import Card from 'react-bootstrap/Card';
import about_card1 from "../../Assets/Image/about_card1.svg";
import about_card2 from "../../Assets/Image/about_card2.svg";
import about_card3 from "../../Assets/Image/about_card3.svg";
import Button from 'react-bootstrap/Button';
import Communityicons from '../Section/Communityicons';

const About = () => {
    return (


        <div className="main_footerabout only-margin">
            <div className="top-sec">
                <div className="coustom_container">
                    <div className="inner-info">
                        <span>{shortTitle}</span>
                        <h4>Who we are</h4>
                        <h1>ABOUT US</h1>

                    </div>
                </div>
            </div>

            <div className="sec-sec">
                <div className="coustom_container">
                    <div className="inner-content">
                        <div className="info">
                            <h2>Welcome to {title}</h2>
                            <p>
                                At Kissanx, we are dedicated to bringing you the best and most innovative solutions for your [specific industry or service]. Our journey began with a vision to revolutionize [industry or market], and we have been tirelessly working towards that goal ever since.
                            </p>
                            <p>
                                From the very beginning, our vision at Kissanx has been to transform the landscape of [industry or market]. We recognized the challenges and opportunities within this field and set out to create a platform that not only meets but exceeds the expectations of our users.
                                Not only this, we utilize our aptitude to meet our goals, which
                                are related to creativity, adjustability, intellectuality, and
                                success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <Communityicons />



        </div>

    )
}

export default About
