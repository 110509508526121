import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Table from "react-bootstrap/Table";
import { MoreVert, } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Sparklines, SparklinesLine } from "react-sparklines";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import LoaderCom from "../../config/LoaderCom";
import Skeleton from "react-loading-skeleton";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}



function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ padding: "3px" }}>
          <Typography sx={{ fontFamily: "open sans" }}>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// dropdown for table buttons
const Dropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        {/* {isDropdownOpen ? "Close Dropdown" : "Open Dropdown"} */}
        <MoreVert />
      </button>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          <Link to="/">Withdraw</Link>
          <Link to="/">Deposit</Link>
        </div>
      )}
    </div>
  );
};

export default function DataTabs() {
  const [value, setValue] = React.useState(0);
  const [allData, setAllData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [accountType, setAccountType] = useState("main_account");
  const [emptyData, setemptyData] = useState([]);
  const [loader, setloader] = useState(true);

  function Toggle() {


    const handleToggle = () => {
      setIsChecked(!isChecked);
    };

    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="toggle-slider"></span>
      </label>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function getSpotTabData() {
    setloader(true);

    setemptyData([])
    setAllData([]);
    let spotData = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=${accountType}`, 'GET', {}, {});
    if (spotData?.status === 'success') {
      setAllData(spotData?.market);
      setemptyData(spotData);
      setloader(false);


    }

  }

  useEffect(() => {
    getSpotTabData();

  }, [accountType]);

  function getMainAccount() {
    setAccountType("main_account");
  }
  function getFundingAccount() {
    setAccountType("funding_account");
  }
  function getTradingAccount() {
    setAccountType("trade_account");
  }



  return (
    <div className="main-tab w-100">
      <div className="marketTab">
        <div className="tab-inner1">
          <div className="tab-inner">
            <div className="tab-style">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ gap: "30px" }}
              >
                <Tab
                  label="main account"
                  {...a11yProps(0)}
                  onClick={getMainAccount}
                  className="tab-sec" />
                <Tab label="Funding Account"
                  // {...a11yProps(1)} 
                  onClick={getFundingAccount}
                  className="tab-sec" />
                <Tab label="Trade Account"
                  // {...a11yProps(2)} 
                  onClick={getTradingAccount}
                  className="tab-sec" />
              </Tabs>
            </div>
            <div className="tab-right-sec">
              <>
                <Toggle />
              </>
              <span>
                <span onClick={() => { setIsChecked(!isChecked) }} className="linked">Hide Zero Balances</span>
              </span>
              <div>
                <input type="search" className="__input" placeholder="Search" onChange={(e: any) => { setSearchedValue(e.target.value) }} />
              </div>
            </div>
          </div>
          <TabPanel value={value} index={0}>
            <div className="data-table">
              <Table hover>
                <thead>
                  <tr>
                    <th>COIN</th>
                    <th>LAST PRICE</th>
                    <th>BALANCE</th>
                    <th>24H CHANGE</th>
                    <th>MARKETS</th>
                    <th style={{ textAlign: "center" }}>TRADE</th>
                  </tr>
                </thead>
                <tbody>
                  {loader == true && <tr>
                    <td colSpan={6}>
                      <Skeleton count={5} />
                    </td>
                  </tr>}

                  {loader == false && allData?.filter((val: any, index: any) =>

                    !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                  ).map((val: any, index: any) => {

                    return (
                      <>
                        {val.total_coin > 0 && isChecked === true && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li><Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                  Trade
                                </Link></li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}

                        {isChecked === false && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li>
                                  <Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                    Trade
                                  </Link>
                                </li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}
                      </>
                    )

                  })
                  }


                </tbody>
              </Table>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>


            <div className="data-table">
              <Table hover>
                <thead>
                  <tr>
                    <th>COIN</th>
                    <th>LAST PRICE</th>
                    <th>BALANCE</th>
                    <th>24H CHANGE</th>
                    <th>MARKETS</th>
                    <th style={{ textAlign: "center" }}>TRADE</th>
                  </tr>
                </thead>
                <tbody>

                  <LoaderCom condition={emptyData} text={'Getting latest values...'} />


                  {allData?.filter((val: any, index: any) =>

                    !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                  ).map((val: any, index: any) => {

                    return (
                      <>
                        {val.total_coin > 0 && isChecked === true && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li><Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                  Trade
                                </Link></li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}

                        {isChecked === false && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li><Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                  Trade
                                </Link></li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}
                      </>
                    )

                  })
                  }


                </tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>


            <div className="data-table">
              <Table hover>
                <thead>
                  <tr>
                    <th>COIN</th>
                    <th>LAST PRICE</th>
                    <th>BALANCE</th>
                    <th>24H CHANGE</th>
                    <th>MARKETS</th>
                    <th style={{ textAlign: "center" }}>TRADE</th>
                  </tr>
                </thead>
                <tbody>


                  <LoaderCom condition={emptyData} text={'Getting latest values...'} />


                  {allData?.filter((val: any, index: any) =>

                    !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                  ).map((val: any, index: any) => {

                    return (
                      <>
                        {val.total_coin > 0 && isChecked === true && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li><Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                  Trade
                                </Link></li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}

                        {isChecked === false && <>

                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link className="t-none" to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <li><Link to={val.token_type !== 'auto' ? `/dashboard/tradings/${val.symbol.toUpperCase()}` : `/dashboard/trading/${val.symbol.toUpperCase()}`} className="btn-items">
                                  Trade
                                </Link></li>

                                <li><Link to="/dashboard/withdraw" className="btn-items">
                                  Withdraw
                                </Link></li>
                                <li><Link to="/dashboard/deposit" className="btn-items">
                                  Deposit
                                </Link></li>
                              </div>
                            </td>
                          </tr>
                        </>}
                      </>
                    )

                  })
                  }


                </tbody>
              </Table>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
