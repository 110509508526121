import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { api_url } from '../../config/config';
import _fetch from '../../config/api';
import { Dropdown } from 'react-bootstrap';

const LiveBalance = (props: any) => {
    const { id } = useParams();
    const [sellData, setSellData] = useState([{ coin_price: 0, amount_total: 0 }]);
    const [buyData, setBuyData] = useState([{ coin_price: 0, amount_total: 0 }]);
    const [emptyData, setemptyData] = useState([]);
    const [sellemptyData, setsellemptyData] = useState([]);

    async function buyDataFetch() {
        var d = await _fetch(`${api_url}trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=buy&order_status=pending`, 'GET', {}, {});
        if (d?.status === 'success') {
            setBuyData(d.data.data);
            // console.log('buy data',d.data.data)
            setemptyData(d)
        }
    }
    async function sellDataFetch() {
        var d = await _fetch(`${api_url}trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=sell&order_status=pending`, 'GET', {}, {});
        if (d?.status === 'success') {
            setSellData(d.data.data);
            setsellemptyData(d);
            // console.log('buy data',d.data.data)

        }
    }
    useEffect(() => {
        buyDataFetch()
        sellDataFetch()

    }, [props.LoadApi])



    const [buytab, setbuyTab] = useState(0);
    const [fixedValue, setfixedValue] = useState(3);

    return (
        <div className='main-order-book'>

            <div className='__order-header'>
                <ul className='list'>
                    <li className={buytab == 0 ? 'active' : undefined} onClick={() => setbuyTab(0)}>
                        <span> </span>
                        <span> </span>
                    </li>

                    <li className={buytab == 1 ? 'active' : undefined} onClick={() => setbuyTab(1)}>
                        <span> </span>
                        <span> </span>
                    </li>

                    <li className={buytab == 2 ? 'active' : undefined} onClick={() => setbuyTab(2)}>
                        <span> </span>
                        <span> </span>
                    </li>
                </ul>


                <div className='__dropdwon'>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {fixedValue == 3 && 0.001}
                            {fixedValue == 4 && 0.0001}
                            {fixedValue == 5 && 0.00001}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <div className='__item' onClick={() => { setfixedValue(3) }}> 0.001</div>
                            <div className='__item' onClick={() => { setfixedValue(4) }}> 0.0001</div>
                            <div className='__item' onClick={() => { setfixedValue(5) }}> 0.00001</div>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

            </div>


            {buytab == 0 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>
                        <div className='scroll-y'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {sellData.map((item, index) => (
                                        <tr data-index={index} >
                                            <td className='pl'>{item.coin_price?.toFixed(fixedValue)}</td>
                                            <td className='al'>{item.amount_total?.toFixed(fixedValue)}</td>
                                            <td className='tl'>{item.coin_price * item.amount_total}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                        <div className='scroll-y1'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='numbers'>{Number(buyData?.[0]?.coin_price?.toFixed(fixedValue) ?? 0)}</th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.amount_total?.toFixed(fixedValue) ?? 0)}</th>
                                        <th className='total'>More</th>
                                    </tr>

                                   
                                </thead>
                                <tbody>


                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} >
                                                    <td className="status-dot-cell-green pl"> {item.coin_price.toFixed(fixedValue)} </td>
                                                    <td className='al'>{item.amount_total?.toFixed(fixedValue)}</td>
                                                    <td className='tl'>{item.coin_price * item.amount_total}</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {buytab == 1 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>

                        <div className='scroll-y1 scroll-fully'>
                            <table>
                                <thead>

                                <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>

                                    {/* <tr>
                                        <th className='numbers'>{Number(buyData?.[0]?.coin_price?.toFixed(fixedValue) ?? 0)}</th>
                                        <th className='numbers2'>${Number(buyData?.[0]?.amount_total?.toFixed(fixedValue) ?? 0)}</th>
                                        <th className='total'>More</th>
                                    </tr> */}
                                </thead>
                                <tbody>


                                    {buyData.map((item, index) => (
                                        <>
                                            {(item) ?
                                                <tr key={index} >
                                                    <td className="status-dot-cell-green pl"> {item.coin_price.toFixed(fixedValue)} </td>
                                                    <td className='al'>{item.amount_total?.toFixed(fixedValue)}</td>
                                                    <td className='tl'>{item.coin_price * item.amount_total}</td>
                                                </tr>
                                                : ''}
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }


            {buytab == 2 &&
                <div className='main-table-data'>
                    <div className='main-table-order'>
                        <div className='scroll-y scroll-fully'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Price(USDT)</th>
                                        <th>Amount({props.symbol.toUpperCase()})</th>
                                        <th className='total'>Total</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    {sellData.map((item, index) => (
                                        <tr data-index={index} >
                                            <td className='pl'>{item.coin_price?.toFixed(fixedValue)}</td>
                                            <td className='al'>{item.amount_total?.toFixed(fixedValue)}</td>
                                            <td className='tl'>{item.coin_price * item.amount_total}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default LiveBalance