import React, { useRef } from "react";
import { CopyAll, Info } from "@mui/icons-material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "react-bootstrap";
import { shortTitle } from "../../config/config";

const Contracts = () => {
  const textRef = useRef<HTMLDivElement>(null);

  const handleCopy = () => {
    if (textRef.current) {
      const range = document.createRange();
      range.selectNode(textRef.current);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
      alert("text copied succesfully");
    }
  };
  return (
    <>
      <div className="main-sec-cont">
        <div className="coustom_container">
          <div className="cont-inner">
            <div className="verified-sec">
              <h2>Contracts</h2>

              <div className="veri-inner">
                <h4>Get Contract ABI for Verified Contract Source Codes</h4>
                <p>
                  Returns the contract Application Binary Interface ( ABI ) of a
                  verified smart contract.
                </p>

                <div className="info-sec">
                  <span>
                    <Info />
                  </span>
                  <span>
                    Find verified contracts on our Verified Contracts Source
                    Code page.
                  </span>
                </div>
                <div className="inner" ref={textRef}>
                  <span>
                    https://api.{shortTitle}.com/api <br />
                    ?module=account <br />
                    &action=balance
                    <br />
                    &address=0x70F657164e5b75689b64B7fd1fA275F334f28e18
                    <br />
                    &apikey=YourApiKeyToken
                  </span>
                  <div>
                    <span>
                      <CopyAll onClick={handleCopy} titleAccess="copy text" />
                    </span>
                  </div>
                </div>

                <div className="request-sec">
                  <h3>Try this endpoint in your Browser</h3>
                  <div className="request-table">
                    <div className="inner-table">
                      <Tabs>
                        <TabList>
                          <Tab>Request</Tab>
                          <Tab>Response</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="head-sec">
                            <h5>Query Parameters</h5>
                            <div className="inner-sec-table">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Parameter</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>address</td>
                                    <td>
                                      the string representing the address to
                                      check for balance
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>tag</td>
                                    <td>
                                      the string pre-defined block parameter,
                                      either earliest, pending or latest
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="head-sec-resp">
                            <div className="data-resp">
                              <span>No Data Available</span>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contracts;
