import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import download_img from "../../Assets/Image/download_img.png";
import chat_img from "../../Assets/Image/chat_img.png";
import diamond_img from "../../Assets/Image/diamond_img.png";
import { title } from "../../config/config";
// import { Link } from "react-router-dom";
// import {Twitter} from '@mui/icons-material';
// import {WhatsApp} from '@mui/icons-material';
// import {Telegram} from '@mui/icons-material';
// import {Call} from '@mui/icons-material';
// import {SpeakerNotes} from '@mui/icons-material';
// import {Facebook} from '@mui/icons-material';
// import {Instagram} from '@mui/icons-material';
// import { shortTitle } from '../../config/config';

const Communityicons = () => {
  return (
    <div className="all_part">
      <div className="main_iconcard ">
        <div className="coustom_container">
          <h4>The Most Trusted Exchange as Always</h4>
          <div className="row card_box pt-2">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <Card className="card_item">
                <div className="left"></div>
                <div className="right"></div>
                <Card.Img
                  variant="top"
                  src={download_img}
                  alt=""
                  className="card_img"
                />
                <Card.Body>
                  <Card.Title className="card_title">
                    Secure and Stable
                  </Card.Title>
                  <Card.Text className="card_text">
                    We provide a trusted and intuitive platform for users, of all
                    experience levels, to trade how they want.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <Card className="card_item">
                <div className="left"></div>
                <div className="right"></div>
                <Card.Img
                  variant="top"
                  src={chat_img}
                  alt=""
                  className="card_img"
                />
                <Card.Body>
                  <Card.Title className="card_title">24 / 7 Support</Card.Title>
                  <Card.Text className="card_text">
                    Reach out to our support team with any issues and we'll help
                    you resolve them as quickly as possible.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-4">
              <Card className="card_item">
                <div className="left"></div>
                <div className="right"></div>
                <Card.Img
                  variant="top"
                  src={diamond_img}
                  alt=""
                  className="card_img"
                />
                <Card.Body>
                  <Card.Title className="card_title">VIP Service</Card.Title>
                  <Card.Text className="card_text">
                    {title} offers high rate limit, and low latency API for you
                    with good overall liquidity; friendly for institutional
                    traders.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>

        </div>
      </div>

      {/* <div className='main_community mt-5 pt-5'>

        <h4>Join Our Community</h4>
        <h6 pt-3>Keep up with the {shortTitle} team and our traders from across the world. We have everything you need to become a crypto expert.</h6>

        <div className='icons_style pt-5'>
          <Link to="#">
            <Twitter className='icon'/>
          </Link>

          <Link to="#">
            <WhatsApp className='icon'/>
          </Link>

          <Link to="#">
            <Telegram className='icon'/>
          </Link>

          <Link to="#">
            <Call className='icon'/>
          </Link>

          <Link to="#">
            <SpeakerNotes className='icon'/>
          </Link>

          <Link to="#">
            <Facebook  className='icon'/>
          </Link>

          <Link to="#">
            <Instagram  className='icon'/>
          </Link>

        </div>
        </div> */}

    </div>
  );
};

export default Communityicons;
