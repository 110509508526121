import React, { useEffect, useState } from "react";
import { Button, Dropdown, Table, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { api_url } from "../../config/config";
import _fetch from "../../config/api";

const MarketReport = (props: any) => {

  const { type } = useParams();

  const [allData, setAllData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  
  const [checked, setIsChecked] = useState(false);

  function Toggle() {

    const handleToggle = () => {
      setIsChecked(!checked);
    }

    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={checked} onChange={handleToggle} />
        <span className="toggle-slider"> </span>
      </label>
    )
  }


  async function getAccountTotalData() {
    let spotData = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=${type}`, 'GET', {}, {});
    if (spotData?.status === 'success') {
      setAllData(spotData?.market);
    }

  }

  useEffect(() => {
    getAccountTotalData();

  }, []);

  return (
    <>
      <div className="main-dashboard">
        {/* <div className="col-sm-12 bal-txt">
            <h5>Estimated Balance</h5>
          </div> */}
            <div className="coustom_container account-history pt-3">
            <h3>{type?.toString().replace("_", ' ')} History</h3>
        <div style={{ width: "100%" }} className="main-tab">
        
          <div className="tab-inner1">
            <div className="tab-inner">
            

              <div className="tab-right-sec">
                <>
                  <Toggle />
                </>
                <span>
                <Link to='' onClick={()=>{setIsChecked(!checked);}}  className="linked">Hide Zero Balances</Link>
                </span>
                <div>
                  <Form.Control type="text" placeholder="Search" onChange={(e: any) => { setSearchedValue(e.target.value) }} />
                </div>
              </div>
            </div>

            <div className="data-table">
              <Table hover>
                <thead>
                  <tr>
                    <th>COIN</th>
                    <th>LAST PRICE</th>
                    <th>BALANCE</th>
                    <th>24H CHANGE</th>
                    <th>MARKETS</th>
                    <th style={{ textAlign: "center" }}>TRADE</th>
                  </tr>
                </thead>
                <tbody>

                  {allData?.filter((val: any, index: any) =>

                    !searchedValue.length || val?.symbol.toString().toLowerCase().includes(searchedValue.toString().toLowerCase())
                  ).map((val: any, index: any) => {

                    return (
                      <>

                        {val.total_coin > 0 && checked === true && <>
                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <div>
                                  <li><Link to="/trade" className="btn-items">
                                    Trade
                                  </Link></li>

                                  <li><Link to="/dashboard/withdraw" className="btn-items">
                                    Withdraw
                                  </Link></li>
                                  <li><Link to="/dashboard/deposit" className="btn-items">
                                    Deposit
                                  </Link></li>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>}

                        {checked === false && <>
                          <tr key={index}>
                            <td>
                              <div className="tab1">
                                <div>
                                  <img src={val.image} alt="" className="tab-img" width={30} height={30} />
                                </div>
                                <div>
                                  <h5>{val.symbol.toUpperCase()}</h5>
                                  <span>{val.name}</span>
                                </div>
                              </div>
                            </td>
                            <td>{'$' + val.current_price}</td>
                            <td>
                              <p> {val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p>
                              <small>${val.usdt_income.toFixed(3)} </small>
                            </td>
                            <td>
                              <Link to="/" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }}>
                                {val.price_change_percentage_24h?.toFixed(1)} % {val.price_change_percentage_24h > 0 ? "" : ''}
                              </Link>
                            </td>

                            <td>
                              {(() => {
                                if (val.price_change_percentage_24h > 0) {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="green" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className="sparkline-area">
                                      <Sparklines data={val.sparkline_in_7d[0].price}>
                                        <SparklinesLine color="red" />
                                      </Sparklines>
                                    </div>
                                  )
                                }
                              })()}

                            </td>
                            <td>
                              <div className="tab-btn">
                                <div>
                                  <li><Link to="/trade" className="btn-items">
                                    Trade
                                  </Link></li>

                                  <li><Link to="/dashboard/withdraw" className="btn-items">
                                    Withdraw
                                  </Link></li>
                                  <li><Link to="/dashboard/deposit" className="btn-items">
                                    Deposit
                                  </Link></li>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>}

                      </>
                    )

                  })
                  }


                </tbody>
              </Table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default MarketReport;