import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import proofreserves_banner from '../../Assets/Image/proofreserves_banner.png';
import prof_card1 from '../../Assets/Image/prof_card1.png';
import prof_card2 from '../../Assets/Image/prof_card2.png';
import reserve_img from '../../Assets/Image/reserve_img.png';
import tree_plan from '../../Assets/Image/tree_plan.png';
import vs_img from '../../Assets/Image/vs_img.png';
import btc_img from '../../Assets/Image/btc_img.png';
import Card from 'react-bootstrap/Card';
import { CheckCircle } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { shortTitle } from '../../config/config';



const Proof_reserves = () => {
  return (
    <div className="proof_reservepage only-margin">
      <div className="coustom_container">
        <div className='topbg_banner'>
          <div className="proff_banner">
            <div className="row row_gap">
              <div className="col align_left">
                <h5>Proof of Reserves</h5>
                <h6>{shortTitle} guarantees it will hold 100% of users' assets in reserves and will publish its Merkle Tree proof, platform reserves, and platform reserve ratio on a monthly basis.</h6>

                <div className="btn_flex">
                  <div><Button className='main-button' variant="success" id='btn-width'>Proof of users' assets</Button></div>
                  <div><Button className='main-button' variant="success" id='btn-width'>GitHub</Button></div>
                </div>
              </div>

              <div className="col">
                <img src={proofreserves_banner} alt='' />
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className=' reserve_ratio pt-5 '>
        <div className="coustom_container">
          <div className="row">
            <div className="col-md-6">
              <div className="flex">
                <h5>The latest {shortTitle} reserve ratio</h5>
                <h6>Reserve ratio = funds in the platform/funds of platform users. A reserve ratio greater than or equal to 100% means that the platform has sufficient funds.</h6>
              </div>
            </div>
            <div className="col-md-6">

              <div className='row row_gap pt-4 pb-2'>
                <div className='col-12 top_twocards'>
                  <Card>
                    <div className="cards_flex card-block">
                      <div>
                        <Card.Img variant="top" src={prof_card1} />
                      </div>
                      <div className='text_block'>
                        <Card.Title>Merkle root hash</Card.Title>
                        <Card.Text>bab4dcc0b4020956</Card.Text>
                      </div>
                    </div>

                    {/* <Card.Body>
                    <div> <Button variant="success"></Button> </div>

                  </Card.Body> */}

                  </Card>
                </div>

                <div className='col-12 top_twocards'>
                  <Card>
                    <div className="cards_flex card-block">
                      <div>
                        <Card.Img variant="top" src={prof_card2} />
                      </div>
                      <div className='text_block'>
                        <Card.Title>Total reserve ratio</Card.Title>
                        <Card.Text>227%</Card.Text>
                      </div>
                    </div>
                  </Card>
                </div>




              </div>
            </div>
          </div>
        </div>


        <div className="inner_cards_bg">
          <div className="coustom_container">
            <div className="value_cards">
              <div className='row row_gap pt-4 pb-5'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                  <Card>
                    <div className="cards_flex">
                      <div>
                        <Card.Img variant="top" src={btc_img} />
                      </div>
                      <div className='text_block'>
                        <Card.Title>BTC reserve ratio</Card.Title>
                        <Card.Text>620%</Card.Text>

                      </div>
                    </div>
                    <div className='icon_flex'>
                      <CheckCircle className='icon_col' />
                      <h6>Reserves are sufficient</h6>
                    </div>


                    <hr className='solid' />

                    <Card.Body>
                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>

                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>
                    </Card.Body>

                  </Card>
                </div>


                <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                  <Card>
                    <div className="cards_flex">
                      <div>
                        <Card.Img variant="top" src={btc_img} />
                      </div>
                      <div className='text_block'>
                        <Card.Title>BTC reserve ratio</Card.Title>
                        <Card.Text>620%</Card.Text>

                      </div>

                    </div>

                    <div className='icon_flex'>
                      <CheckCircle className='icon_col' />
                      <h6>Reserves are sufficient</h6>
                    </div>

                    <hr className='solid' />
                    <Card.Body>
                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>

                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>
                    </Card.Body>

                  </Card>
                </div>


                <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
                  <Card>
                    <div className="cards_flex">
                      <div>
                        <Card.Img variant="top" src={btc_img} />
                      </div>
                      <div className='text_block'>
                        <Card.Title>BTC reserve ratio</Card.Title>
                        <Card.Text>620%</Card.Text>

                      </div>


                    </div>

                    <div className='icon_flex'>
                      <CheckCircle className='icon_col' />
                      <h6>Reserves are sufficient</h6>
                    </div>
                    <hr className='solid' />
                    <Card.Body>
                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>

                      <div className="body_flex">
                        <h6>{shortTitle} wallet reserves</h6>
                        <p>8730.69 BTC</p>
                      </div>
                    </Card.Body>

                  </Card>
                </div>






              </div>
            </div>
          </div>
        </div>

      </div>


      <div className="coustom_container img_text ">
        <div className="row">
          <div className="col">
            <h5>What is proof of reserves?</h5>

            <p>'Proof of reserves' refers to an auditing procedure that is verifiable through cryptographic proofs, checks of public wallet ownerships, and recurring audits to certify the holdings of an exchange. The custodian provides transparency and proof of the existence of on-chain reserves and that the total amounts of those coins held and effectively at the disposal of the platform exceeds or is equal to the sum of all users' holdings of those coins. To achieve this, {shortTitle} stores the hash of each user's account assets in a leaf node on the Merkle tree. Each user can verify that their funds are included in the Merkle tree by checking the total amount of users' assets stored in the Merkle tree leaf nodes.<br />
              If the total amount verified is greater than or equal to 100%, then the platform has proven that users' funds are indeed intact.
            </p>

            <h6>The open source code of the platform's proof of its 100% solvency program has been published to GitHub. <span>See for yourself</span></h6>
          </div>
          <div className="col d-flex justify-content-center align-items-center">
            <img src={reserve_img} alt='' />
          </div>
        </div>


        <div className="leftimg_text ">
          <div className="row">

            <div className="col maximum">
              <img src={reserve_img} alt='' />
            </div>

            <div className="col">
              <h5>We are committed to maximum transparency</h5>

              <p>{shortTitle}'s principles are meant to prioritize our users. To ensure the verifiable safety of our users' assets, we will be fully transparent with all our platform's assets.</p>

              <ol>
                <li>Take snapshots monthly and publish all wallet assets of the platform.</li>

                <li>Take snapshots of each user's assets monthly and desensitize for publication.</li>

                <li>Users can verify their assets in a few simple steps.</li>
              </ol>
            </div>
          </div>


        </div>
      </div>




      <div className=" coustom_container platform_design">
        <h5>Why 100% on-hand reserves are so important</h5>

        <div className="risk">
          <div className="risk-left">
            <h1>Unable to prove 100% on-hand reserves</h1>
            <div className="info">
              <h4>It can be a telltale sign of the misappropriation of assets <Close className='close_icon' /></h4>
              <h4>And a source of low risk tolerance for black swan event <Close className='close_icon' /></h4>
              <h4>Concentrated withdrawals can lead to a run, which may result in a loss of users' assets <Close className='close_icon' /></h4>
            </div>
          </div>


          <div className="risk-right">
            <div className="another">
              <h1>The platform provides 100% reserves</h1>
              <div className="info">
                <h4>It means that the platform has sufficient reserves</h4>
                <h4>It effectively guarantees that users' assets are safe</h4>
                <h4>The platform will have no issues paying 100% of any concentrated withdrawals of users' assets</h4>
              </div>
            </div>
          </div>


          <div className="risk-vs">
            <img src={vs_img} alt="" />
          </div>
        </div>




      </div>

      <div className="coustom_container reserve_details ">

        <h5>{shortTitle} reserve details</h5>

        <div className="table_overflow">
          <table>
            <tr>
              <th>Coin name</th>
              <th>Users' assets</th>
              <th>Platform's assets</th>
              <th>Public chain</th>
              <th>Reserve ratio</th>
            </tr>

            <tr>
              <td>BTC</td>
              <td>1316.48632672BTC</td>
              <td>8163.65741914 BTC</td>
              <td>BITCOIN</td>
              <td>620%</td>
            </tr>

            <tr>
              <td>USDT</td>
              <td>254289764.3307006USDT</td>
              <td>
                <p>46350469.18399736 USDT</p>
                <p>198049869.635799 USDT</p>
                <p>953823.386945 USDT</p>
                <p>216206136.210211 USDT</p>
              </td>

              <td>
                <p>Binance Smart Chain</p>
                <p>Ethereum</p>
                <p>Solana</p>
                <p>Tron</p>
              </td>

              <td>182%</td>
            </tr>

            <tr>
              <td>ETH</td>
              <td>5400.6952265ETH</td>
              <td>
                <p>404.52968079 ETH</p>
                <p>494.38388166 ETH</p>
                <p>494.38388166 ETH</p>
              </td>

              <td>
                <p>Arbitrum One</p>
                <p>Binance Smart Chain</p>
                <p>Ethereum</p>
              </td>
              <td>206%</td>
            </tr>
          </table>
        </div>

        <h6>107 users have verified the authenticity of the data. <span>See for yourself</span></h6>

      </div>

      <div className=" wallet_details ">
        <div className="coustom_container">

          <h5>{shortTitle} wallet details</h5>

          <div className="table_overflow">
            <table>
              <tr>
                <th>Coin name</th>
                <th>Public chain</th>
                <th>Wallet address</th>
                <th>Amout</th>
                <th>Block height</th>
              </tr>

              <tr>
                <td>BTC</td>
                <td>BITCOIN</td>
                <td>1FWQiwK27EnGXb6BiBMRLJvunJQZZPMcGd</td>
                <td>294.26</td>
                <td>774403</td>

              </tr>

              <tr>
                <td>BTC</td>
                <td>BITCOIN</td>
                <td>1FWQiwK27EnGXb6BiBMRLJvunJQZZPMcGd</td>
                <td>294.26</td>
                <td>774403</td>

              </tr>

            </table>
          </div>
        </div>




      </div>

    </div>
  )
}

export default Proof_reserves