import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import dateFormat from "dateformat";
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import Nofound from "../../config/Nofound";

function WithdrawHistory() {

    const [searchedValue, setSearchedValue] = useState("");
    const [searchedType, setSearchedType] = useState("symbol");
    const [tableRecords, settableRecords] = useState([]);
    const [itemOffset, setItemOffset] = useState(1);
    const [dataValues, setDataValues] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [sort, setSort] = useState('created_at');

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };


    async function getWithdrawData() {
        settableRecords([]);
        let passData = {};
        if (searchedValue !== "") {
            var searchData = `&search=${searchedType}&value=${searchedValue}`;
        }
        else {
            var searchData = "";
        }

        const Data = await _fetch(`${api_url}withdraw/withdrawHistory?sort=${sort}&page=${itemOffset}&type=main_account&limit=10${searchData}`, 'GET', passData,);
        setDataValues(Data?.data.data);
        setTotalData(Data.total);
    }

    useEffect(() => {
        getWithdrawData();
    }, [searchedType, sort, searchedValue, itemOffset, totalData, dataValues])
    return (
        <>
            <div className="main-dashboard w-100">
                <div className="deposit-page">
                    <div className='col-12'>
                        <Link to="/dashboard/withdraw" className='backButton'> <KeyboardBackspace /> Back </Link>
                    </div>

                    <div>
                        <h1>withdraw History</h1>
                    </div>

                    <div className="main-tab w-100">
                        <div className="tab-inner1">
                            <div className="tab-inner">
                                {/* <div>
                                    <h2>Withdraw History</h2>
                                </div> */}

                                <div className="tab-right-sec">

                                    <select onChange={(e: any) => { setSearchedType(e.target.value) }}>
                                        <option value="symbol">Symbol</option>

                                    </select>

                                    <div>
                                        <Form.Control type="text" placeholder="Search" onChange={(e: any) => { setSearchedValue(e.target.value) }} />
                                    </div>
                                </div>
                            </div>

                            <div className="data-table">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th onClick={() => { sort === 'symbol' ? setSort('-symbol') : setSort('symbol') }} style={{ cursor: 'pointer' }} >SYMBOL</th>
                                            <th onClick={() => { sort === 'network' ? setSort('-network') : setSort('network') }} style={{ cursor: 'pointer' }} >NETWORK</th>
                                            <th onClick={() => { sort === 'amount' ? setSort('-amount') : setSort('amount') }} style={{ cursor: 'pointer' }} >AMOUNT</th>
                                            <th onClick={() => { sort === 'payable_amount' ? setSort('-payable_amount') : setSort('payable_amount') }} style={{ cursor: 'pointer' }} >PAYABLE AMOUNT</th>
                                            <th onClick={() => { sort === 'status' ? setSort('-status') : setSort('status') }} style={{ cursor: 'pointer' }} >STATUS</th>
                                            <th onClick={() => { sort === 'wallet_address' ? setSort('-wallet_address') : setSort('wallet_address') }} style={{ cursor: 'pointer' }} >WALLET ADDRESS</th>
                                            <th onClick={() => { sort === 'created_at' ? setSort('-created_at') : setSort('created_at') }} style={{ cursor: 'pointer' }} >CREATED AT</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataValues.length == 0 ?
                                            <Nofound />
                                            :
                                            <>
                                                {dataValues?.map((val: any, index: any) => {
                                                    return (
                                                        <>
                                                            <tr key={index} >
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <p>{val.symbol.toUpperCase()}</p>
                                                                </td>
                                                                <td>
                                                                    {val.network}
                                                                </td>
                                                                <td>
                                                                    <p>{val.amount}</p>
                                                                </td>
                                                                <td>
                                                                    {val.payable_amount}
                                                                </td>
                                                                <td>
                                                                    {val.status ? val.status : 'Pending'}
                                                                </td>
                                                                <td>
                                                                    {val.wallet_address}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>{dateFormat(val.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                                            </tr>

                                                        </>
                                                    )

                                                })
                                                }
                                            </>
                                        }
                                    </tbody>
                                </Table>
                                {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={1}
                                    pageCount={totalData / 10}
                                    previousLabel="<"
                                />}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default WithdrawHistory;