import React from 'react'
import SupportIcon from '@mui/icons-material/Support';
import { website } from '../config/config';
import InfoIcon from '@mui/icons-material/Info';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Marquee from "react-fast-marquee";

const InfoSec = () => {
    return (
        <div>
            <div className="for_after_marque">
                <div className="info__sec">
                    <Marquee pauseOnHover={true}>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>support@{website}</h4>
                                <p>Support Service</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>info@{website}</h4>
                                <p>Business Opportunity</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>trade@{website}</h4>
                                <p>Trading Quant & MM</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>vip@{website}</h4>

                                <p>VIP Services</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>support@{website}</h4>

                                <p>Support Service</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>info@{website}</h4>

                                <p>Business Opportunity</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>trade@{website}</h4>

                                <p>Trading Quant & MM</p>
                            </div>
                        </div>
                        <StarBorderIcon />
                        <div className="inner">
                            <div className="info">
                                <h4>vip@{website}</h4>

                                <p>VIP Services</p>
                            </div>
                        </div>

                    </Marquee>
                </div>

            </div>
        </div>
    )
}

export default InfoSec
