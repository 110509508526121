import React, { useEffect, useState } from 'react'
import Sec2cardpart from './Sec2cardpart'
import Sec2tabpart from './Sec2tabpart'
import Sec2rewardpart from './Sec2rewardpart'
import Sec2textimg from './Sec2textimg'
import assets_img from "../../Assets/Image/assets_img.png";
import marketplace_img from "../../Assets/Image/marketplace_img.png";
import outline_logo from "../../Assets/Image/logo_outline_dark.png";
import { title } from "../../config/config";
import { ArrowForwardIos } from "@mui/icons-material";
import igu_img from "../../Assets/Image/igu_img.png";
import btt_img from "../../Assets/Image/btt_img.png";
import win_img from "../../Assets/Image/win_img.png";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import card1_img from "../../Assets/Image/card1_img.png";
import card2_img from "../../Assets/Image/card2_img.png";
import card3_img from "../../Assets/Image/card3_img.png";

const Section2 = () => {

  return (
    <div className='main_section2'>
      <Sec2tabpart />
      <div className="for_stickey">
        <div className=" main-sec-card">
          <div className="coustom_container">
            <div className="main_card">
              <div>
                <p className="heading">Your one-stop shop for crypto trading</p>
              </div>

              <div className="row p-2 mt-5  card_bar">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 ">
                  <Link to="#">
                    <Card className="card">
                      <div className="info">
                        <svg className="blob"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path d="M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z" fill="#66cce500" />
                          </g>
                        </svg>
                        <Card.Img variant="top" src={card1_img} alt="" />

                      </div>
                      <Card.Body>
                        <Card.Title className="card_title">
                          Trade all the trending assets
                        </Card.Title>
                        <Card.Text className="card_text">
                          Discover over 400 cryptocurrencies including all the
                          trending new listings.
                        </Card.Text>
                        <div className="card_footer">
                          <div>
                            <span>
                              400+ cryptocurrencies
                            </span>
                          </div>

                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6 col-12">
                  <Link to="#">
                    <Card className="card">
                      <div className="info">
                        <svg className="blob"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path d="M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z" fill="#66cce500" />
                          </g>
                        </svg>
                        <Card.Img variant="top" src={card2_img} alt='' />
                      </div>
                      <Card.Body>
                        <Card.Title className="card_title">
                          Trade all the trending assets
                        </Card.Title>
                        <Card.Text className="card_text">
                          Discover over 400 cryptocurrencies including all the
                          trending new listings.
                        </Card.Text>

                        <div className="card_footer">
                          <div>
                            <span>
                              100x Max leverage
                            </span>
                          </div>

                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6 col-12 last-card">
                  <Link to="#">
                    <Card className="card">
                      <div className="info">
                        <svg className="blob"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path d="M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z" fill="#66cce500" />
                          </g>
                        </svg>
                        <Card.Img variant="top" src={card3_img} alt="" />
                      </div>
                      <Card.Body>
                        <Card.Title className="card_title">
                          Trade all the trending assets
                        </Card.Title>
                        <Card.Text className="card_text">
                          Discover over 400 cryptocurrencies including all the
                          trending new listings.
                        </Card.Text>
                        <div className="card_footer">
                          <div>
                            <span>
                              As low as 1% interest rate
                            </span>
                          </div>

                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_reward">
        <div className="coustom_container">
          <div className="text_area">
            <h4>Earn Crypto Rewards</h4>
            <p>
              Participate in Polo Earn wealth management and enjoy the benefits of
              higher rate
            </p>
          </div>

          <div className="card_area">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="card_body">

                  <Link to="#">
                    <div className="card_footer">
                      <div className="image_text">
                        <img src={igu_img} alt="" className="icon_img"></img>
                        <h5>
                          <b>IGU</b> Fixed
                        </h5>
                      </div>

                    </div>
                    <p>100.00%</p>
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="card_body">
                  <Link to="#">
                    <div className="card_footer">
                      <div className="image_text">
                        <img src={igu_img} alt="" className="icon_img"></img>
                        <h5>
                          <b>IGU</b> Fixed
                        </h5>
                      </div>

                    </div>
                    <p>100.00%</p>
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="card_body">

                  <Link to="#">
                    <div className="card_footer">
                      <div className="image_text">
                        <img src={btt_img} alt="" className="icon_img"></img>
                        <h5>
                          <b>BTT</b> Flexible
                        </h5>
                      </div>

                    </div>
                    <p>100.00%</p>
                  </Link>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="card_body">

                  <Link to="#">
                    <div className="card_footer">
                      <div className="image_text">
                        <img src={win_img} alt="" className="icon_img"></img>
                        <h5>
                          <b>WIN</b> Flexible
                        </h5>
                      </div>

                    </div>
                    <p>100.00%</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-sec-assets">
        <div className="coustom_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left_info">
                <img src={outline_logo} alt="" />
                <h1>{title}</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse suscipit cumque, culpa iusto praesentium vero accusantium natus animi voluptas adipisci id! Laboriosam</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main_assets">
                <span className="border_left_top"></span>
                <span className="border_left_bottom"></span>
                <span className="border_right_bottom"></span>
                <span className="border_right_top"></span>
                <div className="img-sec">
                  <img src={assets_img} alt=""></img>
                </div>

                <div className="text_area">
                  <h4>Safeguard Your Assets</h4>
                  <p>
                    An industry-leading, multi-layered risk management system to
                    safeguard your assets 24/7, all year round.
                  </p>
                  <h6>
                    <span>Multi-layered</span> Security
                  </h6>
                </div>
              </div>
              <div className="main_assets">
                <span className="border_left_top"></span>
                <span className="border_left_bottom"></span>
                <span className="border_right_bottom"></span>
                <span className="border_right_top"></span>
                <div className="img-sec">
                  <img src={marketplace_img} alt=""></img>
                </div>

                <div className="text_area">
                  <h4>World-Leading NFT Marketplace</h4>
                  <p>
                    Create your NFTs; discover, buy, and trade worldwide premium NFT
                    collections easily.
                  </p>
                  <h6>
                    <span>10,000,000 +</span> NFT
                  </h6>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Section2

