import React from "react";
import {
  AddCircleOutline,
  GetApp,
  Link,
  ManageAccounts,
  Article,
  Paid,
} from "@mui/icons-material";
// import { Link } from "react-router-dom";

type Data = [
  {
    titleNew: string;
    icon: any;
    link: string;
  },
  {
    titleNew: string;
    icon: any;
    link: string;
  }
];

type DataNew = [
  {
    titleNew: string;
    icon: any;
    link: string;
  },
  {
    titleNew: string;
    icon: any;
    link: string;
  },
  {
    titleNew: string;
    icon: any;
    link: string;
  }
];

export const SidebarData: Data = [
  {
    titleNew: "Getting an API Key",
    icon: <GetApp />,
    link: "/getting-api-key",
  },
  {
    titleNew: "Endpoint URLs",
    icon: <Link />,
    link: "/endpoint-url",
  },
];

export const SidebarDataNew: DataNew = [
  {
    titleNew: "Accounts",
    icon: <ManageAccounts />,
    link: "/accounts",
  },
  {
    titleNew: "Contracts",
    icon: <Article />,
    link: "/contracts",
  },
  {
    titleNew: "Transaction",
    icon: <Paid />,
    link: "/transaction",
  },
];
