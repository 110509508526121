import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { logo } from '../../config/config'
import img from "../../Assets/Image/login-img.png"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import CancelIcon from '@mui/icons-material/CancelRounded';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import { Phone } from '@mui/icons-material';
import { ClipLoader } from 'react-spinners'

const SignUp = () => {
    const { sponser } = useParams()

    const Navigate = useNavigate();
    const [btnDisable, setbtnDisable] = useState(false);

    const [showPassword1, setShowPassword1] = useState(false);
    const [password1, setpassword1] = useState('')

    const [email, setEmail] = useState("");
    const [referral, setReferral] = useState('');
    const [signUpPhone, setSignUpPhone] = useState('');
    const [OTP, setOTP] = useState('');


    const [showPassword2, setShowPassword2] = useState(false);
    const [password2, setpassword2] = useState('')


    const signUpSubmit = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();

        const signUp = {
            email: email,
            password: password1,
            passwordConfirm: password2,
            referal: referral ? referral : '100000' ,
            login_type: "email",
            otp: OTP
        }
        var signUPData = await _fetch(`${api_url}auth/signup`, 'POST', signUp, {});
        if (signUPData?.status === 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", signUPData.token);
            if (signUPData.status === 'success') {
                localStorage.setItem("user", JSON.stringify(signUPData?.data.user));
            }
            Navigate("/dashboard");
            toasted.success(signUPData?.message);
            setbtnDisable(false)
        }
        else {
            toasted.error(signUPData?.message);
            setbtnDisable(false)

        }
    }

    const signUpSubmitPhone = async (e: any) => {
        setbtnDisable(true)

        e.preventDefault();

        const signUp = {
            phone: signUpPhone,
            password: password1,
            passwordConfirm: password2,
            referal: referral ? referral : '100000',
            login_type: "phone",
            otp: OTP
        }
        const signUPData = await _fetch(`${api_url}auth/signup`, 'POST', signUp, {});
        // var signUPData = await _fetch(`${api_url}auth/sign-up`, 'POST', signUp, {});
        if (signUPData?.status === 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", signUPData.token);
            if (signUPData.status === 'success') {
                localStorage.setItem("user", JSON.stringify(signUPData?.data.user));
            }
            Navigate("/dashboard");
            toasted.success(signUPData?.message);
            setbtnDisable(false)

        }
        else {
            toasted.error(signUPData?.message);
            setbtnDisable(false)

        }
    }

    async function generateSignUPOTP(val: any, data: any) {
        var passedData: any = "";
        if (val === 'email') {
            passedData = `&sendTo=${data}&check=email`;
        }
        else if (val === 'phone') {
            passedData = `&sendTo=${data}&check=phone`;
        }

        var d = await _fetch(`${api_url}auth/generateAuthOTP?email_type=Register${passedData}`, 'GET', {}, {});
        if (d?.status === 'success') {
            toasted.success(d?.message);
        }
        else {
            localStorage.clear();
            toasted.error(d?.message);
        }
    }

    useEffect(() => {
        if (sponser != null) {
            setReferral(sponser);
        }
    }, [])


    return (
        <div>
            <div className="Login-sec only-margin">
                <div className="Stater-sec for_sign-up">
                    <div className="left">

                        <div className="inner">
                            <h1>Create Account</h1>
                            <Tabs>
                                <TabList>
                                    <Tab>Email</Tab>
                                    <Tab>Phone Number</Tab>
                                </TabList>

                                <TabPanel>
                                    <form className="form" onSubmit={signUpSubmit}>
                                        <div className="group">
                                            <label>Email</label>
                                            <div className='email-field-sec'>
                                                <input type="email" className="form-control inputt" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                                                <button className='send-button' type="button" onClick={() => { generateSignUPOTP('email', email) }}> Send OTP

                                                </button>
                                            </div>
                                        </div>
                                        <div className="group">
                                            <label>Enter OTP</label>
                                            <input type="number" className="form-control inputt" placeholder="Enter OTP" onChange={(e) => { setOTP(e.target.value) }} value={OTP} required />

                                        </div>

                                        <div className="group">
                                            <label>Password</label>
                                            <div className='group-inner'>
                                                <input type={showPassword1 ? "text" : "password"} className='form-control' placeholder='Password' name="Password" value={password1} onChange={(e: any) => { setpassword1(e.target.value) }} required />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword1(!showPassword1) }}>
                                                    {showPassword1 ?
                                                        <VisibilityTwoToneIcon />
                                                        :
                                                        <VisibilityOffTwoToneIcon />
                                                    }
                                                </button>
                                                {password1 !== '' && password1 !== undefined && <div className="cross" onClick={() => { setpassword1('') }}>
                                                    <CancelIcon />
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="group">
                                            <label>Confirm Password</label>
                                            <div className='group-inner'>
                                                <input type={showPassword2 ? "text" : "password"} className='form-control' placeholder='Confirm password' name="Password" value={password2} onChange={(e: any) => { setpassword2(e.target.value) }} required />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword2(!showPassword2) }}>
                                                    {showPassword2 ?
                                                        <VisibilityTwoToneIcon />
                                                        :
                                                        <VisibilityOffTwoToneIcon />
                                                    }
                                                </button>
                                                {password2 !== '' && password2 !== undefined && <div className="cross" onClick={() => { setpassword2('') }}>
                                                    <CancelIcon />
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="group">
                                            <label>Referral Code</label>
                                            <input type="text" className="form-control inputt" placeholder="Referral Code (optional)" onChange={(e) => { setReferral(e.target.value) }} value={referral} />
                                        </div>
                                        <Link to="/">Cannot receive SMS?</Link>
                                        <div className="d-flex align-items-center justify-content-center">

                                            <button type='submit' className='new_custom_button new_custom_button_add' disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ClipLoader color="#36d7b7" />

                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Sign Up ' : ''}
                                            </button>
                                        </div>
                                        <div className="text-center">
                                            <p>Already have an account? <Link to="/login">Login</Link></p>

                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel>
                                    <form className="form" onSubmit={signUpSubmitPhone}>
                                        <div className="group">
                                            <label>Phone Number</label>
                                            <div className='email-field-sec'>
                                                <PhoneInput
                                                    // inputStyle={{ }}
                                                    inputClass={"inputt-ph"}
                                                    containerStyle={{}}
                                                    searchClass="search-class"
                                                    disableSearchIcon={false}
                                                    enableTerritories
                                                    countryCodeEditable={true}
                                                    placeholder='Phone Number'
                                                    buttonStyle={{ width: '47px' }}
                                                    dropdownStyle={{ height: '150px' }}
                                                    country={"in"}
                                                    enableSearch={true}
                                                    onChange={(e) => { setSignUpPhone(e) }}
                                                // onChange={(newValue: any) => setsignupPhone(newValue)} 
                                                />
                                                <button type='button' className='send-button' onClick={() => { generateSignUPOTP('phone', signUpPhone) }}> Send OTP

                                                </button>
                                            </div>
                                        </div>

                                        <div className="group">
                                            <label>Enter OTP</label>
                                            <input type="number" className="form-control inputt" placeholder="Enter OTP" onChange={(e) => { setOTP(e.target.value) }} value={OTP} required />
                                        </div>

                                        <div className="group">
                                            <label>Password</label>
                                            <div className='group-inner'>
                                                <input type={showPassword1 ? "text" : "password"} className='form-control' placeholder='Password' name="Password" value={password1} onChange={(e: any) => { setpassword1(e.target.value) }} required />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword1(!showPassword1) }}>
                                                    {showPassword1 ?
                                                        <VisibilityTwoToneIcon />
                                                        :
                                                        <VisibilityOffTwoToneIcon />
                                                    }
                                                </button>
                                                {password1 !== '' && password1 !== undefined && <div className="cross" onClick={() => { setpassword1('') }}>
                                                    <CancelIcon />
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="group">
                                            <label>Confirm Password</label>
                                            <div className='group-inner'>
                                                <input type={showPassword2 ? "text" : "password"} className='form-control' placeholder='Confirm password' name="Password" value={password2} onChange={(e: any) => { setpassword2(e.target.value) }} required />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword2(!showPassword2) }}>
                                                    {showPassword2 ?
                                                        <VisibilityTwoToneIcon />
                                                        :
                                                        <VisibilityOffTwoToneIcon />
                                                    }
                                                </button>
                                                {password2 !== '' && password2 !== undefined && <div className="cross" onClick={() => { setpassword2('') }}>
                                                    <CancelIcon />
                                                </div>}

                                            </div>
                                        </div>
                                        <div className="group">
                                            <label>Referral Code</label>
                                            <input type="text" className="form-control inputt" placeholder="Referral Code (optional)" onChange={(e) => { setReferral(e.target.value) }} value={referral} />
                                        </div>
                                        <Link to="/">Cannot receive SMS?</Link>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button type='submit' className='new_custom_button new_custom_button_add' disabled={btnDisable}>

                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ClipLoader color="#36d7b7" />

                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Sign Up ' : ''}
                                            </button>
                                        </div>
                                        <div className="text-center">

                                            <p>Already have an account? <Link to="/login">Login</Link></p>
                                        </div>
                                    </form>
                                </TabPanel>
                            </Tabs>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SignUp

