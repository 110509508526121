import React from "react";
import { Table } from "react-bootstrap";
import { shortTitle } from "../../config/config";

const EndpointUrl = () => {
  return (
    <>
      <div className="main-sec-endpoint">
        <div className="coustom_container">
          <div className="inner-sec">
            <h2>Endpoint URLs</h2>
            <p>
              An API key generated on {shortTitle} can be used for both mainnet and
              testnet explorers
            </p>
            <p>
              Similarly, all endpoints and parameter formatting remain the same
              across testnet explorers, you are only required to change the
              relevant API endpoint URL as follows.
            </p>

            <div className="table-sec">
              <div className="table-sec-inner">
                <Table>
                  <thead>
                    <tr>
                      <th>Network</th>
                      <th>Endpoint Url</th>
                      <th className="no-bd">Documentation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mainnet</td>
                      <td>https://abcd</td>
                      <td className="no-bd">https://abcd</td>
                    </tr>
                    <tr>
                      <td>Mainnet</td>
                      <td>https://abcd</td>
                      <td className="no-bd">https://abcd</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EndpointUrl;
