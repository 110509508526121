import React, { useEffect } from "react";
import { title, titleDescription } from "../../config/config";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import banner from '../Assets/Images/bannerRight.png'
import privilegeImg from "../../Assets/Image/privilegeImg.png";
import { useLocation } from "react-router-dom";
// import privilegeImg2 from "../Assets/Images/Footer_IMG/privilegeImg2.png";
// import privilegeImg3 from "../Assets/Images/Footer_IMG/privilegeImg3.png";
// import privilegeImg4 from "../Assets/Images/Footer_IMG/privilegeImg4.png";
// import privilegeImg5 from "../Assets/Images/Footer_IMG/privilegeImg5.png";
// import privilegeImg6 from "../Assets/Images/Footer_IMG/privilegeImg6.png";


const VipIntroduce = () => {

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/vip-services') {
      document.body.setAttribute("vip", 'Vip-services');
    } else {
      document.body.removeAttribute("vip");

    }

    return () => {
      document.body.removeAttribute("vip");
    };
  }, [location]);


  return (
    <>
      <div className="b_container__1 only-margin">
        <section className="VIP__banner">
          <div className="coustom_container">
            <div className="card__banner__left">
              <span>{title}</span>
              <h1> VIP Services</h1>
              <h3>
                {title} provides a full range of exclusive privileges to VIP
                users.
              </h3>
            </div>
          </div>
        </section>

        <div className="VipIntroduce">
          <div className="coustom_container">
            {/* <div className="sec-sec">
              <div className="slider">
                <div className="VIP____container">
                  <VipIntroduceSlider />
                </div>
              </div>
            </div> */}
            <div className="thired_sec">
              <h1>VIP Trading Privileges</h1>
              <div className="cards">
                <div className="row">
                  <div className="col-4">
                    <div className="main-card">
                      <span className="border_left_top"></span>
                      <span className="border_left_bottom"></span>
                      <span className="border_right_bottom"></span>
                      <span className="border_right_top"></span>
                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive VIP Funding Rate</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="main-card">
                      <span className="border_left_top"></span>
                      <span className="border_left_bottom"></span>
                      <span className="border_right_bottom"></span>
                      <span className="border_right_top"></span>
                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive Account Manager</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="main-card">
                      <span className="border_left_top"></span>
                      <span className="border_left_bottom"></span>
                      <span className="border_right_bottom"></span>
                      <span className="border_right_top"></span>
                      <div className="top-img">
                        <img src={privilegeImg} alt="" />
                      </div>
                      <div className="info">
                        <p>Exclusive Green Channel to Deposit and Withdraw</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-sec">
              <div className="heading">
                <h1>Lower Transaction Fees and Higher Withdrawal Limits</h1>
              </div>
              <div className="tabs">
                <Tabs>
                  <div className="tabbuttons">
                    <TabList>
                      <Tab>Spot Trading</Tab>
                      <Tab>Futures Trading</Tab>
                      <Tab>Leverage Trading</Tab>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">
                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 3,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">
                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 3,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="main-head">
                      <div className="inner">
                        <div className="table-head">
                          <h3>VIP Tier</h3>
                          <h3>Requirements</h3>
                          <h3>24-Hour Withdrawal Limit</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 5</h3>
                          <h3>Assets (USDT) : 3,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                        <div className="table-data">
                          <h3>VIP 4</h3>
                          <h3>Assets (USDT) : 2,000,000</h3>
                          <h3>8,500,000USD</h3>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                </Tabs>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bottom-sec">
        <div className="VIP____container">
          <div className="row">
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar1} alt="" />
                </div>
                <div className="info">
                  <h2>Christina</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar2} alt="" />
                </div>
                <div className="info">
                  <h2>Ludovic Migneault</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="cards">
                <div className="top">
                  <img src={avatar3} alt="" />
                </div>
                <div className="info">
                  <h2>Gracelyn</h2>
                  <h4>VIP Ambassador</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default VipIntroduce;
