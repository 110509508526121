
import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import api_logo from '../../Assets/Image/api_logo.png';
import { logo, shortTitle } from '../../config/config';
import {SearchRounded} from '@mui/icons-material';

const ApiTrading = () => {
  return (
    
    <div className="api_tradingpage">
        <div className="coustom_container api_trading pt-5">

                <Tabs
            defaultActiveKey="spot"
            id="uncontrolled-tab-example"
            className="mb-3"
            >
                <Tab eventKey="spot" title="Spot">
                    <div className="spot_panel">
                        <div className="sidebar">
                                    <div className="searchbar">
                                    <div><SearchRounded className='searchicon'/></div>

                                    <div><input type="search" placeholder="Search Job" /></div>
                                    </div>

                                    <ul className="nav">
                                        <li className="nav-item">
                                        <a href="#" className="nav-link">  Welcome  </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#" className="nav-link">  Update log  </a>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">  WebSocket error codes  </a>
                                        </li>
                                    </ul>
                        </div>

                        <div className="welcome_text">
                    
                            <h4>Welcome</h4>

                            <p>Welcome to {shortTitle} ApiDoc! Click here for quick start</p>

                            <div className="ul_list">
                                <h5>Jan 30, 2023 </h5>
                                <ul>
                                    <li>/api/mix/v1/plan/placePlan add param: 'reduceOnly'</li>

                                    <li>/api/mix/v1/plan/modifyTPSLPlan param 'triggerPrice' is required</li>

                                    <li>/api/mix/v1/trace/modifyTPSL desc update on param 'stopProfitPrice' & 'stopLossPrice'</li>
                                </ul>
                            </div>
                
                        </div>

                        
                        <div className="console_txt">
                            <div className="console">
                                <p>console</p>
                            </div>

                            <div className="console_code">
                                <p>
                                    [- 
                                        "id": <span className="value">  "1e200836-a037-4475-825e-f202dd0b0e92" </span>,<br/>
                                        "Status": <span className="value">"ACCOUNT_STATUS_ACTIVE"</span>,<br/>
                                        "type": <span className="value">ACCOUNT_TYPE_WALLET"</span>,<br/>
                                        "currency": <span className="value">"898849.3300"</span>,<br/>

                                                "blocked": <span className="value">"4581.9510"</span>
                                        
                                    ]
                                </p>
                            </div>

                        </div>


                    </div>
                </Tab>

                {/* <Tab eventKey="futures" title="FuturesⓂ(USDT/Coin)">
                
                </Tab> */}
                </Tabs>

        </div>        
    </div>
    
   
  )
}

export default ApiTrading
