import React from 'react' 
import { PropagateLoader, ScaleLoader } from 'react-spinners'

const LoaderCom = (props:any) => {
    return (
        <tr className="d-value only-open-orders">
            
            {/* {props.condition != ' ' && */}
            {props.condition == '' &&
                    <td colSpan={8} className="color-ring-market" >
                        <div className='color__ring_data'>
                           <div className=''>
                                <PropagateLoader color="#66cce5" />
                                <span className='__txt'>{ props.text }</span>
                           </div>
                        </div>
                    </td>
                }
            </tr>
    )
}

export default LoaderCom