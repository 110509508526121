import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import  bonus from "../../Assets/Image/bonus1.png";
import  disc from "../../Assets/Image/discount.png";
import  bg2 from "../../Assets/Image/discBG.png";
import {WorkspacePremium} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { title } from "../../config/config";

const Bonus = () => {
  return (
    <>
      <div className="main-bonus">
        <div className="head-sec">
          <div className="coustom_container">
            <Tabs>
              <TabList>
                <Tab>Bonus Center</Tab>
                <Tab>Coupon Center</Tab>
              </TabList>

              <TabPanel>
                <div className="inner">
                    <div className="dl">
                    <h2>Welcome to Reward Center</h2>
                    <h3>Complete tasks to win up to 1,000 USDT</h3>
                    </div>
                    <div className="dr">
                        <img src={bonus} alt="bonus" />
                    </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="inner">
                <div className="dl">
                <h2>Coupon Center</h2>
                <h3>Receive more benefits</h3>
                </div>
                <div className="dr">
                    <img src={bonus} alt="bonus" />
                </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <div className="sign-up-sec">
            <div className="coustom_container">
                <div className="inner-sec">
                    <div className="sl">
                        <img src={disc} alt="disc" />
                    </div>
                    <div className="sr">
                        <h3>Sign up to get a Welcome Bonus up to $1,000</h3>
                        <p>Create an account here to enjoy a 10% rebate on your trading fee and massive rewards.</p>
                        <Link to ="/sign-up"><div className="btn2">Sign Up</div></Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="tab-sec">
        <div className="coustom_container">
            <Tabs>
              <TabList>
                <Tab>Beginner's Tasks</Tab>
                <Tab>Advanced Tasks</Tab>
              </TabList>

              <TabPanel>
                <div className="top-sec">
                    <div className="icn">
                        <WorkspacePremium />
                    </div>
                    <div className="head">
                        <h3>Trade</h3>
                    </div>
                </div>
                <div className="btm-sec">
                    <div className="sec-l">
                        <span className="top-txt">1000 seats offered</span>
                        <div className="head-sec2">
                            <div className="head2">
                            <div className="inner1">
                                <div className="inner2">
                                    <span className="txt-data">
                                        <small>$</small>
                                        <strong>2</strong>
                                    </span>
                                </div>
                                <span className="small-txt"> Futures Trial Fund </span>
                            </div>
                        </div>
                        </div>

                        <div className="body-txt">
                            <h3> Futures account first time trading...</h3>
                            <p>- It has to be your first trade of futures contracts.</p>
                        </div>
                        <div className="bar">
                            <div className="bar-clr"></div>
                            <div className="txt">0 / 1000</div>
                        </div>
                        <div className="exp-sec">
                            <div className="timer">
                                <div className="exp-txt"><p>Expiration</p></div>
                                <ul>
                                    <li className="day">0</li>
                                    <span className="colon">-</span>
                                    <li className="hour">15</li>
                                    <span className="colon">:</span>
                                    <li className="minute">08</li>
                                    <span className="colon">:</span>
                                    <li className="second">24</li>

                                </ul>
                            </div>
                            <div className="trd-btn">
                                <Link to="">
                                    <div>Trade Now</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="sec-l">
                        <span className="top-txt">1000 seats offered</span>
                        <div className="head-sec2">
                            <div className="head2">
                            <div className="inner1">
                                <div className="inner2">
                                    <span className="txt-data">
                                        <small>$</small>
                                        <strong>2</strong>
                                    </span>
                                </div>
                                <span className="small-txt"> Futures Trial Fund </span>
                            </div>
                        </div>
                        </div>

                        <div className="body-txt">
                            <h3> Futures account first time trading...</h3>
                            <p>- It has to be your first trade of futures contracts.</p>
                        </div>
                        <div className="bar">
                            <div className="bar-clr"></div>
                            <div className="txt">0 / 1000</div>
                        </div>
                        <div className="exp-sec">
                            <div className="timer">
                                <div className="exp-txt"><p>Expiration</p></div>
                                <ul>
                                    <li className="day">0</li>
                                    <span className="colon">-</span>
                                    <li className="hour">15</li>
                                    <span className="colon">:</span>
                                    <li className="minute">08</li>
                                    <span className="colon">:</span>
                                    <li className="second">24</li>

                                </ul>
                            </div>
                            <div className="trd-btn">
                                <Link to="">
                                    <div>Trade Now</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="top-sec">
                    <div className="icn">
                        <WorkspacePremium />
                    </div>
                    <div className="head">
                        <h3>Trade</h3>
                    </div>
                </div>
                <div className="btm-sec">
                    <div className="sec-l">
                        <span className="top-txt">1000 seats offered</span>
                        <div className="head-sec2">
                            <div className="head2">
                            <div className="inner1">
                                <div className="inner2">
                                    <span className="txt-data">
                                        <small>$</small>
                                        <strong>5</strong>
                                    </span>
                                </div>
                                <span className="small-txt"> Futures Trial Fund </span>
                            </div>
                        </div>
                        </div>

                        <div className="body-txt">
                            <h3> Futures account first time trading...</h3>
                            <p>- It has to be your first trade of futures contracts.</p>
                        </div>
                        <div className="bar">
                            <div className="bar-clr"></div>
                            <div className="txt">0 / 1000</div>
                        </div>
                        <div className="exp-sec">
                            <div className="timer">
                                <div className="exp-txt"><p>Expiration</p></div>
                                <ul>
                                    <li className="day">0</li>
                                    <span className="colon">-</span>
                                    <li className="hour">15</li>
                                    <span className="colon">:</span>
                                    <li className="minute">08</li>
                                    <span className="colon">:</span>
                                    <li className="second">24</li>

                                </ul>
                            </div>
                            <div className="trd-btn">
                                <Link to="">
                                    <div>Trade Now</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="sec-l">
                        <span className="top-txt">1000 seats offered</span>
                        <div className="head-sec2">
                            <div className="head2">
                            <div className="inner1">
                                <div className="inner2">
                                    <span className="txt-data">
                                        <small>$</small>
                                        <strong>5</strong>
                                    </span>
                                </div>
                                <span className="small-txt"> Futures Trial Fund </span>
                            </div>
                        </div>
                        </div>

                        <div className="body-txt">
                            <h3> Futures account first time trading...</h3>
                            <p>- It has to be your first trade of futures contracts.</p>
                        </div>
                        <div className="bar">
                            <div className="bar-clr"></div>
                            <div className="txt">0 / 1000</div>
                        </div>
                        <div className="exp-sec">
                            <div className="timer">
                                <div className="exp-txt"><p>Expiration</p></div>
                                <ul>
                                    <li className="day">0</li>
                                    <span className="colon">-</span>
                                    <li className="hour">15</li>
                                    <span className="colon">:</span>
                                    <li className="minute">08</li>
                                    <span className="colon">:</span>
                                    <li className="second">24</li>

                                </ul>
                            </div>
                            <div className="trd-btn">
                                <Link to="">
                                    <div>Trade Now</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <div className="qa-sec">
            
            <div className="coustom_container">

                <div className="head-sec-qa">
                    <h2>Q&A</h2>
                    <p>Frequently asked questions about the {title} Reward Center are as follows:</p>
                </div>

                <div className="body-sec">
                    <div className="ques">
                        <span className="q1">1. Where can I view the rewards I have received?</span>
                        <span>A: You can view the task rewards you have obtained in [Reward Center - My Rewards].</span>
                    </div>

                    <div className="ques">
                        <span className="q1">2. How do I use the Spot Coupons?</span>
                        <span>A: The spot coupons can be used to offset the fees for trading tokens and Margin transaction fee. The coupon will be refunded in the form of "first deducted then refunded" when the transaction fee is deducted.</span>
                    </div>

                    <div className="ques">
                        <span className="q1">3. How do I use the Margin Coupon?</span>
                        <span>A: When you turn on auto debit for margin trading, you can use the margin coupon amount to directly offset the amount of borrowed tokens, and the borrowed tokens will be exempted from the interest rate.</span>
                    </div>

                    <div className="ques">
                        <span className="q1">4. How does the Futures TrIal Fund work?</span>
                        <span>A: The purpose of the Futures TrIal Fund Voucher is to allow new users to experience {title}'s futures products in a real trading environment and without risk. After the voucher has been successfully redeemed and activated, the trial fund will be distributed to your futures account and you can use it for futures trading. Learn more.</span>
                    </div>

                </div>
            </div>
        </div>

        <div className="rules-sec">
            
            <div className="coustom_container">

                <div className="head-sec-qa">
                    <h2>Rules</h2>
                    <p>The rules of {title} Center Center are as follows:</p>
                </div>

                <div className="body-sec">
                    <div className="ques">
                        <span>1. 10% rebate of handling fee: "Users who register through the invitation code can enjoy a 10% rebate for Spot, Margin, and Futures transactions. The rebate is valid within 60 days from the registration of the {title} account, and the rebate will be issued to the spot account. Records can be viewed in [Activity-Wallet-Other], transaction rebate reward = net handling fee * rebate ratio"</span>
                    </div>

                    <div className="ques">
                        <span>2. Deposit task: Follow the task requirements to complete the specified amount of deposits (only on the chain deposit) to receive deposit rewards, rewards can be viewed in the [Reward Center - My Rewards].</span>
                    </div>

                    <div className="ques">
                        <span>3. Spot trading task: To earn spot trading rewards, you must fulfill the relevant spot trading tasks, with the exclusion of trading volume using spot coupons, exclude Maker transactions, and zero transaction fee trading pairs.</span>
                    </div>

                    <div className="ques">
                        <span>4. Futures task: Users need to register and open futures to receive the trial fund or coupon reward. The part of the transaction amount offset by using the futures trial fund is not counted in the futures trading task count.</span>
                    </div>

                    <div className="ques">
                        <span>5. The opening time and validity period of each task may be different, and the total amount of task rewards for trial fund and coupon is limited on a first-come-first-served basis;</span>
                    </div>

                    <div className="ques">
                        <span>6. Each task needs to be collected in the reward center before completion, in order to be effectively counted by the system. For tasks such as "cumulative futures account transfer" or "cumulative spot/margin/futures trading volume," there may be some delay in displaying the progress of task completion. For the task of "cumulative active futures trading," the display delay may be even longer;</span>
                    </div>

                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Bonus;
