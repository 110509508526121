import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { logo } from '../../config/config'
import img from "../../Assets/Image/login-img.png"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners'


const ForgotPass = () => {
    const [forgotmail, setforgotmail] = useState('')
    const [forgotphone, setforgotphone] = useState('')
    const [btnDisable, setbtnDisable] = useState(false);



    const forgotSubmit = async (event: any, e: any) => {
        event.preventDefault();
        setbtnDisable(true)
        var loginData: any;
        if (e === 'email') {
            loginData = {
                email: forgotmail,
                forget_type: 'email'
            }
        } else {
            loginData = {
                phone: forgotphone,
                forget_type: 'phone'

            }
        }
        var d = await _fetch(`${api_url}auth/forgotPassword`, 'POST', loginData, {});
        if (d?.status === 'success') {
            setbtnDisable(false)

            // localStorage.setItem("auth", 'true');
            // localStorage.setItem("accessToken", d?.token);
            // if (d.status == "success") {
            //     localStorage.setItem("user", JSON.stringify(d.data.user));
            // }
            // if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
            //     navigate("Dashboard/Kyc");

            // } else {
            //     navigate("/Dashboard");
            // }
            toasted.success(d?.message);
        } else {
            // localStorage.clear();
            toasted.error(d?.message);
            setbtnDisable(false)
        }

    }
    return (
        <div>
            <div className="Login-sec forgot_pASS only-margin">
                <div className="Stater-sec">
                    <div className="left">

                        <div className="inner">
                            <h1>Forgot Password</h1>
                            <Tabs>
                                <TabList>
                                    <Tab>Email</Tab>
                                    <Tab>Phone Number</Tab>
                                </TabList>

                                <TabPanel>
                                    <form className="form" onSubmit={(e: any) => { forgotSubmit(e, 'email') }}>
                                        <div className="group">
                                            <label>Email</label>
                                            <input type="email" className="form-control inputt" placeholder="Email" onChange={(e: any) => setforgotmail(e.target.value)} value={forgotmail} />
                                        </div>
                                        {/* <div className="group">
                                            <label>Enter OTP</label>
                                            <div className="d-flex">
                                                <input type="text" className="form-control inputt" placeholder="Verification Code" />
                                                <button type='button'>Send Code</button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex align-items-center justify-content-center">

                                            <button type='submit' className='new_custom_button new_custom_button_add' disabled={btnDisable} >
                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ClipLoader color="#36d7b7" />

                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Submit ' : ''}
                                            </button>
                                        </div>

                                    </form>
                                </TabPanel>
                                <TabPanel>
                                    <form className="form" onSubmit={(e: any) => { forgotSubmit(e, 'phone') }}>
                                        <div className="group">
                                            <label>Phone Number</label>
                                            {/* <input type="number" className="form-control inputt" placeholder="Phone" onChange={(e: any) => { setforgotphone(e.target.value) }} value={forgotphone} /> */}
                                            <PhoneInput
                                                // inputStyle={{ }}
                                                inputClass={"inputt-ph"}
                                                containerStyle={{}}
                                                searchClass="search-class"
                                                disableSearchIcon={false}
                                                enableTerritories
                                                countryCodeEditable={true}
                                                placeholder='Phone Number'
                                                buttonStyle={{ width: '47px' }}
                                                dropdownStyle={{ height: '150px' }}
                                                country={"in"}
                                                enableSearch={true}

                                            // onChange={(newValue: any) => setsignupPhone(newValue)} 
                                            />
                                        </div>
                                        {/* <div className="group">
                                            <label>Enter OTP</label>
                                            <div className="d-flex">
                                                <input type="text" className="form-control inputt" placeholder="Verification Code" />
                                                <button type='button'>Send Code</button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button type='submit' className='new_custom_button new_custom_button_add' disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ClipLoader color="#36d7b7" />

                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Submit ' : ''}
                                            </button>

                                        </div>
                                    </form>
                                </TabPanel>

                            </Tabs>

                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ForgotPass

