import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import strategy_banner_main from "../../Assets/Image/Footer_IMG/statergy.png"
import strategy_start_1 from "../../Assets/Image/Footer_IMG/coins-statergy.png"
import tether from "../../Assets/Image/Footer_IMG/trone.png"
import avtar from "../../Assets/Image/Footer_IMG/user_in_table.jpeg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import newIMG from '../../Assets/Image/new_img.png';



const StartagyTrading = () => {

  return (
    <>
    <div className='max__contain  only-margin'>
       <div className='strategy__banner '>
            <div className='row coustom_container strategy_row'>
                <div className='col-lg-6 strategy__banner_left'>
                    <h1>Copy Trading</h1>
                    <ul>
                        <li> Subscribe to a strategist or buy a strategy from the pros.</li>
                        <li> Automatically mirror expert trades 24 hours a day, 7 days a week.</li>
                        <li> Stay cool and composed during market volatility.</li>
                    </ul>
                </div>
                <div className='col-lg-6 strategy__banner_right'>
                    <img src={newIMG} alt="strategy-1/icon" width="100%" height="400px" />
                </div>
            </div>
       </div>

       <div className='strategy__start coustom_container'>
            <h2 className='mb-0 pb-4' >Not sure where to start?</h2>
            <div className='row'>
                <div className='col-lg-6 col-sm-6'>
                    <div className='card'>
                        <img src={strategy_start_1} alt="img__strategy"/>
                        <div className='inner'>
                            <h4>I need someone to lead the way.</h4>
                            <p>Subscribe to a strategist and mirror their trades for 30 days. Cancel anytime.</p>
                            <Link to="/" className='btn btn-success'> Find a strategist </Link>
                        </div>

                    </div>
                </div>

                <div className='col-lg-6 col-sm-6'>
                    <div className='card'>
                        <img src={strategy_start_1} alt="img__strategy"/>
                        <div className='inner'>
                            <h4>I need an expert strategy but I want to manage it myself.</h4>
                            <p>Purchase a strategy and use it as many times as you want, when you want.</p>
                            <Link to="/" className='btn btn-success'> Find a strategist </Link>
                        </div>

                    </div>
                </div>
            </div>
            
       </div>


       <div className='strategy__market coustom_container'>
          <Tabs>
            <div className='strategy__tabs'>
                <TabList>
                    <Tab>Strategies</Tab>
                    <Tab>Strategists</Tab>
                </TabList>
            </div>

             <div className='strategy__market_body'>
                <TabPanel>
                       <div className='strategy__market_1'>
                            <table>
                                <thead>
                                    <tr>
                                        <th> Token</th>
                                        <th> P&L</th>
                                        <th> Strategy creator</th>
                                        <th> Runtime</th>
                                        <th> Times Sold</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='icon__img'> 
                                            <div className='icon__img__WWE'>
                                                <div className='icon__img__img'>
                                                    <img src={tether} alt="icon"/>
                                                </div>
                                                <div className='icon__title'>
                                                    <h5>SOLUSDT</h5>
                                                    <p>Futures Grid-Long</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>$14,174.32</p>
                                            <p>+2,834.86%</p>
                                        </td>
                                        <td className='avtar__strategy'>
                                            <img src={avtar} alt="avtar_img"/>
                                        </td>
                                        <td>
                                            <p>37D 21H 20M</p>
                                        </td>
                                        <td>
                                            <p>444</p>
                                        </td>
                                        <td className='btn__strategy'>
                                            <div className='btn__tds'>
                                                <button className='btn' type='button'> Copy - 20 USDT</button>
                                            </div>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                       </div>
                </TabPanel>

                <TabPanel>
                       <div className='strategy__market_1'>
                            <table>
                                <thead>
                                    <tr>
                                        <th> Token</th>
                                        <th> P&L</th>
                                        <th> Strategy creator</th>
                                        <th> Runtime</th>
                                        <th> Times Sold</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='icon__img'> 
                                            <div className='icon__img__WWE'>
                                                <div className='icon__img__img'>
                                                    <img src={tether} alt="icon"/>
                                                </div>
                                                <div className='icon__title'>
                                                    <h5>SOLUSDT</h5>
                                                    <p>Futures Grid-Long</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>$14,174.32</p>
                                            <p>+2,834.86%</p>
                                        </td>
                                        <td className='avtar__strategy'>
                                            <img src={avtar} alt="avtar_img"/>
                                        </td>
                                        <td>
                                            <p>37D 21H 20M</p>
                                        </td>
                                        <td>
                                            <p>444</p>
                                        </td>
                                        <td className='btn__strategy'>
                                            <div className='btn__tds'>
                                                <button className='btn' type='button'> Copy - 20 USDT</button>
                                            </div>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                       </div>
                </TabPanel>

             </div>
         </Tabs>



       </div>

    </div>

    </>
  )
}

export default StartagyTrading

