import React from "react";
import { useEffect } from "react";
// import banner__LI from "../Assets/Images/Footer_IMG/compliance-banner.png"
import license__sponsar_1 from "../../Assets/Image/Footer_IMG/footer.card.msb.png";
import license__sponsar_2 from "../../Assets/Image/Footer_IMG/footer.card.msb.png";

import { Link } from "react-router-dom";



const ComplianceLicense = () => {





  return (
    <>
      <div className="License___sec only-margin">
        <div className="max__contain_license">
          <section className="IIner__banner coustom_container">
            <div className="IIner____container">
              <div className="card__banner__left">
                <h1>Compliance and Accountability</h1>
                <p>
                  {" "}
                  We are committed to protecting your assets, and we take this
                  responsibility seriously. Our values guide us to make
                  decisions that meet our community's needs and have a lasting
                  impact on society.
                </p>
                <p>
                  {" "}
                  Our operations are conducted competently and transparently,
                  and are ever-evolving to meet global compliance standards in
                  cryptocurrency. We work with affiliates to ensure our
                  information is accurate, and our platform is secure. We offer
                  services and products to our users that reflect our
                  industry-leading security and credibility.
                </p>

              </div>
            </div>
          </section>

          <div className="license__sponsar coustom_container">
            <div className="IIner____container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card_img_le">
                      <img src={license__sponsar_1} alt="sponsar_img" />
                    </div>
                    <div className="card-itmes">
                      <h2> U.S. MSB License</h2>
                      <p>
                        Regulatory Authority: The Financial Crimes Enforcement
                        Network (FinCEN), U.S. Department of the Treasury
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="card">
                    <div className="card_img_le">
                      <img src={license__sponsar_2} alt="sponsar_img" />
                    </div>
                    <div className="card-itmes">
                      <h2> Canada MSB Licence</h2>
                      <p>
                        Regulatory Authority: Financial Transactions and Reports
                        Analysis Centre of Canada
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-main coustom_container">
          <div className="faq-inner">
            <div className="faq-sec">
              <h3>FAQs</h3>
              <div className="inner-sec">
                <div className="question">
                  <h4> Compliance Management</h4>
                  <p>Our dedicated team of experts ensures that we follow strict compliance standards and protocols. Our team follows a comprehensive compliance management framework, which is overseen by senior management, to identify, assess, execute and evaluate effectively.</p>
                </div>
                <div className="question">
                  <h4> eKYC</h4>
                  <p>We use extensive eKYC procedures to safeguard our platform and mitigate any potential risk from fraud and illicit behavior. We have established proper sanctions and anti-money laundering policies to ensure we comply with all relevant jurisdictions and prevent counterfeiting or other criminal activities on the platform.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ComplianceLicense;
