import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import { Link, useParams } from 'react-router-dom';
import { ContentCopy, OpenWith, Cancel } from "@mui/icons-material";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import dateFormat, { masks } from "dateformat";
import LoaderCom from '../../config/LoaderCom';
import { ClipLoader } from 'react-spinners'

const OrderStatus = (props: any) => {

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [cancel, setcancel] = useState(false);
    const onCloseCModal = () => setcancel(false);
    const [openOrders, setopenOrders] = useState([])
    const [emptyData, setemptyData] = useState([]);
    const [CompleteemptyData, setCompleteemptyData] = useState([]);
    const [btnDisable, setbtnDisable] = useState(false);

    async function OpenOrders() {
        let data = await _fetch(`${api_url}trade/getMyBuySellHistory?order_status=pending&trade_on=${id?.toLowerCase()}`, 'GET', {}, {});
        if (data?.status === 'success') {
            setopenOrders(data?.data?.data);
            setemptyData(data)
        }
    }
    const [completedOrders, setCompletedOrders] = useState([])

    async function CompletedOrders() {
        let data = await _fetch(`${api_url}trade/getMyBuySellHistory?order_status=completed&order_status=cancelled&trade_on=${id?.toLowerCase()}`, 'GET', {}, {});
        if (data?.status === 'success') {
            setCompletedOrders(data?.data?.data);
            setCompleteemptyData(data);
        }
    }

    const [openMultiDataModal, setopenMultiDataModal] = useState({ amount: 0, buy_currency: '', coin: 0, coin_id: '', coin_price: 0, createdAt: 0, market_trade_type: '', order_id: '', order_status: '', order_type: { buy: '', sell: '' }, pending_coin: '', receive_coin: '', sell_currency: '', trade_type: '', updatedAt: '', username: '' });
    const [totalPriceTable, settotalPriceTable] = useState(0);
    const [totalAmountTable, settotalAmountTable] = useState(0);
    const [orderMoreData, setOrderMoreData] = useState([{
        buyerReceive: '',
        buyer_currency: '',
        buyer_orderId: '',
        buyer_username: '',
        coin: 0,
        coin_id: '',
        createdAt: '',
        current_price: 0,
        sellerReceive: '',
        seller_currency: '',
        seller_orderId: '',
        seller_username: '',
        status: '',
        trade_on: '',
        updatedAt: '',
        _id: '',
    }]);

    async function tradeMoreData(status: any, user: any) {
        // console.log('status', status, user)
        if (status === 'buy') {
            var status_id = 'buyer_orderId';
        } else {
            var status_id = 'seller_orderId';

        }

        var moreData = await _fetch(`${api_url}trade/getMyOrderHistory?sort=createdAt&${status_id}=${user}`, 'get', {}, {});
        if (moreData?.status === 'success') {
            settotalPriceTable(moreData?.price)
            settotalAmountTable(moreData?.amount)
            setOrderMoreData(moreData.data.data);
            // console.log('moreData', moreData.data.data);
        }
    }
    const [cancelOrder, setCancelOrder] = useState('')


    async function CancelOrder() {
        setbtnDisable(true);
        let data = await _fetch(`${api_url}trade/cancel-trade`, 'POST', { id: cancelOrder }, {});
        if (data?.status === 'success') {
            OpenOrders()
            CompletedOrders()
            setOpenModal3(false)
            toasted.success(data?.message);
            setbtnDisable(false);

            // window.location.reload()

        } else {
            setbtnDisable(false);
            toasted.error(data?.message);
        }
    }

    useEffect(() => {
        CompletedOrders();
        OpenOrders();
    }, [props.LoadApi])

    // modal

    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);

    const onOpenlangModal = (e: any) => {
        setOpenModal(true)
        setopenMultiDataModal({ amount: e.amount, buy_currency: e.buy_currency, coin: e.coin, coin_id: e.coin_id, coin_price: e.coin_price, createdAt: e.createdAt, market_trade_type: e.market_trade_type, order_id: e.order_id, order_status: e.order_status, order_type: { buy: e.order_type.buy, sell: e.order_type.sell }, pending_coin: e.pending_coin, receive_coin: e.receive_coin, sell_currency: e.sell_currency, trade_type: e.trade_type, updatedAt: e.updatedAt, username: e.username })
        tradeMoreData(e.market_trade_type, e.order_id);
    };
    const onCloselangModal = () => setOpenModal(false);

    const onOpenlangModal2 = () => setOpenModal2(true);
    const onCloselangModal2 = () => setOpenModal2(false);

    const onOpenlangModal3 = (orderId: any) => {
        setOpenModal3(true)
        setCancelOrder(orderId)
    };

    const onCloselangModal3 = () => setOpenModal3(false);

    return (
        <>
            <div className='main-order-status'>
                <div className="inner-order">

                    <Tabs>
                        <TabList>
                            <Tab>Open Orders</Tab>
                            <Tab>Completed Orders</Tab>
                            {/* <Tab>Trade History</Tab> */}
                        </TabList>

                        <TabPanel>
                            <div className='head1'>
                                <div className='inner'>
                                    <div className='table-data'>
                                        <table className='table-striped'>
                                            <thead>
                                                <th>Pair</th>
                                                <th>Amount</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                                {/* <th>Date</th> */}
                                                <th>More</th>
                                            </thead>

                                            <tbody>
                                                <tr className="border-0 bg-transparent">
                                                    <td colSpan={6} className="__loginData">
                                                        <div className='btn-groups'>
                                                            <Link to="/login" className='btn btn-primary sign_up__button'> Login </Link> or <Link to="/Sign-up" className='btn btn-primary sign_up__button'> Sign Up </Link> to trade
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* <div className='no-data'>
                                            <p>No Data Available</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='head1'>
                                <div className='inner'>

                                    <div className='table-data'>
                                        <table className='table-striped'>
                                            <thead>
                                                <th>Pair</th>
                                                <th className=''>Amount</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                                {/* <th>Date</th> */}
                                                {/* <th>Status</th> */}

                                                <th className='text-end'> More</th>
                                            </thead>
                                            <tbody>
                                                <tr className="border-0 bg-transparent">
                                                    <td colSpan={6} className="__loginData">
                                                        <div className='btn-groups'>
                                                            <Link to="/login" className='btn btn-primary sign_up__button'> Login </Link> or <Link to="/Sign-up" className='btn btn-primary sign_up__button'> Sign Up </Link> to trade
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                    </Tabs>
                </div>

            </div>
            {/* modal */}
            <Modal open={openModal} onClose={onCloselangModal} classNames={{
                modal: "modal_sec_order_status"
            }} center>
                <div className="inner-modal">
                    <div className='head'><h3>Order Details</h3></div>
                    <div className='data'>
                        <div className='data_items'>{openMultiDataModal?.sell_currency?.toUpperCase()}</div>
                        <div className='data_items'>{openMultiDataModal?.market_trade_type?.toUpperCase()}</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Type</div>
                        <div className='data_items'>{openMultiDataModal?.trade_type?.toUpperCase()}</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Placed on</div>
                        <div className='data_items'>{dateFormat(openMultiDataModal?.createdAt, "IST:d mmm , yyyy, h:MM:ss TT").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '')}</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Coin</div>
                        <div className='data_items'>{openMultiDataModal.coin.toFixed(3)} {openMultiDataModal?.sell_currency?.toUpperCase()}</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>At Price</div>
                        <div className='data_items'>${totalPriceTable}</div>
                    </div>

                    {openMultiDataModal?.trade_type.toUpperCase() === "LIMIT" &&
                        <div className='data'>
                            <div className='data_items'>Stop Price</div>
                            <div className='data_items'>${openMultiDataModal?.coin_price?.toFixed(3)} </div>
                        </div>}
                    <div className='data'>
                        <div className='data_items'>Total</div>
                        <div className='data_items'>{totalAmountTable?.toFixed(3)} USDT</div>
                    </div>
                    <div className='data_b'>
                        <div className='data_items'>Fee</div>
                        <div className='data_items'>N/A</div>
                    </div>
                    <div className='data_c'>
                        <div className='data_items'>TDS</div>
                        <div className='data_items'>N/A</div>
                    </div>

                    <div className='table-data'>
                        <table className=''>
                            <thead>
                                <tr>
                                    <th>Coin</th>
                                    <th>Seller ID</th>
                                    <th>Buyer ID</th>
                                    <th>Coin</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderMoreData?.map((val: any) => {
                                        // console.log('orderMoreData',orderMoreData);
                                        // var add = totalAddPriceLoop + val.current_price;
                                        // () => settotalAddPriceLoop(add);
                                        return (
                                            <tr>
                                                <td>{val.trade_on.toUpperCase()}</td>
                                                <td>{val.seller_orderId}</td>
                                                <td>{val.buyer_orderId}</td>
                                                <td>{val?.coin?.toFixed(3)}</td>
                                                <td>{val?.current_price?.toFixed(3)}</td>
                                                <td>{val.sellerReceive + ' ' + val?.seller_currency?.toUpperCase()}</td>

                                                <td className={val.status === "completed" ? 'text-success' : 'text-danger'}>{val.status.toUpperCase()}</td>
                                                <td>{dateFormat(val?.createdAt, "IST:d mmm , yyyy, h:MM:ss TT").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '').replace('IrdP:', '')}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

            {/* Modal 2 */}
            <Modal open={openModal2} onClose={onCloselangModal2} classNames={{
                modal: "modal_sec_order_status"
            }} center>
                <div className="inner-modal">
                    <div className='head'><h3>Order Details 2</h3></div>
                    <div className='data'>
                        <div className='data_items'>USDT</div>
                        <div className='data_items'>BUY</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Type</div>
                        <div className='data_items'>Market</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Placed on</div>
                        <div className='data_items'>26 May , 2023, 11:15:58 AM</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Coin</div>
                        <div className='data_items'>1.000 USDT</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>At Price</div>
                        <div className='data_items'>$6</div>
                    </div>
                    <div className='data'>
                        <div className='data_items'>Total</div>
                        <div className='data_items'>18.000 USDT</div>
                    </div>
                    <div className='data_b'>
                        <div className='data_items'>Fee</div>
                        <div className='data_items'>N/A</div>
                    </div>
                    <div className='data_c'>
                        <div className='data_items'>TDS</div>
                        <div className='data_items'>N/A</div>
                    </div>

                    <div className='table-data'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Coin</th>
                                    <th>Seller ID</th>
                                    <th>Buyer ID</th>
                                    <th>Coin</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>MIC</td>
                                    <td>01426346</td>
                                    <td>72241035</td>
                                    <td>1.000</td>
                                    <td>6.000</td>
                                    <td>6 USDT</td>
                                    <td className='grn'>COMPLETED</td>
                                    <td>26 May , 2023, 11:15:58 AM</td>
                                </tr>
                                <tr>
                                    <td>MIC</td>
                                    <td>01426346</td>
                                    <td>72241035</td>
                                    <td>1.000</td>
                                    <td>6.000</td>
                                    <td>6 USDT</td>
                                    <td className='grn'>COMPLETED</td>
                                    <td>26 May , 2023, 11:15:58 AM</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

            {/* Modal 3 */}
            <Modal open={openModal3} onClose={onCloselangModal3} classNames={{
                modal: "modal_sec_order_status2"
            }} center>
                <div className="inner-modal">
                    <div className='head'><h3>Are You Sure</h3></div>
                    <div className='data text-center d-flex justify-content-center align-items-center gap-2 p-4'>
                        <button type="button" className='btn btn-danger data_items' style={{ padding: '5px 25px' }} onClick={CancelOrder} disabled={btnDisable}>
                            {btnDisable === true &&
                                <div className="color-ring">
                                    <ClipLoader color="#36d7b7" />

                                    Please Wait...
                                </div>
                            }
                            {btnDisable === false ? 'Yes' : ''}  </button>
                        <button type="button" className='btn btn-success data_items' style={{ padding: '5px 25px' }} onClick={() => setOpenModal3(false)}>NO</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default OrderStatus;