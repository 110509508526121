import React, { useEffect, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import icon1 from '../../Assets/Icon/icon2.png';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { api_url } from "../../config/config";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import P2pTab from "./P2pTab";
import FaitDeposit from "./FaitDeposit";
import phonepe from "../../Assets/Image/phonepe.png";
import g_pay from "../../Assets/Image/g_pay.png";
import wallet from "../../Assets/Image/wallet.png";
import upi from "../../Assets/Image/upi.png";
import { Link, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { AccountTree, AddBusiness, AdminPanelSettings, DashboardCustomize, KeyboardArrowDown, MarkAsUnread, People, PostAdd } from "@mui/icons-material";
import AddPost from "./AddPost";
import MyAdsPost from "./MyAdsPost";


const P2p = () => {

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open1, setOpen1] = useState(false);
  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);


  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])
  const [coinBalance, setcoinBalance] = useState()
  const [payment_methods, setpayment_methods] = useState();

  const [InrPrice, setInrPrice] = useState(87)
  const [BuyMethodView, setBuyMethodView] = useState('Payment Options')
  const [BuyMethodImage, setBuyMethodImage] = useState(icon1)

  async function activityLog() {
    let d = await _fetch(`${api_url}markets/getAllElpMarketbyType?type=funding_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }


  //////////////////////////////////////sell/////////////////////////////////////////////////////
  const [sellQuantity, setsellQuantity] = useState(0)
  const [sellPrice, setsellPrice] = useState(0);


  const [selectedOption, setSelectedOption] = useState(null);
  const [sellChainId, setsellChainId] = useState(null);
  const [sellMinLimit, setsellMinLimit] = useState(null);
  const [PassbtnDisable, setPassbtnDisable] = useState(false);

  const handleChange = (e) => {
    var total_coin = e?.total_coin?.toFixed(2);
    // console.log('sad', total_coin);
    setcoinBalance(total_coin);
    var newPrice = InrPrice * e?.current_price;
    setsellPrice(newPrice)
    setsellChainId(e.coinID)
    setSelectedOption(e);
    setSelectedBuyOption(e)

  }

  const sellSubmit = async (e) => {
    setPassbtnDisable(true);
    e.preventDefault();
    var data = {
      "amount": sellQuantity,
      "price": sellPrice,
      "coinID": sellChainId,
      "currency": selectedOption.value,
      "payment_method": payment_methods,
      "min_limit": sellMinLimit,
    }

    var d = await _fetch(`${api_url}trade/p2p-create-sell-trade`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
      //  setOpenQR(false);
      setPassbtnDisable(false)

    } else {
      setPassbtnDisable(false)

      toasted.error(d.message);

    }
  }

  ///////////////////////////CLOSE SELL /////////////////

  const [allAccountdetail, setallAccountdetail] = useState([])
  async function getAccountDetails() {
    let d = await _fetch(`${api_url}payments/paymentDetails`, 'GET', {}, {});
    if (d?.status === 'success') {
      setallAccountdetail(d.data.data)
    }
  }
  const location = useLocation()
  const { state } = location
  const [formTab, setformTab] = useState(0)

  useEffect(() => {
    // document.title = props.title ;

    activityLog()
    getAccountDetails();
    if (state?.active === true) {
      setformTab(1)
    }
  }, [payment_methods])



  const [buyPrice, setbuyPrice] = useState(0);
  const [buyQuantity, setbuyQuantity] = useState(0);
  const [selectedBuyOption, setSelectedBuyOption] = useState(null);
  const [buyChain, setbuyChain] = useState(null);
  const [getPeymentMethod, setgetPeymentMethod] = useState(null);
  const [searchType, setsearchType] = useState(false);
  const [ActiveTab, setActiveTab] = useState(0);

  // const [selectedOption, setSelectedOption] = useState(null);

  // const handleChange = (e) => {
  //   setSelectedOption(e);

  // }
  var serchBuyData = {
    "searchType": searchType,
    "amount": buyQuantity,
    "price": buyPrice,
    "coinID": buyChain,
    "currency": selectedBuyOption?.value,
    'search': `&search=payment_method_type&value=${getPeymentMethod}`,
    // &sell_currency=${selectedBuyOption?.value}
    "tab": '0',

  }
  ////////////////////////////////select multiple method ////////////////

  const addMethod = (val) => {

    if (payment_methods == undefined) {
      setpayment_methods([val]);
    } else {
      var check = payment_methods.includes(val);
      if (check != true) {
        payment_methods.push(val);
        // console.log(payment_methods)

      } else {
        var removeIndex = payment_methods.indexOf(val);
        //remove  array
        payment_methods.splice(removeIndex, 1); // 

      }
      // setpayment_methods([...payment_methods,val])
    }

  }


  const [isClassAdded, setIsClassAdded] = useState(false);

  const subtoggle = () => {
    setIsClassAdded(!isClassAdded);
  };
  



  return (
    <>

      <section className='section-main-p2p'>
        <div className='__conta'>
          <div className="tab-top">
            <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>

              <div className="__container">

                <div className="__subHeader">
                  <h4> More Service</h4>
                  <button type="button" onClick={subtoggle} className="btn"> <KeyboardArrowDown /> </button>
                </div>

                <div className={isClassAdded ? '__tabLeft showHeader' : ' __tabLeft'}>
                  <div className="tab-header mb-3">
                    <Link to="/dashboard" className="react-tabs__tab"> <DashboardCustomize /> Overview </Link>
                    <Link to="/profile" className="react-tabs__tab"> <AdminPanelSettings /> Profile </Link>

                  </div>

                  <div className="tab-header-fix">
                    <TabList>
                      <Link to="/dashboard" className="react-tabs__tab overView"> <DashboardCustomize /> Overview </Link>
                      <Tab><People /> One click Buy</Tab>
                      <Tab> <AccountTree /> P2P</Tab>
                      <Tab> <PostAdd /> Addpost</Tab>
                      {/* <Tab> <MarkAsUnread /> My adspost</Tab> */}
                    </TabList>
                  </div>



                </div>


                <div className="__tabsdata">
                  <TabPanel>
                    <div className='one-click-div'>
                      <Tabs selectedIndex={formTab} onSelect={(index) => setformTab(index)}>


                        <div className={`inner-crum ${formTab === 0 ? 'buy-tabs' : 'sell-tabs'}`}>
                          <div className="__links">
                            <h3> {formTab === 0 ? 'One click Buy' : 'One click Sell'}</h3>

                            <TabList>
                              <Tab>Buy </Tab>
                              <Tab>Sell</Tab>
                            </TabList>
                          </div>

                        </div>



                        <TabPanel>
                          <div className='buy-inner buy-sell-comman'>
                            <form>

                              <div className='form-group price-input '>
                                <label>Price</label>
                                <input type='text' placeholder='Price' className='form-control mt-1 coinInput'
                                  onChange={(e) => setbuyPrice(e.target.value)}
                                  value={buyPrice}
                                />
                                <div className="dropdown tab-dropdown">


                                  <Select
                                    placeholder="Coins"
                                    value={selectedOption}
                                    className="select-option-s"
                                    options={elpMarket}
                                    onChange={handleChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />

                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <li><span className="dropdown-item"><img src={icon1} />MET</span></li>
                                  </ul>
                                </div>
                              </div>
                              <div className='form-group mt-3 quantity'>
                                <label>Quantity</label>
                                <input type='number' placeholder='QUANTITY' className='form-control mt-1'
                                  onChange={(e) => { setbuyQuantity(e.target.value) }}
                                  value={buyQuantity}
                                />
                              </div>

                              <div className='form-group payment-opt mt-3'>
                                <label>Payment Methods</label><br />
                                <span onClick={onOpenModal} className="b-btn form-control mt-1">
                                  <img src={BuyMethodImage} />{BuyMethodView}
                                </span>
                              </div>

                              <div className='form-group total-opt mt-3'>
                                <label>Total </label><br />
                                <span className="b-btn form-control mt-1">
                                  {buyPrice * buyQuantity}
                                </span>
                              </div>



                              <div className='form-group mt-4 d-flex align-items-center justify-content-center'>
                                <button type="button" className="sub-btn --buyTab" onClick={() => { setActiveTab(1); setsearchType(true) }}>Buy</button>
                              </div>

                            </form>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className='sell-inner buy-sell-comman'>
                            <form onSubmit={sellSubmit}>
                              <h4 className="title">Balance: {coinBalance}</h4>
                              <div className='form-group price-input'>
                                <label>Price</label>
                                <input type='text' placeholder='Price' className='form-control mt-1 coinInput'
                                  onChange={(e) => setsellPrice(e.target.value)}
                                  value={sellPrice}
                                />
                                <div className="dropdown tab-dropdown">
                                  <Select
                                    placeholder="Coins"
                                    value={selectedOption}
                                    className="select-option-s"
                                    options={elpMarket}
                                    onChange={handleChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className='form-group mt-3 quantity'>
                                <label>Quantity</label>
                                <input type='number' placeholder='Quantity' className='form-control mt-1'
                                  onChange={(e) => { setsellQuantity(e.target.value) }}
                                  value={sellQuantity} />
                                <button type="button" className="btn max-btn" onClick={() => setsellQuantity(coinBalance)}>
                                  MAX
                                </button>

                              </div>
                              <div className='form-group mt-3'>
                                <label>Minimum limit</label>
                                <input type='number' placeholder='Minimum limit' className='form-control mt-1'
                                  onChange={(e) => setsellMinLimit(e.target.value)}
                                  value={sellMinLimit}
                                />
                              </div>

                              <div className='form-group total-opt mt-3'>
                                <label>Total </label><br />
                                <span className="b-btn form-control mt-1">
                                  {sellPrice * sellQuantity}
                                </span>
                              </div>


                              <div className='form-group payment-opt mt-3'>
                                <label>Payment Methods</label><br />
                                <span onClick={onOpenModal1} className="b-btn form-control mt-1">
                                  <img src={icon1} /><spam>Payment</spam>
                                </span>
                              </div>

                              <div className='form-group mt-4 d-flex align-items-center justify-content-center'>
                                <button type="submit" className="sub-btn --sellTab" disabled={PassbtnDisable}>
                                  {PassbtnDisable === true &&
                                    <div className="color-ring">
                                      <ClipLoader color="#36d7b7" />

                                      Please Wait...
                                    </div>
                                  }
                                  {PassbtnDisable === false ? 'Sell' : ''}
                                </button>
                              </div>

                            </form>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </TabPanel>


                  <TabPanel>
                    <P2pTab props={serchBuyData} />
                  </TabPanel>

                  <TabPanel>
                    <div className="__addpost">
                      <AddPost />
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="__addpost">
                      <MyAdsPost />
                    </div>
                  </TabPanel>
                </div>


              </div>





            </Tabs>
          </div>
        </div>
      </section>


      <Modal open={open} onClose={onCloseModal} center
        classNames={{
          modal: "New_modal_p2p",
        }}>

        <div className="main-head">
          <h5>Select Payment Method</h5>
        </div>


        <div className="recm-sec">
          <p>Recommended</p>
          <p className="disc">P2P Match With Sellers in Just One Click</p>

          <div className="cur-sec">
            <div className="__payment">
              <div className="g_pay" onClick={() => { setBuyMethodImage(g_pay); setgetPeymentMethod('gpay'); setOpen(false); setBuyMethodView('Google Pay') }}>
                <div className="icn_cur">
                  <img src={g_pay} alt="coin" />
                </div>
                <div className="cur_title">
                  <p>Google Pay</p>
                </div>
              </div>

              <div className="g_pay" onClick={() => { setBuyMethodImage(phonepe); setgetPeymentMethod('phonepe'); setOpen(false);; setBuyMethodView('Phone Pay') }}>
                <div className="icn_cur">
                  <img src={phonepe} alt="coin" />
                </div>
                <div className="cur_title">
                  <p>Phone Pay</p>
                </div>
              </div>
            </div>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  More third-party service providers
                </Accordion.Header>
                <Accordion.Body>

                  <div className="__payment">
                    <div className="g_pay">
                      <div className="icn_cur">
                        <img src={wallet} alt="coin" />
                      </div>
                      <div className="cur_title">
                        <p>Bank Transfer</p>
                      </div>
                    </div>

                    <div className="g_pay">
                      <div className="icn_cur">
                        <img src={upi} alt="coin" />
                      </div>
                      <div className="cur_title">
                        <p>UPI</p>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <p className="copyright">CopyRight © 2024 Kissanx All Rights Reserved.</p>
        </div>


      </Modal>


      {/* sell model */}
      <Modal open={open1} onClose={onCloseModal1} center
        classNames={{
          modal: "sell-data-modal",
        }}>

        <div className="main-head">
          <h5>Payment Methods</h5>
        </div>

        <div className="__cs">
          <div className="modal-content modal-content___area">
            {allAccountdetail?.map((method_val) => {
              let target_id = '#sk' + method_val._id;

              return (
                <>
                  <div class="accordion" id="accordionExample">

                    <div class="accordion-item">
                      <div className="accordion-item___data">
                        <input type="checkbox" id={method_val?._id} checked={payment_methods?.includes(method_val?._id)} onClick={() => { addMethod(method_val?._id); getAccountDetails() }} className="form___check___AD" />
                        <label htmlFor={method_val?._id} className="check-box___aadfa ">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={target_id} aria-expanded="false" aria-controls="collapseTwo">
                              {method_val.type?.toUpperCase()} Details
                            </button>
                            <p className='status-btn'>Active</p>
                          </h2>
                        </label>
                      </div>

                      <div id={'sk' + method_val._id} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          {method_val.type === "bank" &&
                            <div className='holder-detail'>
                              <h4>Your bank account details for IMPS payments</h4>
                              <li><label>ACCOUNT Holder Name</label> <span className="ac-number"> {method_val.account_holder_name}</span></li>
                              <li><label>ACCOUNT NUMBER</label> <span className="ac-number">{method_val.account_number}</span></li>
                              {/* <li><label>IFSE CODE</label> <span className="ac-number">{method_val.ifcce}</span></li> */}
                              <li><label>ACCOUNT TYPE</label> <span className="ac-number">{method_val.account_type}</span></li>
                              {/* <div className='button'><button className="account-remove">Remove</button></div> */}
                            </div>
                          }
                          {method_val.type !== "bank" &&
                            <div className='holder-detail'>
                              <h4>Your UPI account details for  payments</h4>
                              <li><label> Name</label> <span className="ac-number"> {method_val.label}</span></li>
                              <li><label>UPI/VPA ID</label> <span className="ac-number">{method_val.upi_id}</span></li>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>

              );
            })}
          </div>

          <div className="ALl__buttons">
            <Link to="/dashboard/profile" className="btn___adds" state={{ active: true }}> Adds </Link>
          </div>
        </div>

      </Modal >


    </>
  )
}

export default P2p;
