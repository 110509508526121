import { toast } from 'react-toastify';

const toasted = {
    success: async function (message) {
        toast.success(message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            position: toast.POSITION.BOTTOM_RIGHT,
        })
    },
    error: async function (message) {
        toast.error(message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    }
}
export default toasted;