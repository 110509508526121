import React, { useState,useEffect } from 'react'
import { InfoOutlined } from '@mui/icons-material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Background from '../../Assets/Image/kycBG.svg'
import Background_icon from '../../Assets/Image/icon-passport-color.png'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Form } from 'react-bootstrap';
import { Value } from 'sass';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import toasted from '../../config/toast';
import { ClipLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom';


const Kyc = () => {



    const [country, setCountry] = useState<string>('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [DOB, setDOB] = useState('');
    const [region, setRegion] = useState<string>('');
    const [city, setCity] = useState('');
    const [pin_code, setPin_code] = useState('');
    const [comments, setComments] = useState('');
    const [document_Type, setDocument_Type] = useState('pan_card');
    const [documentNumber, setDocumnetNumber] = useState("");
    const [re_enter_document_number, setre_enter_document_number] = useState("");

    const [documentFront, setDocumentFront] = useState('');
    const [documentFrontImage, setDocumentFrontImage] = useState('');
    const [documentBack, setDocumentBack] = useState('');
    const [documentBackImage, setBackDocumentImage] = useState('');
    const [pan_image, setpan_image] = useState('');
    const [pan_image_path, setpan_image_path] = useState('');
    const [pan, setpan] = useState('');
    const [reEnterPan, setreEnterPan] = useState('');
    const [selfie_image, Setselfie_image] = useState('');
    const [selfie_imageGet, Setselfie_imageGet] = useState('');

    const [btnDisable, setbtnDisable] = useState(false);





    const selectCountry = (val: string) => {
        setCountry(val);
    };

    const selectRegion = (val: string) => {
        setRegion(val);
    };

    function Addahar_Card() {
        setDocument_Type('Aadhar');
    }
    function Driving_Licence() {
        setDocument_Type('DrivingLicense');
    }



    const SubmitKycData = async (e: any) => {
        setbtnDisable(true)
        e.preventDefault();

        let formData = new FormData();
        formData.append('country', country);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('dob', DOB);
        formData.append('state', region);
        formData.append('city', city);
        formData.append('pin_code', pin_code);
        formData.append('comment', comments);
        formData.append('document_type', document_Type);
        formData.append('document_number', documentNumber);
        formData.append('re_enter_document_number', re_enter_document_number);
        formData.append('document_front_image', documentFront);
        formData.append('document_back_image', documentBack);
        formData.append('pan_number', pan);
        formData.append('re_enter_pan_number', reEnterPan);
        formData.append('pan_image', pan_image);
        formData.append('selfie_image', selfie_image);

        var KycData = await _fetch(`${api_url}kyc/updateKyc/`, 'IMGPATCH', formData);
        console.log(KycData);

        if (KycData?.status === 'success') {
            toasted.success(KycData.message);
            setbtnDisable(false)

        }
        else {
            toasted.error(KycData.message);
            setbtnDisable(false)

        }
    }
    let navigate = useNavigate();
    useEffect(() => {

        (async () => {
            const passData = {};
            const data1 = await _fetch(`${api_url}kyc/userKycInfo/`, 'GET', passData, {});
            const newData = data1.data.data;
            if (newData.kyc_status === 'Pending') {
                navigate("/Dashboard/Kyc/Pending");
            } else if (newData.kyc_status === 'Approved') {
                navigate("/Dashboard/Kyc/Approved");
            }
            
            
        })()
    }, [])

    return (


        <>
            <div className='kyc'>
                <div className=''>

                    <div className='heading-sec text-center'>
                        <h2>Verify Your Identity</h2>
                        <p className='mb-0'>For increased limits and advanced features our regulatory partners require a bit more profile information..</p>
                    </div>


                    <div className='card form-kyc-card border-0'>
                        <Form>
                            <div className='text-main'>
                                <div className="step-head">
                                    <div className="step-number">01</div>
                                    <div className="step-head-text">
                                        <h4>Personal Details</h4>
                                        <p>Your simple personal information required for identification</p>
                                    </div>
                                </div>


                                <div className="form-step-fields">
                                    <div className="note full-width">
                                        <label htmlFor='country'> Country</label>
                                        <CountryDropdown value={country} onChange={(val: string) => selectCountry(val)} id='country' />
                                    </div>
                                </div>

                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='fname'> First Name*</label>
                                        <Form.Control type="text" placeholder="Enter First Name" id="fname" required onChange={(e) => { setFirstName(e.target.value) }} value={firstName} />
                                    </div>

                                    <div className="note w-100">
                                        <label htmlFor='lname'> LAST NAME</label>
                                        <Form.Control type="text" placeholder="Enter Last Name" id="lname" onChange={(e) => { setLastName(e.target.value) }} value={lastName} />
                                    </div>
                                </div>

                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='bithdate'> DATE OF BIRTH (DD-MM-YYYY) *</label>
                                        <input type="date" className='form-control' placeholder="(DD-MM-YYYY)" id="bithdate" required onChange={(e) => { setDOB(e.target.value) }} value={DOB} />
                                    </div>

                                    <div className="note w-100 full-width-region">
                                        <label htmlFor='lname'> STATE ( Region )*</label>
                                        <RegionDropdown country={country} value={region} onChange={(val: string) => selectRegion(val)} />
                                    </div>
                                </div>


                                <div className="form-step-fields form-step-fields-flex ">
                                    <div className="note w-100">
                                        <label htmlFor='city'> City*</label>
                                        <Form.Control type="text" placeholder="Enter City" id="city" required onChange={(e) => { setCity(e.target.value) }} value={city} />
                                    </div>

                                    <div className="note w-100">
                                        <label htmlFor='code-zip'> PIN/ZIP CODE *</label>
                                        <Form.Control type="number" placeholder="PIN/ZIP CODE" id="code-zip" required onChange={(e) => { setPin_code(e.target.value) }} value={pin_code} />
                                    </div>
                                </div>

                                <div className="form-step-fields">
                                    <div className="note full-width">
                                        <label htmlFor='Comments'> Comments</label>
                                        <textarea className="form-control" rows={5} id="Comments" name="text" onChange={(e) => { setComments(e.target.value) }} value={comments}></textarea>
                                    </div>
                                </div>
                            </div>



                            <div className='text-main'>

                                <div className="step-head">
                                    <div className="step-number">02</div>
                                    <div className="step-head-text">
                                        <h4>Pan Card Details Upload</h4>
                                        <p>To verify your identity, please upload any of your document</p>
                                    </div>
                                </div>
                            </div>
                            <div className='for-padding border-0'>
                                <div className="form-step-fields">
                                    <div className="note d-flex">
                                        <InfoOutlined />  <p>In order to complete, please upload any of the following personal document.</p>
                                    </div>
                                </div>
                                <div className='main-doc'>

                                    <div className='for-padding p-0 mt-2 border-0'>
                                        <div className="form-step-fields">
                                            <div className="note d-flex">
                                                <InfoOutlined />  <p>Please type carefully and fill out the form with your personal details. Your can’t edit these details once you submitted the form.</p>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="input-item input-with-label">
                                                    <label className="input-item-label">Pan Number <span className="text-danger">*</span></label>
                                                    <input className="input-bordered" type="text" onChange={(e) => { setpan(e.target.value) }} value={pan} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="input-item input-with-label">
                                                    <label className="input-item-label"> Confirm Pan Number <span className="text-danger">*</span></label>
                                                    <input className="input-bordered" type="text" onChange={(e) => { setreEnterPan(e.target.value) }} value={reEnterPan} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='upload mt-3 upload-new'>
                                        <h5 className="font-mid">Upload Here Your Pan Card Images</h5>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <p className='document mt-2 p-text'>Document Pan Image</p>
                                                <div className="upload-box" style={{ backgroundImage: `url(${pan_image_path === '' ? Background : pan_image_path})` }} /* style={{backgroundImage: image === undefined && image !== " " ? `url(${Background})`: `url(${image})`}}  */>
                                                    <div className="upload-zone dropzone dz-clickable">
                                                        <div className="dz-message" data-dz-message="">
                                                            <input id='doc-front' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { setpan_image(e.target.files[0]); setpan_image_path(URL.createObjectURL(e.target.files[0])) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='button-center'>
                                                    <label htmlFor='doc-front' className="btn">
                                                        SELECT
                                                    </label>
                                                </div>
                                            </div>


                                            {/* <div className="col-sm-6">
                                            <p className='document p-text'>Document Back Image</p>
                                                <div className="upload-box" style={{backgroundImage: `url(${Background})`}}>
                                                    <div className="upload-zone dropzone dz-clickable">
                                                        <div className="dz-message" data-dz-message="">

                                                            <input id='doc-back' type="file" className='temprary-input'  accept="image/*" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='button-center'>
                                                    <label htmlFor='doc-back' className="btn btn-primary">SELECT</label>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>


                                    <div className='text-main'>
                                        <div className="step-head">
                                            <div className="step-number">03</div>
                                            <div className="step-head-text">
                                                <h4>Document Details Upload</h4>
                                                <p>To verify your identity, please upload any of your document</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <Tabs>
                                            <TabList>
                                                <Tab><img src={Background_icon} alt='AADHAR-icon' /> AADHAR CARD</Tab>
                                                <Tab><img src={Background_icon} alt='Driving-license-icon' /> Driving license</Tab>
                                            </TabList>


                                            <TabPanel onClick={Addahar_Card}>
                                                <div className='for-padding  p-0 mt-2 border-0'>
                                                    <div className="form-step-fields">
                                                        <div className="note d-flex">
                                                            <InfoOutlined />  <p>Please type carefully and fill out the form with your personal details. Your can’t edit these details once you submitted the form.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="input-item input-with-label">
                                                                <label className="input-item-label">Aadhar Number <span className="text-danger">*</span></label>
                                                                <input className="input-bordered" type="text" onChange={(e) => { setDocumnetNumber(e.target.value) }} value={documentNumber} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="input-item input-with-label">
                                                                <label className="input-item-label">Confirm Aadhar Number <span className="text-danger">*</span></label>
                                                                <input className="input-bordered" type="text" onChange={(e) => { setre_enter_document_number(e.target.value) }} value={re_enter_document_number} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane">
                                                    <h5 className="text-secondary font-bold">To avoid delays when verifying account, Please make sure bellow:</h5>
                                                    <ul className="list-check">
                                                        <li>Chosen credential must not be expaired.</li>
                                                        <li>Document should be good condition and clearly visible.</li>
                                                        <li>Make sure that there is no light glare on the card.</li>
                                                    </ul>
                                                </div>

                                                <div className='upload'>
                                                    <h5 className="font-mid">Upload Here Your Aadhar Card Images</h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-6">
                                                            <p className='document mt-2 p-text'>Document Front Image</p>
                                                            <div className="upload-box" style={{ backgroundImage: `url(${documentFrontImage === '' ? Background : documentFrontImage})` }}>
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message="">

                                                                        <input id='doc-front2' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { setDocumentFront(e.target.files[0]); setDocumentFrontImage(URL.createObjectURL(e.target.files[0])) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='button-center'>

                                                                <label htmlFor='doc-front2' className="btn btn-primary">SELECT</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p className='document mt-2 p-text'>Document Back Image</p>
                                                            <div className="upload-box" style={{ backgroundImage: `url(${documentBackImage === '' ? Background : documentBackImage})` }}>
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message="">

                                                                        <input id='doc-back2' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { setDocumentBack(e.target.files[0]); setBackDocumentImage(URL.createObjectURL(e.target.files[0])) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='button-center'>
                                                                <label htmlFor='doc-back2' className="btn">SELECT</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel onClick={Driving_Licence}>
                                                <div className='for-padding p-0 mt-2 border-0'>
                                                    <div className="form-step-fields">
                                                        <div className="note d-flex">
                                                            <InfoOutlined />  <p>Please type carefully and fill out the form with your personal details. Your can’t edit these details once you submitted the form.</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="input-item input-with-label">
                                                                <label className="input-item-label"> Driving license <span className="text-danger">*</span></label>
                                                                <input className="input-bordered" type="text" onChange={(e) => { setDocumnetNumber(e.target.value) }} value={documentNumber} />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="input-item input-with-label">
                                                                <label className="input-item-label"> Confirm Driving license <span className="text-danger">*</span></label>
                                                                <input className="input-bordered" type="text" onChange={(e) => { setre_enter_document_number(e.target.value) }} value={re_enter_document_number} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane">
                                                    <h5 className="text-secondary font-bold">To avoid delays when verifying account, Please make sure below:</h5>
                                                    <ul className="list-check">
                                                        <li>Chosen credential must not be expaired.</li>
                                                        <li>Document should be good condition and clearly visible.</li>
                                                        <li>Make sure that there is no light glare on the card.</li>
                                                    </ul>
                                                </div>

                                                <div className='upload'>
                                                    <h5 className="font-mid">Upload Here Your Driving license Images</h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-6">
                                                            <p className='document mt-2 p-text'>Document Front Image</p>
                                                            <div className="upload-box" style={{ backgroundImage: `url(${documentFrontImage === '' ? Background : documentFrontImage})` }}>
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message="">

                                                                        <input id='doc-front2' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { setDocumentFront(e.target.files[0]); setDocumentFrontImage(URL.createObjectURL(e.target.files[0])) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='button-center'>

                                                                <label htmlFor='doc-front2' className="btn btn-primary">SELECT</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p className='document mt-2 p-text'>Document Back Image</p>
                                                            <div className="upload-box" style={{ backgroundImage: `url(${documentBackImage === '' ? Background : documentBackImage})` }}>
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message="">

                                                                        <input id='doc-back2' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { setDocumentBack(e.target.files[0]); setBackDocumentImage(URL.createObjectURL(e.target.files[0])) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='button-center'>
                                                                <label htmlFor='doc-back2' className="btn">SELECT</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </Tabs>
                                    </div>


                                    <div className='text-main upload-new__top'>
                                        <div className="step-head">
                                            <div className="step-number">04</div>
                                            <div className="step-head-text">
                                                <h4>Selfie Upload</h4>
                                                <p>To verify your identity, please upload any of your document</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='upload mt-3 upload-new'>
                                        <h5 className="font-mid">Upload Here Your Selfie</h5>
                                        <div className="row">

                                            <div className="col-sm-6">
                                                <div className="upload-box" style={{ backgroundImage: `url(${selfie_imageGet === '' ? Background : selfie_imageGet})` }}>
                                                    <div className="upload-zone dropzone dz-clickable">
                                                        <div className="dz-message" data-dz-message="">

                                                            <input id='doc-back' type="file" className='temprary-input' accept="image/*" onChange={(e: any) => { Setselfie_image(e.target.files[0]); Setselfie_imageGet(URL.createObjectURL(e.target.files[0])) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='button-center'>
                                                    <label htmlFor='doc-back' className="btn btn-primary">SELECT</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='submit-btn text-center mt-4 mb-4'>
                                <button type='submit' className='btn submit-btn btn-primary' onClick={SubmitKycData} disabled={btnDisable}>

                                    {btnDisable === true &&
                                        <div className="color-ring">
                                            <ClipLoader color="#36d7b7" />

                                            Please Wait...
                                        </div>
                                    }
                                    {btnDisable === false ? 'Submit Kyc ' : ''}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Kyc