import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Form as form, Table } from "react-bootstrap";
import { FilePresent } from "@mui/icons-material";
import { shortTitle } from "../../config/config";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ padding: "30px 0" }}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FeeSchedule = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="schedule-main">
        <div className="banner-sec">
          <div className="coustom_container">
            <h1>Fee Schedule</h1>
            <h5>
              Use BLC To Deduct Fees <br />
              Enjoy Larger Discounts And More Privileges VIP Privileges
            </h5>
          </div>
        </div>

        <div className="table-sec">
          <div className="coustom_container">
            <div className="table-inner">
              <div className="tabl-inner2" style={{ width: "100%" }}>
                <div style={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Spot Trading" {...a11yProps(0)} />
                    <Tab label="Futures Trading" {...a11yProps(1)} />
                    <Tab label="Withdrawals" {...a11yProps(2)} />
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <div className="tab1-sec"></div>
                  <div className="table-main">
                    <div className="table-sec1">
                      <Table>
                        <thead>
                          <tr>
                            <th>Trading Pair</th>
                            <th>Maker</th>
                            <th>Taker</th>
                            <th>Maker (Pay With {shortTitle})</th>
                            <th>Taker (Pay With {shortTitle})</th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>

                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="tab1-sec"></div>
                  <div className="table-main">
                    <div className="table-sec1">
                      <Table>
                        <thead>
                          <tr>
                            <th>Trading Pair</th>
                            <th>Maker</th>
                            <th>Taker</th>
                            <th>Maker (Pay With {shortTitle})</th>
                            <th>Taker (Pay With {shortTitle})</th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                  {/* <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <FilePresent sx={{ fontSize: "60px", color: "white" }} />{" "}
                    <br />
                    <span style={{ color: "white" }}>No Data</span>
                  </div> */}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="tab1-sec"></div>
                  <div className="table-main">
                    <div className="table-sec1">
                      <Table>
                        <thead>
                          <tr>
                            <th>Trading Pair</th>
                            <th>Maker</th>
                            <th>Taker</th>
                            <th>Maker (Pay With {shortTitle})</th>
                            <th>Taker (Pay With {shortTitle})</th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                  {/* <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <FilePresent sx={{ fontSize: "60px", color: "black" }} />
                    <br />
                    <span style={{ color: "white" }}>No Data available</span>
                  </div> */}
                </TabPanel>
              </div>
              <div style={{ textAlign: "center", marginTop: "30px" }} className="no-data">
                <FilePresent sx={{ fontSize: "60px" }} />{" "}
                <br />
                <span style={{ color: "$txt-white" }}>No Data</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeSchedule;
