import React, { useEffect, useRef, useState } from "react";
import { AddShoppingCart } from "@mui/icons-material";
import phone_img from "../../Assets/Image/phone.svg";
import site_logo from "../../Assets/Image/New_logo.png";
import img from "../../Assets/Image/first-card-sec.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { shortTitle, title } from "../../config/config";

const Banner = () => {


  // const [isScrolled, setIsScrolled] = useState(false);
  // const aboutRef = useRef<HTMLDivElement>(null);

  // const handleScroll = () => {
  //   if (!aboutRef.current) return;

  //   const aboutTop = aboutRef.current.getBoundingClientRect().top;
  //   const aboutBottom = aboutRef.current.getBoundingClientRect().bottom;

  //   if (aboutTop <= 100) {
  //     setIsScrolled(true);
  //   } else {
  //     setIsScrolled(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div className="sec_hero_sec" >
        <div className="cover_up">
          <div className='loading'>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
            <div className="bar">
              <div className="delta"></div>
            </div>
          </div>
        </div>
        <div className="coustom_container">
          <div className="hero_sec">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-Info">
                  <h1>Kissan<span>X</span></h1>
                  <h2>Connecting you to the Power of Crypto</h2>
                  <p>
                    Unlock the full potential of your digital assets in the Crypto, DeFi, and NFT spaces like never before. Seamlessly connect and manage all your holdings with the innovative KSN Token from Kissanx. Embrace the future of Web3 technology today, and experience unparalleled control, security, and flexibility. Whether you are an investor, collector, or DeFi enthusiast, the KSN Token empowers you to navigate the evolving digital landscape with ease and confidence. Join the revolution and transform your digital asset experience with Kissanx.
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <Link to="/sign-up" className="new_custom_button new_custom_button_add">
                      Contact Us
                    </Link><Link to="/sign-up" className="new_custom_button new_custom_button_add">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="sec-sec">
                  <div className="inner">
                    <div className="logo">
                      <img src={site_logo} alt={title + ` Logo`} />

                      <div className="inner">
                        <Link to="#">
                          <AppleIcon />
                          App Store
                        </Link>

                        <Link to="#">
                          <ShopIcon />
                          Google Play
                        </Link>

                        <Link to="#">
                          <AndroidIcon />
                          Android
                        </Link>
                      </div>
                    </div>

                    <img src={phone_img} alt="" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="coustom_container main-swiper-sec">
        <div className="swiper">
          <Swiper
            // spaceBetween={30}
            centeredSlides={false}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              992: {
                slidesPerView: 2,
                spaceBetween: 20,
              },

              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card-1">
                <img src={img} alt="" />
                <div className="inner_info">
                  <h1>lorem lorem</h1>
                  <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-1">
                <img src={img} alt="" />
                <div className="inner_info">
                  <h1>lorem lorem</h1>
                  <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-1">
                <img src={img} alt="" />
                <div className="inner_info">
                  <h1>lorem lorem</h1>
                  <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-1">
                <img src={img} alt="" />
                <div className="inner_info">
                  <h1>lorem lorem</h1>
                  <p>Lorem ipsum dolor sit amet consectetur.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Banner;
