import React from 'react'
import img from "../../Assets/Image/Footer_IMG/commin-soon.jpg"

const CommingSoon = () => {
  return (
    <div>
      <div className="comming-soon">
        <div className="inner">
            <img src={img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default CommingSoon
