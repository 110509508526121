import React from "react";
import { Info } from "@mui/icons-material";
import apiImage from "../../Assets/Image/api_image.webp";
import ApiSidebar from "../ApiSidebar/ApiSidebar";
import { shortTitle } from "../../config/config";

const GettingApiKey = () => {
  return (
    <>
      <div className="main-sec-getting-api">
        <div className="coustom_container">
          <div className="inner-sec">
            <h1>Getting an API Key</h1>
            <div className="info-sec">
              <span>
                <Info />
              </span>
              <span>
                API keys created from your account dashboard can be used for
                both {shortTitle} Smart Chain mainnet and testnet explorers
              </span>
            </div>

            <div className="creating-api">
              <h2>Creating an API Key</h2>
              <p>
                From your Account Dashboard, click on the navigation tab
                labelled API-KEYs.
              </p>
              <img src={apiImage} alt="" width="768px" height="368px" />
              <p style={{ paddingTop: "15px" }}>
                From there, you may click on Add to create a new key and give a
                name to your project. Each {shortTitle} account is limited to creating 3
                keys at any one time.
              </p>
            </div>

            <div className="editing-api">
              <h2>Editing an API Key</h2>
              <p>
                To change your project name associated with an API Key, click on
                Edit to specify a new App Name, and save the changes.
              </p>
              <img src={apiImage} alt="" width="768px" height="368px" />

              <p style={{ paddingTop: "15px" }}>
                If you would like to delete an API Key or suspect your key has
                been compromised, you may click on Remove to delete that key and
                generate a new one
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GettingApiKey;
